import axios from 'axios';
const baseURL =
  process.env.NODE_ENV === 'production'
    ? window.env.COMSDOMAIN + '/prepare/api/admin'
    : process.env.REACT_APP_COMSDOMAIN + '/prepare/api/admin';

const http = axios.create({
  baseURL: baseURL,
  timeout: 50000,
});

const Network = {
  http: http,
  get: async (url, params) => {
    return http.get(url, { params: params });
  },
  post: async (url, data) => {
    return http.post(url, data);
  },
};
export const PrepareBaseUrl = baseURL;
export default Network;
