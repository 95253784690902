import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import API from '../../../service/api';
import Network from '../../../service/Network';
import AddSiteDialog from '../Sites/AddSiteDialog';
import { Link, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dropdown } from 'react-bootstrap';
import Help from '../../../component/Help/Help';
import { PrepareMedia, useSelfHostedKnowledgeBase} from 'assets/data/HelpContent';
import { useNavigate } from 'react-router-dom';
import IconAdd from 'assets/images/icon__add.svg';
import IconSettings from 'assets/images/icon__settings.svg';
import IconSites from 'assets/images/icon__sites.svg';
import IconProfiles from 'assets/images/icon__profiles.svg';
import IconPersonas from 'assets/images/icon__personas.svg';
import IconNetworks from 'assets/images/icon__networks.svg';
import IconPosts from 'assets/images/icon__posts.svg';
import IconDrafted from 'assets/images/icon__drafted--blue.svg';
import IconScheduled from 'assets/images/icon__scheduled--blue.svg';
import IconPublished from 'assets/images/icon__published--blue.svg';
import IconPosted from 'assets/images/icon__posted--blue.svg';
import IconAllPosts from 'assets/images/icon__allposts--blue.svg';
import IconDifficulty from 'assets/images/icon__difficulty--blue.svg';
import IconProfilesBlue from 'assets/images/icon__profiles--blue.svg';
import IconPersonasBlue from 'assets/images/icon__personas--blue.svg';
import IconNetworksBlue from 'assets/images/icon__networks--blue.svg';
import IconSitesBlue from 'assets/images/icon__sites--blue.svg';
import IconHour from 'assets/images/icon__hour.svg';
import IconDay from 'assets/images/icon__day.svg';
import IconWeek from 'assets/images/icon__week.svg';
import IconMonth from 'assets/images/icon__month.svg';
import IconEver from 'assets/images/icon__ever.svg';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import CreateProfileDialog from '../Profiles/CreateProfileDialog';
import CreatePersonaDialog from '../Personas/CreatePersonaDialog';
import CreateNetworkDialog from '../Networks/CreateNetworkDialog';
import CreatePostDialog from '../Posts/CreatePostDialog';
import { toastConfig } from 'assets/data/config';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly';
import PageTitle from 'components/headers/PageTitle';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useNewUIFlag } from 'utils/featureFlags';

export default function MediaDashboard() {
  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const newUI = useNewUIFlag();

  const [allNewMediaDash, setAllNewMediaDash] = useState({});
  const [showMediaAnalytics, setShowMediaAnalytics] = useState(false);
  const [showSiteAddDialog, setShowSiteAddDialog] = useState(false);
  const [dialogType, setDialogType] = useState('Add');
  const [showCreateProfileDialog, setShowCreateProfileDialog] = useState(false);
  const [showCreatePersonaDialog, setShowCreatePersonaDialog] = useState(false);
  const [showCreateNetworkDialog, setShowCreateNetworkDialog] = useState(false);
  const [showCreatePostDialog, setShowCreatePostDialog] = useState(false);
  const [filter, setFilter] = useState('day');

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onCreateProfileDialog = () => {
    setShowCreateProfileDialog(true);
  };
  const onCreateProfileDialogClose = () => {
    setShowCreateProfileDialog(false);
  };
  const onCreatePersonaDialog = () => {
    setShowCreatePersonaDialog(true);
  };
  const onCreatePersonaDialogClose = () => {
    setShowCreatePersonaDialog(false);
  };
  const onCreateNetworkDialog = () => {
    setShowCreateNetworkDialog(true);
  };
  const onCreateNetworkDialogClose = () => {
    setShowCreateNetworkDialog(false);
  };
  const onCreatePostDialog = () => {
    setShowCreatePostDialog(true);
  };
  const onCreatePostDialogClose = () => {
    setShowCreatePostDialog(false);
  };

  const toggleClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const onAddSiteDialog = () => {
    setDialogType('Add');
    setShowSiteAddDialog(true);
  };
  const onAddSiteDialogClose = () => {
    setShowSiteAddDialog(false);
  };

  const mediaDashboardDetails = async () => {
    dispatch(startLoading());
    if (workspaceId) {
      try {
        const newMediaDash = await Network.get(API.mediaDashboardDetails, {
          workspaceId: workspaceId,
          filter: filter,
        });
        setAllNewMediaDash(newMediaDash.data.response);
      } catch (error) {
        toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      } finally {
        dispatch(endLoading());
      }
    }
  };

  const user = useSelector((state) => state.prepare.user);

  useEffect(() => {
    mediaDashboardDetails();
  }, [filter, workspaceId]);

  useEffect(() => {
    featureFlags.map((flag) => {
      if (flag === 'MEDIA_ANALYTICS') {
        setShowMediaAnalytics(true);
      } else {
        setShowMediaAnalytics(false);
      }
    });
  }, [featureFlags]);

  return (
    <>
      <div className="row">
        {newUI ? (
          <PageTitle title="Dashboard" parents={['Media']} />
        ) : (
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <StylishNewButton onClick={() => navigate(-1)}>
                  <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                  Back
                </StylishNewButton>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard
              </li>
            </ol>
          </nav>
        )}

        <div className="col-12">
          <div className="d-flex align-items-center mb-4">
            <Dropdown>
              <Dropdown.Toggle
                className={`button button--primary button--create px-4
                ${isRoleReadOnly(user.roles) ? ' disabled' : ''}
                `}
              >
                <img src={IconAdd} alt="" />
                <span>Create</span>
                <i className="fa fa-angle-down" aria-hidden="true"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu align="start">
                <Dropdown.Item onClick={onCreatePostDialog}>
                  New Post
                </Dropdown.Item>
                <Dropdown.Item onClick={onCreateProfileDialog}>
                  New Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={onCreatePersonaDialog}>
                  New Personas
                </Dropdown.Item>
                <Dropdown.Item onClick={onCreateNetworkDialog}>
                  New Network
                </Dropdown.Item>
                <Dropdown.Item onClick={onAddSiteDialog}>
                  New site
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Help
              classes={'ms-auto flex-shrink-0 sml'}
              title={PrepareMedia.title}
              content={PrepareMedia.content}
              link={selfHostedKnowledgeBase || PrepareMedia.link}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="row">
            <div
              className="col-md-6 col-xl-4 mb-4"
              onClick={(e) => {
                e?.preventDefault();
                navigate(`${PreparePath}/dashboard/media/posts`, {
                  state: {
                    filter: 'drafted',
                  },
                });
              }}
            >
              <div className="block h-100 m-0 logo d-flex">
                <div className="d-flex align-items-center col-11 py-4 pe-2">
                  <div className="d-flex align-items-center me-3 ">
                    <img
                      className="flex-shrink-0 me-3 img-h-32"
                      src={IconDrafted}
                      alt=""
                    />

                    <h4 className="mb-0 break-word">Drafted</h4>
                  </div>

                  <h5 className="ms-auto mb-0 flex-shrink-0">
                    {allNewMediaDash?.draftedCount}
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-4 mb-4"
              onClick={(e) => {
                e?.preventDefault();
                navigate(`${PreparePath}/dashboard/media/posts`, {
                  state: {
                    filter: 'scheduled',
                  },
                });
              }}
            >
              <div className="block h-100 m-0 logo d-flex">
                <div className="d-flex align-items-center col-11 py-4 pe-2">
                  <div className="d-flex align-items-center me-3 ">
                    <img
                      className="flex-shrink-0 me-3 img-h-32"
                      src={IconScheduled}
                      alt=""
                    />

                    <h4 className="mb-0 break-word">Scheduled</h4>
                  </div>

                  <h5 className="ms-auto mb-0 flex-shrink-0">
                    {allNewMediaDash?.scheduledCount}
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-4 mb-4"
              onClick={(e) => {
                e?.preventDefault();
                navigate(`${PreparePath}/dashboard/media/posts`, {
                  state: {
                    filter: 'published',
                  },
                });
              }}
            >
              <div className="block h-100 m-0 logo d-flex">
                <div className="d-flex align-items-center col-11 py-4 pe-2">
                  <div className="d-flex align-items-center me-3 ">
                    <img
                      className="flex-shrink-0 me-3 img-h-32"
                      src={IconPublished}
                      alt=""
                    />

                    <h4 className="mb-0 break-word">Published</h4>
                  </div>

                  <h5 className="ms-auto mb-0 flex-shrink-0">
                    {allNewMediaDash?.publishedCount}
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-4 mb-4"
              onClick={(e) => {
                e?.preventDefault();
                navigate(`${PreparePath}/dashboard/media/posts`);
              }}
            >
              <div className="block h-100 m-0 logo d-flex">
                <div className="d-flex align-items-center col-11 py-4 pe-2">
                  <div className="d-flex align-items-center me-3 ">
                    <img
                      className="flex-shrink-0 me-3 img-h-32"
                      src={IconPosted}
                      alt=""
                    />

                    <h4 className="mb-0 break-word">Posted</h4>
                  </div>

                  <h5 className="ms-auto mb-0 flex-shrink-0">
                    {allNewMediaDash?.filterPostDashboardData?.count}
                  </h5>
                </div>
                <div className="text-end flex-shrink-0 ms-auto">
                  <Dropdown
                    className="flex-shrink-0"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Dropdown.Toggle className="button--icon">
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setFilter('hour');
                        }}
                      >
                        <img src={IconHour} className="img-h-16" alt="" />
                        Hour
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setFilter('day');
                        }}
                      >
                        <img src={IconDay} className="img-h-16" alt="" />
                        Day
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setFilter('week');
                        }}
                      >
                        <img src={IconWeek} className="img-h-16" alt="" />
                        Week
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setFilter('month');
                        }}
                      >
                        <img src={IconMonth} className="img-h-16" alt="" />
                        Month
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setFilter('ever');
                        }}
                      >
                        <img src={IconEver} className="img-h-10" alt="" />
                        Ever
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-4 mb-4"
              onClick={(e) => {
                e?.preventDefault();
                navigate(`${PreparePath}/dashboard/media/posts`);
              }}
            >
              <div className="block h-100 m-0 logo d-flex">
                <div className="d-flex align-items-center col-11 py-4 pe-2">
                  <div className="d-flex align-items-center me-3 ">
                    <img
                      className="flex-shrink-0 me-3 img-h-32"
                      src={IconAllPosts}
                      alt=""
                    />

                    <h4 className="mb-0 break-word">All Posts</h4>
                  </div>
                  <h5 className="ms-auto mb-0 flex-shrink-0">
                    {allNewMediaDash?.publishedCount +
                      allNewMediaDash?.scheduledCount +
                      allNewMediaDash?.draftedCount}
                  </h5>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 col-xl-4 mb-4">
              <div className="block h-100 m-0 logo d-flex">
                <div className="d-flex align-items-center col-11 py-4 pe-2">
                  <div className="d-flex align-items-center me-3 ">
                    <img
                      className="flex-shrink-0 me-3 img-h-32"
                      src={IconDifficulty}
                      alt=""
                    />

                    <h4 className="mb-0 break-word">Difficulty</h4>
                  </div>

                  <h5 className="ms-auto mb-0 flex-shrink-0">100%</h5>
                </div>
                <div className="text-end flex-shrink-0 ms-auto">
                  <Dropdown className="flex-shrink-0">
                    <Dropdown.Toggle className="button--icon">
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item>
                        <img src={IconHour} className="img-h-16" alt="" />
                        Hour
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <img src={IconDay} className="img-h-16" alt="" />
                        Day
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <img src={IconWeek} className="img-h-16" alt="" />
                        Week
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <img src={IconMonth} className="img-h-16" alt="" />
                        Month
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <img src={IconEver} className="img-h-10" alt="" />
                        Ever
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div> */}

            <div
              className="col-md-6 col-xl-4 mb-4"
              onClick={(e) => {
                e?.preventDefault();
                navigate(`${PreparePath}/dashboard/media/profiles`);
              }}
            >
              <div className="block h-100 m-0 logo d-flex">
                <div className="d-flex align-items-center col-11 py-4 pe-2">
                  <div className="d-flex align-items-center me-3 ">
                    <img
                      className="flex-shrink-0 me-3 img-h-32"
                      src={IconProfilesBlue}
                      alt=""
                    />

                    <h4 className="mb-0 break-word">Profiles</h4>
                  </div>

                  <h5 className="ms-auto mb-0 flex-shrink-0">
                    {allNewMediaDash?.profileDashboardData?.count}
                  </h5>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-xl-4 mb-4"
              onClick={(e) => {
                e?.preventDefault();
                navigate(`${PreparePath}/dashboard/media/personas`);
              }}
            >
              <div className="block h-100 m-0 logo d-flex">
                <div className="d-flex align-items-center col-11 py-4 pe-2">
                  <div className="d-flex align-items-center me-3 ">
                    <img
                      className="flex-shrink-0 me-3 img-h-32"
                      src={IconPersonasBlue}
                      alt=""
                    />

                    <h4 className="mb-0 break-word">Personas</h4>
                  </div>

                  <h5 className="ms-auto mb-0 flex-shrink-0">
                    {' '}
                    {allNewMediaDash?.personaDashboardData?.count}
                  </h5>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-xl-4 mb-4"
              onClick={(e) => {
                e?.preventDefault();
                navigate(`${PreparePath}/dashboard/media/networks`);
              }}
            >
              <div className="block h-100 m-0 logo d-flex">
                <div className="d-flex align-items-center col-11 py-4 pe-2">
                  <div className="d-flex align-items-center me-3 ">
                    <img
                      className="flex-shrink-0 me-3 img-h-32"
                      src={IconNetworksBlue}
                      alt=""
                    />

                    <h4 className="mb-0 break-word">Networks</h4>
                  </div>

                  <h5 className="ms-auto mb-0 flex-shrink-0">
                    {allNewMediaDash?.networkDashboardData?.count}
                  </h5>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 col-xl-4 mb-4"
              onClick={(e) => {
                e?.preventDefault();
                navigate(`${PreparePath}/dashboard/media/sites`);
              }}
            >
              <div className="block h-100 m-0 logo d-flex">
                <div className="d-flex align-items-center col-11 py-4 pe-2">
                  <div className="d-flex align-items-center me-3 ">
                    <img
                      className="flex-shrink-0 me-3 img-h-32"
                      src={IconSitesBlue}
                      alt=""
                    />

                    <h4 className="mb-0 break-word">Sites</h4>
                  </div>

                  <h5 className="ms-auto mb-0 flex-shrink-0">
                    {' '}
                    {allNewMediaDash?.siteDashboardData?.count}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSiteAddDialog && (
        <AddSiteDialog
          show={showSiteAddDialog}
          onClose={onAddSiteDialogClose}
          dialogType={dialogType}
        />
      )}
      {showCreateProfileDialog && (
        <CreateProfileDialog
          show={showCreateProfileDialog}
          onClose={onCreateProfileDialogClose}
        />
      )}
      {showCreatePersonaDialog && (
        <CreatePersonaDialog
          show={showCreatePersonaDialog}
          onClose={onCreatePersonaDialogClose}
        />
      )}
      {showCreateNetworkDialog && (
        <CreateNetworkDialog
          show={showCreateNetworkDialog}
          onClose={onCreateNetworkDialogClose}
        />
      )}
      {showCreatePostDialog && (
        <CreatePostDialog
          show={showCreatePostDialog}
          onClose={onCreatePostDialogClose}
          // selectedSiteId={selectedSiteId}
          // selectedPostid={selectedPostid}
        />
      )}
    </>
  );
}
