import { useState } from 'react';
import { FullScreenModal } from 'components/DesignSystems/FullScreenModal/FullScreenModal';
import { PratusStepper } from 'components/DesignSystems/PratusStepper/PratusStepper';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { IconContainerWithButton } from 'components/DesignSystems/IconContainerWithButton/IconContainerWithButton';
import { ExistingTemplates } from './ExistingTemplates';
import { PratusBreadcrumb } from 'components/DesignSystems/PatusBreadcrumb/PratusBreadcrumb';
import { TemplateStep } from './TemplateStep';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

interface Props {
  onModalClose: () => null;
  onSubmit: (template: any) => null;
}

const STEPS = [
  { id: 1, label: 'Select Starting Point' },
  { id: 2, label: 'Choose a template' },
  { id: 3, label: 'Launch' },
];

const BREADCRUMBS = [
  {
    id: 1,
    label: 'Select Starting Point',
    isActive: false,
  },
  {
    id: 2,
    label: 'Use Existing Templates',
    isActive: true,
  },
];

export const TemplateWizard = ({ onModalClose, onSubmit }: Props) => {
  const [activeStep, setActiveStep] = useState(1);
  const [showHelperText, setShowHelperText] = useState(true);
  const [showExistingTemplates, setShowExistingTemplates] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  const handleExistingTemplateClick = () => {
    setShowExistingTemplates(true);
    setActiveStep(2);
  };

  const handleTemplateSelect = (template: any) => {
    setSelectedTemplate(template);
    setActiveStep(3);
  };

  const handleSubmit = () => {
    onSubmit(selectedTemplate);
    onModalClose();
  };

  const renderStepOne = () => {
    return (
      <IconContainerWithButton
        buttonText="Use Existing"
        description="Implement existing Teams templates to expedite setup, leveraging established structures for quick operational integration."
        title="Use Existing Templates"
        onButtonClick={() => handleExistingTemplateClick()}
        icon="file_copy"
      />
    );
  };

  const renderStepTwo = () => {
    if (showExistingTemplates) {
      return (
        <>
          <ExistingTemplates handleTemplateSelect={handleTemplateSelect} />

          {/* {!showExistingTemplates ? (
            <button className="cancel-btn" onClick={() => onModalClose()}>
              Cancel
            </button>
          ) : (
            <div className="d-flex align-items-center w-100">
              <button className="flex-grow-1 button button--link">
                View Microsoft Teams Templates
              </button>

              <button
                className="d-flex align-items-center justify-content-center flex-grow-1 button button--secondary-fill"
                onClick={() => setActiveStep((prev) => prev + 1)}
              >
                <SharedIcon iconName="add" />

                <span>Create New Template</span>
              </button>
            </div>
          )} */}
        </>
      );
    }

    return <></>;
  };

  const renderStepThree = () => {
    return (
      <div className="w-100 d-flex flex-column align-items-start justify-content-between">
        <div className="w-100 d-flex flex-row justify-content-between">
          <span className="label">Template Name</span>
          <span>{selectedTemplate?.label}</span>
        </div>
      </div>
    );
  };

  return (
    <FullScreenModal
      title="Provisions Teams Site"
      show
      onClose={() => onModalClose()}
    >
      <div className="d-flex flex-row teams-template-wizard-container">
        <div className="d-flex flex-column justify-content-between align-items-start left-content">
          <PratusStepper vertical activeStep={activeStep} steps={STEPS} />
          {showHelperText && (
            <div className="helper-text-container d-flex flex-column align-items-start">
              <span className="title d-flex align-items-center">
                <SharedIcon iconName="info" />
                <span className="title ms-1">Helper Text</span>
              </span>
              <span className="description">
                Create new or enhance existing Teams templates through PRATUS
                for improved insights and productivity.
              </span>
              <button
                onClick={() => setShowHelperText(false)}
                className="dismiss-btn"
              >
                Dismiss
              </button>
            </div>
          )}
        </div>

        <div className="right-content d-flex flex-column align-items-start justify-content-between">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between">
              {activeStep === 2 ? (
                <PratusBreadcrumb
                  steps={BREADCRUMBS}
                  onBack={() => setActiveStep((prev) => prev - 1)}
                />
              ) : (
                <div className="text-uppercase step">{`Step ${activeStep}/${STEPS.length}`}</div>
              )}

              <button className="button button--primary button--reverse border-0">
                <SharedIcon iconName="help" classes="me-2" />
                Need Help?
              </button>
            </div>

            {activeStep === 1 && (
              <TemplateStep
                pageTitle="Select Starting Point"
                pageSubTitle="You can start from scratch or build upon a pre-designed template."
              >
                {renderStepOne()}
              </TemplateStep>
            )}

            {activeStep === 2 && (
              <TemplateStep
                pageTitle="Choose from Existing Templates"
                pageSubTitle="Select from existing Teams or PRATUS templates to get started"
              >
                {renderStepTwo()}
              </TemplateStep>
            )}

            {activeStep === 3 && (
              <TemplateStep pageTitle="Launch Your Template">
                {renderStepThree()}
              </TemplateStep>
            )}
          </div>

          <div className="w-100 d-flex flex-row justify-content-between">
            <StylishNewButton
              className="button--secondary border-0"
              onClick={() => onModalClose()}
            >
              Cancel
            </StylishNewButton>
            {activeStep === STEPS.length && (
              <StylishNewButton
                className="button--primary"
                onClick={handleSubmit}
              >
                Submit
              </StylishNewButton>
            )}
          </div>
          {/* )} */}
        </div>
      </div>
    </FullScreenModal>
  );
};
