import { useEffect, useState } from 'react';
import { AddEditDetailsDialog } from './AddEditDetailsDialog';
import API from 'PREPAREsrc/service/api';
import Network from 'PREPAREsrc/service/Network';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";

export const Details = (props) => {
  const { setEventData, selectedTab } = props;

  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector(
    (state) => state?.prepare?.workspace
  );

  const { eventId } = useSelector((state) => state?.prepare?.planDashboard);

  const [detailsData, setDetailsData] = useState();
  const [showEEDDetailsDialog, setShowEEDDetailsDialog] = useState(false);

  const handleOnClose = (successFlag) => {
    if (successFlag) fetchSingleEvent();
    setShowEEDDetailsDialog(false);
  };

  useEffect(() => {
    if (selectedTab === 'details' && eventId) {
      fetchSingleEvent();
    }
  }, [selectedTab, eventId, selectedWorkspace]);

  const fetchSingleEvent = async () => {
    const data = {
      id: eventId,
      workspaceId: selectedWorkspace,
    };
    dispatch(startLoading());
    try {
      const response = await Network.get(API.fetchSingleEvent, data);
      if (response !== undefined && response?.status === 200) {
        setDetailsData(response?.data?.response?.dataset[0]);
        setEventData({
          name: response?.data?.response?.dataset[0]?.name,
          startDate: response?.data?.response?.dataset[0]?.startDate,
          endDate: response?.data?.response?.dataset[0]?.endDate,
          primaryHost: response?.data?.response?.dataset[0]?.primary_host,
          secondaryHost: response?.data?.response?.dataset[0]?.secondary_host,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <StylishNewButton
        type="button"
        className={'button--primary mb-4 w-100 w-md-auto'}
        onClick={() => setShowEEDDetailsDialog(true)}
        disabled={!eventId}
      >
        Edit Details
      </StylishNewButton>
      <p className="mb-4">
        <span className="d-block weight-700 mb-1">Purpose</span>
        {detailsData?.purpose || 'N/A'}
      </p>
      <p className="mb-4">
        <span className="d-block weight-700 mb-1">Background Statement</span>
        {detailsData?.background_statement || 'N/A'}
      </p>
      <p className="m-0">
        <span className="d-block weight-700 mb-1">Test Event Location</span>
        {detailsData?.event_location || 'N/A'}
      </p>
      {(showEEDDetailsDialog && (
        <AddEditDetailsDialog
          show={showEEDDetailsDialog}
          onClose={handleOnClose}
          data={detailsData}
        />
      )) ||
        null}
    </>
  );
};
