import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './StylishDateTimePicker.css';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

const timeOptions = (() => {
  const newTimeOptions = [];
  const format = 'HH:mm';

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      const time = moment({ hour: i, minute: j });
      newTimeOptions.push({
        label: time.format(format),
        value: time.format('HH:mm'),
      });
    }
  }
  return newTimeOptions;
})();

const Input = (props) => <components.Input {...props} isHidden={false} />;

// We expect the value to come as an iso time string
// We trigger the callback using the same format
export default function StylishDateTimePicker({
  value,
  onChange,
  type,
  minDate,
  maxDate,
  onBlur,
  disabled,
  dateLabel,
  timeLabel,
  disableDateTimeLabel,
  required,
  ...props
}) {
  const user = useSelector((state) => state.prepare.user);

  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const datePicker = useRef();
  const selectRef = useRef();
  const [inputValue, setInputValue] = useState('00:00');

  const [localTimeOptions, setLocalTimeOptions] = useState([]);

  useEffect(() => {
    if (user.timeFormat === 'hour12Mode') {
      setLocalTimeOptions(
        timeOptions.map((option) => ({
          ...option,
          label: moment(option.value, 'h:mm A').format('h:mm A'),
        }))
      );
    } else {
      setLocalTimeOptions(timeOptions);
    }
  }, []);

  const formatOptionLabel = ({ label }, context) => {
    if (context.context === 'menu') {
      return (
        <div>
          <span className="material-symbols-outlined icon">
            nest_clock_farsight_analog
          </span>
          <div className="label">{label}</div>
        </div>
      );
    } else {
      return <div className="label">{label}</div>;
    }
  };

  useEffect(() => {
    if (!datePicker.current) {
      return;
    }

    const _this = datePicker.current;

    _this.onOutsideAction = (event) => {
      var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === '[object SafariRemoteNotification]';
        })(
          !window['safari'] ||
            (typeof safari !== 'undefined' && window['safari'].pushNotification)
        );
      if (isSafari) {
        if (
          _this.wrapper &&
          !_this.wrapper.contains(event.target) &&
          event.target.id !== ''
        ) {
          _this.closeCalendar();
          return;
        }
      } else {
        if (_this.wrapper && !_this.wrapper.contains(event.target)) {
          _this.closeCalendar();
        }
      }
    };
  });

  useEffect(() => {
    if (value) {
      const initialDateTime = moment(value);
      setDate(new Date(initialDateTime));
      const time24 =
        user.timeFormat === 'hour12Mode'
          ? initialDateTime.format('h:mm A')
          : initialDateTime.format('HH:mm');
      setTime(time24);
      setInputValue(time24);
    } else {
      setTime('00:00');
      setInputValue('00:00');
      setDate(value);
    }
  }, [value]);

  useEffect(() => {
    if (date && time) {
      if (typeof onBlur === 'function') {
        onBlur();
      }
      let newValue;

      const hours = parseInt(time.substring(0, time.indexOf(':')));
      const minutes = parseInt(time.substring(time.indexOf(':') + 1));
      if (user.timeFormat !== 'hour12Mode') {
        newValue = moment(date)
          .set({
            hour: hours,
            minute: minutes,
          })
          .toISOString();
      } else {
        if (time.toUpperCase().includes('PM')) {
          newValue = moment(date)
            .set({
              hour: hours < 12 ? hours + 12 : hours,
              minute: minutes,
            })
            .toISOString();
        } else {
          newValue = moment(date)
            .set({
              hour: hours === 12 ? 0 : hours,
              minute: minutes,
            })
            .toISOString();
        }
      }
      if (value !== newValue && typeof onChange === 'function') {
        onChange(newValue);
      }
    }
  }, [date, time]);
  const checkIfValid = (inputValue) => {
    return user.timeFormat === 'hour12Mode'
      ? moment(inputValue, 'h:mm A', true).isValid() ||
          moment(inputValue, 'h:mmA', true).isValid() ||
          moment(inputValue, 'hmmA', true).isValid() ||
          moment(inputValue, 'hmm A', true).isValid()
      : moment(inputValue, 'HH:mm', true).isValid() ||
          moment(inputValue, 'HHmm', true).isValid();
  };
  return (
    <>
      <div className="form-datetimepicker" id="form-datetimepicker">
        <div className="row">
          <div className={type === 'datetime-local' ? 'col-md-6' : 'col-md-12'}>
            {!disableDateTimeLabel && (
              <label className="form-label">
                {!!dateLabel ? `${dateLabel}:` : 'Date:'}
                {required ? (
                  <span aria-label="Required field" className="required">
                    *
                  </span>
                ) : (
                  ''
                )}
              </label>
            )}
            <DatePicker
              ref={datePicker}
              onChange={setDate}
              value={date}
              clearIcon={null}
              calendarIcon={null}
              minDate={new Date(minDate)}
              maxDate={new Date(maxDate)}
              disabled={disabled}
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              calendarType={'US'}
              formatShortWeekday={(locale, date) => {
                const weekday = date.toLocaleDateString(locale, {
                  weekday: 'short',
                });
                if (weekday === 'Sun' || weekday === 'Thu') {
                  return weekday.slice(0, 2);
                }
                return weekday.slice(0, 1);
              }}
              {...props}
            />
          </div>
          {type === 'datetime-local' && (
            <div className="col-md-6 mt-2 mt-md-0">
              {!disableDateTimeLabel && (
                <label className="form-label">
                  {!!timeLabel ? `${timeLabel}:` : 'Time:'}
                  {required ? (
                    <span aria-label="Required field" className="required">
                      *
                    </span>
                  ) : (
                    ''
                  )}
                </label>
              )}
              <div className={'form-selectbox time-select'}>
                <CreatableSelect
                  isDisabled={disabled}
                  ref={selectRef}
                  onInputChange={(inputValue, { action }) => {
                    if (action === 'input-change') {
                      setInputValue(inputValue);
                    }
                  }}
                  inputValue={inputValue}
                  components={{
                    Input,
                  }}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={localTimeOptions}
                  openMenuOnClick={false}
                  onBlur={() => {
                    if (inputValue?.length === 0) {
                      if (user.timeFormat === 'hour12Mode') {
                        setInputValue('12:00 AM');
                        setTime('12:00 AM');
                      } else {
                        setInputValue('00:00');
                        setTime('00:00');
                      }
                    } else if (checkIfValid(inputValue)) {
                      const time24 =
                        user.timeFormat === 'hour12Mode'
                          ? moment(inputValue, 'h:mm A').format('h:mm A')
                          : moment(inputValue, 'HH:mm').format('HH:mm');
                      setTime(time24);
                      setInputValue(time24);
                    } else {
                      //not valid, return to original
                      setInputValue(time);
                    }
                  }}
                  value={
                    inputValue?.length < 1
                      ? null
                      : {
                          value: time,
                          label:
                            user.timeFormat === 'hour12Mode'
                              ? moment(time, 'h:mm A').format('h:mm A')
                              : moment(time, 'HH:mm').format('HH:mm'),
                        }
                  }
                  onKeyDown={(e) => {
                    //limit the input
                    if (user.timeFormat === 'hour12Mode') {
                      if (
                        !/^[\d:AMPamp\s]+$/.test(e.key) &&
                        e.key !== 'Backspace' &&
                        e.key !== 'ArrowLeft' &&
                        e.key !== 'ArrowRight'
                      ) {
                        e.preventDefault();
                      }
                    } else {
                      if (
                        !/^[\d:]+$/.test(e.key) &&
                        e.key !== 'Backspace' &&
                        e.key !== 'ArrowLeft' &&
                        e.key !== 'ArrowRight'
                      ) {
                        e.preventDefault();
                      }
                    }
                    if (e.key === 'Enter') {
                      if (inputValue?.length === 0) {
                        if (user.timeFormat === 'hour12Mode') {
                          setInputValue('12:00 AM');
                          setTime('12:00 AM');
                        } else {
                          setInputValue('00:00');
                          setTime('00:00');
                        }
                        selectRef.current.blur();
                      } else if (checkIfValid(inputValue)) {
                        const time24 =
                          user.timeFormat === 'hour12Mode'
                            ? moment(inputValue, 'HH:mm A').format('h:mm A')
                            : moment(inputValue, 'HH:mm').format('HH:mm');
                        setTime(time24);
                        setInputValue(time24);
                        selectRef.current.blur();
                      } else {
                        //not valid, return to original
                        setInputValue(time);
                        selectRef.current.blur();
                      }
                    }
                  }}
                  onChange={(selected) => {
                    const time24 =
                      user.timeFormat === 'hour12Mode'
                        ? moment(selected.value, 'HH:mm A').format('h:mm A')
                        : moment(selected.value, 'HH:mm').format('HH:mm');
                    setInputValue(time24);
                    setTime(time24);
                  }}
                  placeholder={''}
                  filterOption={false}
                  menuPlacement="auto"
                  noOptionsMessage={() => 'No Items Found'}
                  formatOptionLabel={formatOptionLabel}
                  isValidNewOption={(inputValue) => {
                    return checkIfValid(inputValue);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
