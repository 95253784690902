import config from 'constants/apiConfig'
import { productionEnv } from 'constants/debug'

const azureApiScope = productionEnv ? window.env.AZURE_API_SCOPE : process.env.REACT_APP_AZURE_API_SCOPE
const b2cClientId = productionEnv ? window.env.AZURE_B2C_APP_CLIENT_ID : process.env.REACT_APP_AZURE_B2C_APP_CLIENT_ID

// B2C
export const B2Cpolicies = {
    names: {
        signUpSignIn: "B2C_1_signupsignin1",
        forgotPassword: "B2C_1_passwordreset1"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://disastertechb2c.b2clogin.com/disastertechb2c.onmicrosoft.com/B2C_1_signupsignin1",
        },
        forgotPassword: {
            authority: "https://disastertechb2c.b2clogin.com/disastertechb2c.onmicrosoft.com/B2C_1_passwordreset1",
        },
    },
    authorityDomain: "disastertechb2c.b2clogin.com"
}

export const B2CapiConfig = {
	b2cScopes: ["https://disastertechb2c.onmicrosoft.com/api/access.user"],
	webApi: config.apiPrefix,
};

export const B2CloginRequest = {
  scopes: ["openid", "profile",...B2CapiConfig.b2cScopes],
};

export const B2CtokenRequest = {
	scopes: [...B2CapiConfig.b2cScopes],
	loginHint: "user@disastertech.com",
	forceRefresh: false,
};

export const B2CmsalConfig = {
  auth: {
    clientId: b2cClientId,
    authority: B2Cpolicies.authorities.signUpSignIn.authority,
    knownAuthorities: ["https://login.microsoftonline.com/common",B2Cpolicies.authorityDomain],
    redirectUri: window.location.origin
  },
  cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
      storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
};

// /B2C

// AAD
export const msalConfig = {
  auth: {
      clientId: productionEnv ? window.env.AZURE_CLIENT_ID : process.env.REACT_APP_AZURE_CLIENT_ID,
      audience: productionEnv ? window.env.AZURE_API_AUDIENCE : process.env.REACT_APP_AZURE_API_AUDIENCE,
      redirectUri: window.location.origin,
      knownAuthorities: ["https://login.microsoftonline.com/common",B2Cpolicies.authorityDomain],
  },
  cache: {
      cacheLocation: 'sessionStorage'
  }
};

export const loginRequest = {
  scopes: [azureApiScope,"user.read"]
};

export const ssoRequest = {
  scopes: [azureApiScope,"user.read"],
  loginHint: "user@disastertech.com"
};

export const tokenRequest = {
  scopes: [azureApiScope,"user.read"]
};

// /AAD
