import React, { useEffect, useState } from 'react';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import dayjs from 'dayjs';

export default function ICSIncidentResource({
  row,
  ics_resources,
  ics_incident_resources,
}) {
  const versions = ics_incident_resources.find(
    (ir) => ir.resource_id === row.id
  )?.versions;

  const [version, setVersion] = useState(!!versions && versions[0]);

  const [name, setName] = useState(row.name);
  const [description, setDescription] = useState(row.description);
  const [quantity, setQuantity] = useState(row.quantity);
  const [dateTimeOrdered, setDateTimeOrdered] = useState(row.date_time_ordered);
  const [eta, setEta] = useState(row.eta);
  const [arrived, setArrived] = useState(row.arrived);
  const [location, setLocation] = useState(row.location);
  const [status, setStatus] = useState(row.status);
  const [notes, setNotes] = useState(row.notes);
  const [archived, setArchived] = useState(row.archived);
  const [lastModifiedBy, setLastModifiedBy] = useState(row.user_name);

  const [nameChanged, setNameChanged] = useState(false);
  const [descriptionChanged, setDescriptionChanged] = useState(false);
  const [quantityChanged, setQuantityChanged] = useState(false);
  const [dateTimeOrderedChanged, setDateTimeOrderedChanged] = useState(false);
  const [etaChanged, setEtaChanged] = useState(false);
  const [arrivedChanged, setArrivedChanged] = useState(false);
  const [locationChanged, setLocationChanged] = useState(false);
  const [statusChanged, setStatusChanged] = useState(false);
  const [notesChanged, setNotesChanged] = useState(false);
  const [archivedChanged, setArchivedChanged] = useState(false);
  const [lastModifiedByChanged, setLastModifiedByChanged] = useState(false);

  useEffect(() => {
    if (!!version) {
      setName(version.name);
      setDescription(version.description);
      setQuantity(version.quantity);
      setDateTimeOrdered(version.date_time_ordered);
      setEta(version.eta);
      setArrived(version.arrived);
      setLocation(version.location);
      setStatus(version.status);
      setNotes(version.notes);
      setArchived(version.archived);
      setLastModifiedBy(version.user_name);

      // Check if changed from prior
      const vi = versions.findIndex((v) => v.id === version.id);
      if (!!versions[vi + 1]) {
        setNameChanged(versions[vi + 1].name !== version.name);
        setDescriptionChanged(
          versions[vi + 1].description !== version.description
        );
        setQuantityChanged(versions[vi + 1].quantity !== version.quantity);
        setDateTimeOrderedChanged(
          versions[vi + 1].date_time_ordered !== version.date_time_ordered
        );
        setEtaChanged(versions[vi + 1].eta !== version.eta);
        setArrivedChanged(versions[vi + 1].arrived !== version.arrived);
        setLocationChanged(versions[vi + 1].location !== version.location);
        setStatusChanged(versions[vi + 1].status !== version.status);
        setNotesChanged(versions[vi + 1].notes !== version.notes);
        setArchivedChanged(versions[vi + 1].archived !== version.archived);
        setLastModifiedByChanged(
          versions[vi + 1].user_name !== version.user_name
        );
      } else {
        setNameChanged(false);
        setDescriptionChanged(false);
        setQuantityChanged(false);
        setDateTimeOrderedChanged(false);
        setEtaChanged(false);
        setArrivedChanged(false);
        setLocationChanged(false);
        setStatusChanged(false);
        setNotesChanged(false);
        setArchivedChanged(false);
        setLastModifiedByChanged(false);
      }
    } else {
      setName(row.name);
      setDescription(row.description);
      setQuantity(row.quantity);
      setDateTimeOrdered(row.date_time_ordered);
      setEta(row.eta);
      setArrived(row.arrived);
      setLocation(row.location);
      setStatus(row.status);
      setNotes(row.notes);
      setArchived(row.archived);
      setLastModifiedBy(row.user_name);
      setNameChanged(false);
      setDescriptionChanged(false);
      setQuantityChanged(false);
      setDateTimeOrderedChanged(false);
      setEtaChanged(false);
      setArrivedChanged(false);
      setLocationChanged(false);
      setStatusChanged(false);
      setNotesChanged(false);
      setArchivedChanged(false);
      setLastModifiedByChanged(false);
    }
  }, [version]);

  useEffect(() => {
    if (!!versions) {
      setVersion(versions[0]);
    }
  }, [versions]);

  return (
    <div className="ICSIncidentResource">
      {!!versions && (
        <div className="mb-4">
          <h6 className="mb-2">Version:</h6>
          <StylishNewSelect
            options={versions.map((v) => {
              return {
                label: dayjs(v.timestamp).format('YYYY-MM-DD HH:mm'),
                value: v,
              };
            })}
            defaultValue={version}
            value={dayjs(version.timestamp).format('YYYY-MM-DD HH:mm')}
            onChange={(e) => {
              setVersion(e.value);
            }}
            placeholder={dayjs(version.timestamp).format('YYYY-MM-DD HH:mm')}
            isClearable={true}
            isSearchable={true}
            isMulti={false}
            isDisabled={false}
            className="SitrepSection-Select"
          />
        </div>
      )}
      <div className="mb-4">
        <h6 className="mb-2">Name:</h6>
        <p className="m-0" style={{ color: nameChanged ? 'yellow' : 'white' }}>
          {name}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Description:</h6>
        <p
          className="m-0"
          style={{ color: descriptionChanged ? 'yellow' : 'white' }}
        >
          {description}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Quantity:</h6>
        <p
          className="m-0"
          style={{ color: quantityChanged ? 'yellow' : 'white' }}
        >
          {quantity}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Date Time Ordered:</h6>
        <p
          className="m-0"
          style={{ color: dateTimeOrderedChanged ? 'yellow' : 'white' }}
        >
          {dayjs(dateTimeOrdered).format('YYYY-MM-DD HH:mm')}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">ETA:</h6>
        <p className="m-0" style={{ color: etaChanged ? 'yellow' : 'white' }}>
          {eta ? dayjs(eta).format('YYYY-MM-DD HH:mm') : 'N/A'}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Arrived:</h6>
        <p
          className="m-0"
          style={{ color: arrivedChanged ? 'yellow' : 'white' }}
        >
          {arrived ? 'Yes' : 'No'}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Location:</h6>
        <p
          className="m-0"
          style={{ color: locationChanged ? 'yellow' : 'white' }}
        >
          {location}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Status:</h6>
        <p
          className="m-0"
          style={{ color: statusChanged ? 'yellow' : 'white' }}
        >
          {status}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Notes:</h6>
        <p className="m-0" style={{ color: notesChanged ? 'yellow' : 'white' }}>
          {notes}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Last Modified By:</h6>
        <p
          className="m-0"
          style={{ color: lastModifiedByChanged ? 'yellow' : 'white' }}
        >
          {lastModifiedBy}
        </p>
      </div>
      <div className="mb-4">
        <h6 className="mb-2">Archived:</h6>
        <p
          className="m-0"
          style={{ color: archivedChanged ? 'yellow' : 'white' }}
        >
          {archived ? 'Yes' : 'No'}
        </p>
      </div>
    </div>
  );
}
