import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { createContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  Legends,
  SpecialInterestOptions,
  StyleLabel,
} from 'assets/data/config';

import TRRCardList from './TRRCardList';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const TechnicalDisciplineContext = createContext({
  technicalDisciplines: [],
  setTechnicalDisciplines: (technical_discipline) => {},
  selectedTechnicalDiscipline: '',
  setSelectedTechnicalDiscipline: (selectedTechnicalDiscipline) => {},
  trrCountWithTechnicalDiscipline: 0,
  setTrrCountWithTechnicalDiscipline: (trrCountWithTechnicalDiscipline) => {},
  lastTrrLevel1ItemNo: 0,
  setLastTrrLevel1ItemNo: () => {},
});

export const TRR = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const trrListRef = useRef();

  const { selectedWorkspace, type: workspaceType } = useSelector((state) => {
    return state?.prepare?.workspace;
  });
  const [technicalDisciplines, setTechnicalDisciplines] = useState([]);

  const [
    selectedTechnicalDiscipline,
    setSelectedTechnicalDiscipline,
  ] = useState('');

  const [trrLevels, setTrrLevels] = useState();
  const [lastTrrLevel1ItemNo, setLastTrrLevel1ItemNo] = useState(0);
  const [
    trrCountWithTechnicalDiscipline,
    setTrrCountWithTechnicalDiscipline,
  ] = useState(0);

  useEffect(() => {
    if (selectedWorkspace) {
      getAllTTechnicalDiscipline();
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    if (selectedWorkspace) {
      getTrrList(1);
    }
  }, [selectedWorkspace, selectedTechnicalDiscipline]);

  useEffect(() => {
    if (workspaceType && workspaceType !== 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  const getAllTTechnicalDiscipline = async () => {
    try {
      const resp = await Network.get(API.fetchTd, {
        workspaceId: selectedWorkspace,
      });
      const technicalDisciplines = resp.data.response.dataset.rows.map((td) => {
        return {
          label: td?.discipline_name,
          value: td?.id,
        };
      });
      setTechnicalDisciplines([...technicalDisciplines]);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getTrrList = async (level) => {
    try {
      dispatch(startLoading());
      const resp = await Network.get(API.fetchTRR, {
        workspaceId: selectedWorkspace,
        level,
        td_id: selectedTechnicalDiscipline?.value || '',
      });

      const trrLevelData = resp.data.response.dataset.map((trr) => {
        return {
          ...trr,
          trrLevel: level,
          active: false,
          item_no: trr?.item_no.trim(),
          special_interest: SpecialInterestOptions.find(
            (i) => i.value === trr?.special_interest.trim()
          ),
          description: trr?.description?.trim(),
          comments: trr?.comments?.trim(),
          risk_character: trr?.risk_character,
          technical_discipline_item: trr?.technical_discipline,
          child: [],
        };
      });
      if (
        !selectedTechnicalDiscipline ||
        (!!selectedTechnicalDiscipline && selectedTechnicalDiscipline === '')
      ) {
        setTrrCountWithTechnicalDiscipline(trrLevelData.length);
      }
      setTrrLevels(trrLevelData);
      setLastTrrLevel1ItemNo(
        Number(resp.data.response.last_trr_level_1_item_no || 0)
      );
    } catch (error) {
      console.log('error', error);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() => navigate(`${PreparePath}/dashboard/plan`)}
            >
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            TRR
          </li>
        </ol>
      </nav>
      <div className="d-xl-flex align-items-end">
        <div className="flex-grow-1 mb-4 mb-xl-0">
          <h4 className="mb-1">Technical Readiness Review (TRR)</h4>
          <h5 className="m-0 weight-400">Program Risk Assessment Checklist</h5>
        </div>
        <div className="d-md-flex align-items-center flex-shrink-0">
          {Legends.map((i, idx) => (
            <div
              key={idx}
              className={`d-flex align-items-center ${
                idx !== 0 ? 'ms-md-4 mt-2 mt-md-0' : ''
              }`}
            >
              <span
                style={{ ...StyleLabel, background: i?.color }}
                className="me-3"
              ></span>
              {i?.name}
            </div>
          ))}
        </div>
      </div>
      <hr className="my-4" />
      <div className="d-flex align-items-md-center flex-column flex-md-row mb-4">
        <div className="flex-grow-1 d-lg-flex align-items-center order-1 order-md-0">
          <h4 className="m-0 mb-2 mb-lg-0 weight-700">TRR Level 1</h4>
          <StylishNewButton
            type={'button'}
            className={'button--primary ms-lg-4'}
            onClick={() => trrListRef.current.addTrrLevelOne()}
          >
            Add TRR Level 1
          </StylishNewButton>
        </div>
        <div className="ms-md-auto flex-shrink-0 d-lg-flex align-items-center mb-3 mb-md-0">
          <p className="p--l weight-700 m-0 mb-2 mb-lg-0 me-lg-4">
            Technical Discipline
          </p>
          <StylishNewSelect
            classes={'trr__form-selectbox'}
            options={technicalDisciplines}
            placeholder={'Select...'}
            isClearable={true}
            isSearchable={false}
            isMulti={false}
            onChange={(e) => setSelectedTechnicalDiscipline(e)}
            value={selectedTechnicalDiscipline}
          />
        </div>
      </div>
      <div className="trrs">
        <TechnicalDisciplineContext.Provider
          value={{
            technicalDisciplines,
            setTechnicalDisciplines,
            selectedTechnicalDiscipline: selectedTechnicalDiscipline?.value,
            setSelectedTechnicalDiscipline,
            trrCountWithTechnicalDiscipline,
            setTrrCountWithTechnicalDiscipline,
            getTrrList,
            lastTrrLevel1ItemNo,
            setLastTrrLevel1ItemNo,
          }}
        >
          <TRRCardList
            technicalDisciplines={technicalDisciplines}
            ref={trrListRef}
            trrLevels={trrLevels}
            onExpandTrr={(level, trr_level_1, trr_level_2) =>
              onExpandTrr(level, trr_level_1, trr_level_2)
            }
            updateTrrLevels={(updatedTrrLevels) =>
              setTrrLevels(updatedTrrLevels)
            }
          />
        </TechnicalDisciplineContext.Provider>
      </div>
    </>
  );
};
