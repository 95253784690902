import React, { useEffect } from 'react';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

export const AddEditSystemDataSourceDialog = ({
  show,
  onClose,
  dialogType,
  updateDialogData,
}) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const eventId = useSelector((state) => state.prepare.planDashboard.eventId);

  const onSubmit = async (data) => {
    const submittedData = {
      id: updateDialogData?.id,
      source: data.source,
      provider: data.provider,
      collector: data.collector,
      method: data.method,
      workspaceId: workspaceId,
      planEventId: eventId,
    };

    dispatch(startLoading());
    try {
      const sourceData = await Network.post(
        updateDialogData?.id
          ? API.updateDataCollection
          : API.createDataCollection,
        submittedData
      );
      onClose(sourceData.data.response.dataset[0]);
      toast.success(
        dialogType === 'Add'
          ? 'Source Data created successfully'
          : 'Source Data updated successfully',
        toastConfig
      );
    } catch (error) {
      dispatch(endLoading());
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{dialogType} System Data Source</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-4">
            <label className="form-label">Source</label>
            <Controller
              control={control}
              name="source"
              rules={{
                required: true,
                maxLength: inputStringLength,
              }}
              defaultValue={updateDialogData?.source}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  onChange={onChange}
                  value={value || ''}
                />
              )}
            />
            {errors.source?.type === 'required' && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
            {errors?.source?.type === 'maxLength' && (
              <span className="form-text form-error">
                Max {inputStringLength} character allowed
              </span>
            )}
          </div>
          <div className="mb-4">
            <label className="form-label">Provider</label>
            <Controller
              control={control}
              name="provider"
              rules={{
                required: true,
                maxLength: inputStringLength,
              }}
              defaultValue={updateDialogData?.provider}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  onChange={onChange}
                  value={value || ''}
                />
              )}
            />
            {errors.provider?.type === 'required' && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
            {errors?.provider?.type === 'maxLength' && (
              <span className="form-text form-error">
                Max {inputStringLength} character allowed
              </span>
            )}
          </div>
          <div className="mb-4">
            <label className="form-label">Collector</label>
            <Controller
              control={control}
              name="collector"
              rules={{
                required: true,
                maxLength: inputStringLength,
              }}
              defaultValue={updateDialogData?.collector}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  onChange={onChange}
                  value={value || ''}
                />
              )}
            />
            {errors.collector?.type === 'required' && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
            {errors?.collector?.type === 'maxLength' && (
              <span className="form-text form-error">
                Max {inputStringLength} character allowed
              </span>
            )}
          </div>
          <div className="mb-0">
            <label className="form-label">Method</label>
            <Controller
              control={control}
              name="method"
              rules={{
                required: true,
                maxLength: inputStringLength,
              }}
              defaultValue={updateDialogData?.method}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  onChange={onChange}
                  value={value || ''}
                />
              )}
            />
            {errors.method?.type === 'required' && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
            {errors?.method?.type === 'maxLength' && (
              <span className="form-text form-error">
                Max {inputStringLength} character allowed
              </span>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={onClose}
              type="button"
            >
              Cancel
            </StylishNewButton>

            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
