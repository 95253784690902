import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import Sitrep from 'components/DisasterGPT/Sitrep';
import { initSitrep } from 'actions/dgptActions';
import {
  getAORLocationInfo,
  getCurrentWeatherData,
  getForecastWeatherData,
} from 'components/DisasterGPT/DisasterGPTDataHelpers/EventInfoHelpers';
import { chatDGPTSessionsSchema } from 'components/DisasterGPT/DisasterGPTConstants';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { noAutoClose } from 'assets/data/config';

export default function AORAIAnalysis({ aor, eventData, eriData, map }) {
  const reduxDispatch = useDispatch();

  const [isFetchingEventInfo, setIsFetchingEventInfo] = useState(false);

  const reduxChatDGPTSession = useSelector((state) => {
    return state.app.chatDGPTSessions.find(
      (s) =>
        !!s.functions &&
        s.functions.sitrep === 'AOR' &&
        s.initSessionInfo.id === aor.id
    );
  });

  const isFetchSitrepsLoaded = useSelector((state) => {
    return state.app.isFetchSitrepsLoaded && state.app.isInitSitrepLoaded;
  });

  const reduxSitrep = useSelector((state) => {
    return state.app.sitreps.find((s) => s.sitrep_source_id === aor.id);
  });

  async function getAORInfo(aor) {
    setIsFetchingEventInfo(true);

    let chatDGPTSession = { ...chatDGPTSessionsSchema[0] };

    chatDGPTSession.dgpt_session_id = generateUUID();
    chatDGPTSession.functions.sitrep = 'AOR';
    chatDGPTSession.initSessionInfo = aor;

    try {
      chatDGPTSession.centroid = aor?.location?.centroid.geometry;

      chatDGPTSession.locationInfo = await getAORLocationInfo(
        aor,
        chatDGPTSession.centroid
      );
      chatDGPTSession.weatherCurrent = await getCurrentWeatherData(
        chatDGPTSession.centroid
      );
      chatDGPTSession.weatherForecast = await getForecastWeatherData(
        chatDGPTSession.centroid
      );
      chatDGPTSession.events = eventData;
      chatDGPTSession.powerOutagePredictions = eriData;

      reduxDispatch(
        initSitrep({
          chatDGPTSession,
          source: 'AOR',
          source_id: aor.id,
        })
      );
    } catch (e) {
      toast.error(
        'Error retrieving AOR Info for Analysis:' + e.message,
        noAutoClose
      );
      console.error('AOR Info ERROR: ', e);
    }
    setIsFetchingEventInfo(false);
  }

  return (
    <div>
      {!reduxChatDGPTSession && (
        <>
          {!isFetchingEventInfo && !!isFetchSitrepsLoaded && !reduxSitrep ? (
            <div className="analysis-content py-3 px-4">
              <div className="small-title">
                <span className="material-symbols-outlined fs-6 me-2">
                  auto_awesome
                </span>
                DISASTER GPT
              </div>
              <div className="big-title">
                Welcome to <b>Disaster GPT</b>
              </div>
              <div className="p-3 my-3 bg-inverse">
                <b>Name: </b>
                {aor?.name}
                <br />
                <b>Location: </b>
                {aor?.location?.address ? aor.location.address : 'N/A'}
              </div>
              <StylishNewButton
                disabled={!!isFetchingEventInfo}
                onClick={async () => await getAORInfo(aor)}
                className="generate-button"
              >
                Generate Report
              </StylishNewButton>
            </div>
          ) : (
            !reduxSitrep && (
              <div>
                <span className="d-flex justify-content-center">
                  Fetching AutoSITREP
                </span>
                <div className="d-flex justify-content-center">
                  <i className="fa fa-spinner fa-pulse fs-6"></i>
                </div>
              </div>
            )
          )}
        </>
      )}
      {!!reduxSitrep && <Sitrep sitrep={reduxSitrep} map={map} />}
    </div>
  );
}
