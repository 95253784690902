import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../../../service/api';
import AddEditSubSourceDialog from '../../../../Settings/SubSource/AddEditSubSourceDialog';
import AddEditPrimarySourceDialog from '../../../../Settings/PrimarySource/AddEditPrimarySourceDialog';
import Network from '../../../../../service/Network';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export default function AddEditFundingSourceDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
    setValue,
    setError,
  } = useForm();

  const [allPrimarySource, setAllPrimarySource] = useState([]);
  const [allSubSource, setAllSubSource] = useState([]);
  const [showSubSourceModal, setShowSubSourceModal] = useState(false);
  const [showPrimarySourceModal, setShowPrimarySourceModal] = useState(false);
  const [primarySearch, setPrimarySearch] = useState('');
  const [subSearch, setSubSearch] = useState('');
  // const [allListLoaded, setAllListLoaded] = useState(false);

  const dispatch = useDispatch();

  const eventId = useSelector((state) => state.prepare.planDashboard.eventId);

  // const exerciseId = useSelector(
  //   (state) => state.prepare.exercise.exerciseDetailsId
  // );
  const { selectedWorkspace, type } = useSelector(
    (state) => state.prepare.workspace
  );
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );

  const getAllPrimarySource = async () => {
    dispatch(startLoading());
    try {
      const primarySource = await Network.get(API.primarySource, {
        workspaceId: selectedWorkspace,
        search: primarySearch,
      });
      let primarySourceData = [
        { value: 'new', label: 'Add New' },

        ...primarySource.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      ];
      setAllPrimarySource(primarySourceData);

      if (updateDialogData?.id) {
        setValue(
          'primarysource',
          primarySourceData.find(
            (data) => data.value === updateDialogData?.primarySourceId
          )
        );
      }
      // setAllPrimarySource(primarySourceData);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllSubSource = async () => {
    dispatch(startLoading());
    try {
      const accreTasks = await Network.get(API.subSource, {
        workspaceId: selectedWorkspace,
        search: subSearch,
      });
      let subSourceData = [
        { value: 'new', label: 'Add New' },

        ...accreTasks.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      ];

      setAllSubSource(subSourceData);
      if (updateDialogData?.id) {
        setValue(
          'subsource',
          subSourceData.find(
            (data) => data.value === updateDialogData?.subSourceId
          )
        );
      }
      // setAllSubSource(subSourceData);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const onCloseAfterSaveSubSource = (afterSave) => {
    setShowSubSourceModal(false);
    if (afterSave?.id) {
      setAllSubSource([
        ...allSubSource,
        {
          label: afterSave?.title,
          value: afterSave?.id,
        },
      ]);

      setValue('subsource', {
        label: afterSave?.title,
        value: afterSave?.id,
      });
      getAllSubSource();
      clearErrors('subsource');
    } else {
      setValue('subsource', '');
    }
  };
  const onCloseAfterSavePrimarySource = (afterSave) => {
    setShowPrimarySourceModal(false);

    if (afterSave?.id) {
      setValue('primarysource', {
        label: afterSave?.title,
        value: afterSave?.id,
      });
      setAllPrimarySource([
        ...allPrimarySource,
        {
          label: afterSave?.title,
          value: afterSave?.id,
        },
      ]);

      clearErrors('primarysource');

      // getAllPrimarySource();
    } else {
      setValue('primarysource', '');
    }
  };

  useEffect(() => {
    if (show) {
      setValue('amount', updateDialogData?.amount);
      getAllPrimarySource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspace, primarySearch]);

  useEffect(() => {
    if (show) {
      setValue('amount', updateDialogData?.amount);
      getAllSubSource();
    }
  }, [selectedWorkspace, subSearch]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const inputData = {
      id: updateDialogData?.id,
      primarysource: data.primarysource.value,
      subsource: data.subsource.value,
      amount: data.amount,
      workspaceId: selectedWorkspace,
      planEventId: type === 'T&E' ? eventId : exerciseId,
    };
    dispatch(startLoading());
    try {
      const newTraining = await Network.post(
        updateDialogData ? API.editFunding : API.addFunding,
        inputData
      );
      toast.success(
        dialogType === 'Add'
          ? 'Fundings added successfully'
          : 'Fundings edited successfully',
        toastConfig
      );
      onClose(newTraining.data.response.dataset[0]);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  //Add your search logic here.
  const customFilter = (option, searchText, type) => {
    if (option?.value === 'new') return true;
    if (
      option?.data?.label?.toLowerCase().includes(searchText?.toLowerCase())
    ) {
      if (type === 'primarysource') {
        setPrimarySearch(searchText);
      } else if (type === 'subsource') {
        setSubSearch(searchText);
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Funding Source</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label className="form-label">Source</label>
              <Controller
                control={control}
                name="primarysource"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allPrimarySource}
                    // defaultValue={value}
                    value={value}
                    onChange={(e) => {
                      if (e?.value === 'new') {
                        setShowPrimarySourceModal(true);
                        clearErrors('primarysource');
                        return onChange();
                      } else if (!e) {
                        setValue('primarysource', '');
                        setError('primarysource');
                      } else if (e.value) {
                        clearErrors('primarysource');
                      }

                      return onChange(e);
                    }}
                    placeholder={'Primary Source'}
                    filterOption={(option, searchText) =>
                      customFilter(option, searchText, 'primarysource')
                    }
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors?.primarysource && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Sub-source</label>
              <Controller
                control={control}
                name="subsource"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allSubSource}
                    defaultValue={value}
                    value={value}
                    onChange={(e) => {
                      if (e?.value === 'new') {
                        clearErrors('subsource');
                        setShowSubSourceModal(true);

                        return onChange();
                      } else if (!e) {
                        setValue('subsource', '');
                        setError('subsource');
                      } else if (e.value) {
                        clearErrors('subsource');
                      }
                      return onChange(e);
                    }}
                    placeholder={'Sub-source'}
                    filterOption={(option, searchText) =>
                      customFilter(option, searchText, 'subsource')
                    }
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.subsource && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-12">
              <label className="form-label">Amount ($)</label>
              <Controller
                control={control}
                name={'amount'}
                rules={{
                  required: true,
                  validate: (value) => {
                    const numericValue = parseFloat(value);
                    return (
                      numericValue <= 1000000000 &&
                      /^[0-9]+(\.[0-9]{1,2})?$/.test(value)
                    );
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <StylishNewInput
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={value}
                      type="number"
                      name="amount"
                      defaultValue={updateDialogData?.amount}
                      placeholder="0"
                      step="0.01"
                      className="form-control"
                    />
                    {errors?.amount && (
                      <span className="form-text form-error">
                        {errors?.amount?.type === 'validate' &&
                          'Please enter a valid amount (up to 1 billion with up to 2 decimal places).'}
                        {errors?.amount?.type === 'required' &&
                          'This is a required field'}
                      </span>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
      {showSubSourceModal && (
        <AddEditSubSourceDialog
          show={showSubSourceModal}
          onClose={(afterSave) => onCloseAfterSaveSubSource(afterSave)}
          dialogType="Add"
        />
      )}
      {showPrimarySourceModal && (
        <AddEditPrimarySourceDialog
          show={showPrimarySourceModal}
          onClose={(afterSave) => onCloseAfterSavePrimarySource(afterSave)}
          dialogType="Add"
        />
      )}
    </Modal>
  );
}
