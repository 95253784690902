import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Title  from 'components/DesignSystems/text/Title'
import axios from 'axios';

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';


import {
  uploadSOP,
  fetchSOPs,
  deleteSOP
} from 'actions/dgptActions'

import "./SOPs.css"

const SOPs = () => {

  const reduxDispatch = useDispatch()

  const [file, setFile] = useState(null);

  const reduxSOPs = useSelector(state=>{
    return state.app.SOPs
  })

  const onFileChange = event => {
    setFile(event.target.files[0]);
  };

  const onFileUpload = () => {
    reduxDispatch(uploadSOP(file))
  };

  useEffect(() => {
    reduxDispatch(fetchSOPs());
  }, []);

  const columns = [
    {
      dataField: 'filename',
      text: 'File Name',
      sort: true,
      attrs: { title: 'File Name' },
    },
    {
      dataField: 'filetype',
      text: 'File Type',
      sort: true,
      attrs: { title: 'File Type' },
    },
    {
      dataField: 'delete',
      text: 'Delete',
      sort: true,
      attrs: { title: 'Delete' },
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            <StylishNewButton 
              onClick={()=>reduxDispatch(deleteSOP(row.id))}
            >
              Delete
            </StylishNewButton>
          </div>);
      },
    },
  ];

  return (
    <div className="SOPs">
      <h3>Standard Operating Procedures</h3>

      <div>
        <label>Upload SOPs to optimize PlanAI on your procedures</label>
        <div>
          <input type="file" onChange={onFileChange} />
        </div>
        <div>
          <StylishNewButton 
            onClick={onFileUpload}
          >
            Upload!
          </StylishNewButton>
        </div>
      </div>    

      <StylishNewTable
        keyField={'id'}
        rows={reduxSOPs}
        columns={columns}
      />  

    </div>
  );
};

export default SOPs;
