import React from 'react';

const ExpandRightArrow = () => {
  return (
    <button className="button--icon sml border-0">
      <i className="material-symbols-outlined">arrow_right</i>
    </button>
  );
};

export default ExpandRightArrow;
