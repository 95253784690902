import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import Info from 'components/DesignSystems/text/Info';
import Sitrep from 'components/DisasterGPT/Sitrep';
import { initSitrep } from 'actions/dgptActions';
import {
  getEventCentroid,
  getCurrentWeatherData,
  getForecastWeatherData,
} from 'components/DisasterGPT/DisasterGPTDataHelpers/EventInfoHelpers';
import { chatDGPTSessionsSchema } from 'components/DisasterGPT/DisasterGPTConstants';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { toast } from 'react-toastify';

export default function EventAIAnalysis({ event, locationInfo, map }) {
  const reduxDispatch = useDispatch();

  const [isFetchingEventInfo, setIsFetchingEventInfo] = useState(false);

  const reduxChatDGPTSession = useSelector((state) => {
    return state.app.chatDGPTSessions.find(
      (s) =>
        !!s.functions &&
        !!s.functions.sitrep &&
        s.initSessionInfo.instance_id === event.instance_id
    );
  });
  const isFetchChatDGPTLoaded = useSelector((state) => {
    return state.app.isFetchChatDGPTLoaded;
  });
  const isFetchSitrepsLoaded = useSelector((state) => {
    return state.app.isFetchSitrepsLoaded;
  });
  const reduxSitrep = useSelector((state) => {
    return state.app.sitreps.find(
      (s) => s.sitrep_source_id === event.instance_id
    );
  });

  async function getEventInfo(event, locationInfo) {
    setIsFetchingEventInfo(true);

    let chatDGPTSession = { ...chatDGPTSessionsSchema[0] };

    chatDGPTSession.dgpt_session_id = generateUUID();
    chatDGPTSession.functions.sitrep = 'EVENTS';
    chatDGPTSession.locationInfo = locationInfo;

    try {
      chatDGPTSession.centroid = await getEventCentroid(event);
      chatDGPTSession.weatherCurrent = await getCurrentWeatherData(
        chatDGPTSession.centroid
      );
      chatDGPTSession.weatherForecast = await getForecastWeatherData(
        chatDGPTSession.centroid
      );

      // Remove geojson to prevent 413 entity too large errors for complex geojson
      chatDGPTSession.initSessionInfo = {
        ...event,
        geojson: null,
      };

      if (!chatDGPTSession.locationInfo) {
        locationInfo = chatDGPTSession.centroid;
      }

      reduxDispatch(
        initSitrep({
          chatDGPTSession,
          source: 'EVENTS',
          source_id: event.instance_id,
        })
      );
    } catch (e) {
      toast.error('Error retrieving Event Info for Analysis:' + e.message);
      console.error('Event Info ERROR: ', e);
    }
    setIsFetchingEventInfo(false);
  }

  return (
    <div>
      {!reduxChatDGPTSession && (
        <>
          {(!isFetchingEventInfo && !!isFetchSitrepsLoaded && !reduxSitrep && (
            <div className="analysis-content py-3 px-4">
              <div className="small-title">
                <span className="material-symbols-outlined fs-6 me-2">
                  auto_awesome
                </span>
                DISASTER GPT
              </div>
              <div className="big-title">
                Welcome to <b>Disaster GPT</b>
              </div>
              <div className="p-3 my-3 bg-inverse">
                <b>Event Type: </b>
                {event?.hazard_name}
                <br />
                <b>Place: </b>
                {event?.properties?.place ? event?.properties?.place : 'N/A'}
              </div>
              <StylishNewButton
                disabled={!!isFetchingEventInfo}
                onClick={async () => await getEventInfo(event, locationInfo)}
                className="generate-button"
              >
                Generate Report
              </StylishNewButton>
            </div>
          )) ||
            (!reduxSitrep && (
              <div>
                <div className="d-flex justify-content-center">
                  Fetching Event Info for analysis...
                </div>
                <div className="d-flex justify-content-center">
                  <i className="fa fa-spinner fa-pulse fs-6"></i>
                </div>
                <Info className="d-flex justify-content-center">
                  AutoSITREP can take between 10-60 seconds to complete.
                </Info>
              </div>
            ))}
        </>
      )}
      {!!reduxSitrep && <Sitrep sitrep={reduxSitrep} map={map} />}
    </div>
  );
}
