import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { useEffect, useState } from 'react';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const types = [
  { label: 'MOE', value: 'MOE' },
  { label: 'MOS', value: 'MOS' },
  { label: 'MOP', value: 'MOP' },
];

export const AddEditTestObjectiveDialog = ({
  show,
  onClose,
  data,
  coicId,
  dialogType,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      associatedplantestobjective: [
        {
          type: { label: 'MOE', value: 'MOE' },
          id: '',
          description: '',
        },
      ],
    },
  });

  const dispatch = useDispatch();
  const { eventId } = useSelector((state) => state?.prepare?.planDashboard);

  const [newCoicId, setNewCoicId] = useState(0);

  const { selectedWorkspace } = useSelector((state) => {
    return state.prepare.workspace;
  });
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'associatedplantestobjective',
  });

  useEffect(() => {
    if (data) {
      setValue('COIC_ID', data?.coic);
      setValue('description', data?.description);
      if (
        data.associatedplantestobjective &&
        !!data?.associatedplantestobjective?.length
      ) {
        data.associatedplantestobjective.forEach((obj, idx) => {
          const selectedType = types.find(
            (type) => type.value === obj?.type
          ) || { label: '', value: '' };
          setValue(`associatedplantestobjective.${idx}.type`, selectedType); // Setting value based on the types array

          setValue(`associatedplantestobjective.${idx}.id`, obj?.item);
          setValue(
            `associatedplantestobjective.${idx}.description`,
            obj?.description
          );
          if (idx + 1 !== data?.associatedplantestobjective?.length)
            append({
              type: { label: 'MOE', value: 'MOE' },
              id: `${coicId}.1`,
              description: '',
            });
        });
      }
    } else {
      dispatch(startLoading());
      if (selectedWorkspace) {
        try {
          Network.get(API.getCoicNumber, {
            workspaceId: selectedWorkspace,
            planEventId: eventId,
          })
            .then((resp) => {
              const coicNumber = resp?.data?.response?.number;

              setValue('COIC_ID', coicNumber);

              const updatedDefaultValue = [
                {
                  type: { label: 'MOE', value: 'MOE' },
                  id: '',
                  description: '',
                },
              ];
              setValue('associatedplantestobjective', updatedDefaultValue);
              setNewCoicId(coicNumber);
            })
            .catch(console.log)
            .finally(() => {
              dispatch(endLoading());
            });
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [data]);

  const onSubmit = async (formData) => {
    dispatch(startLoading());
    try {
      const subCoics = formData.associatedplantestobjective.map((item) => ({
        type: item.type.value,
        item: item.id,
        description: item.description,
      }));

      const newData = {
        planEventId: eventId,
        workspaceId: selectedWorkspace,
        coic: formData.COIC_ID,
        description: formData.description,
        subCoics: subCoics,
      };

      const newTestObjective = await Network.post(
        dialogType === 'Add' ? API.addTestObjective : API.updateTestObjective,
        {
          ...newData,
          workspaceId: workspaceId,
          id: data?.id,
        }
      );
      onClose(newTestObjective.data.response.dataset[0]);
      toast.success(
        dialogType === 'Add'
          ? 'Test objective created successfully'
          : 'Test objective updated successfully',
        toastConfig
      );
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Test Objectives</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-3 pe-md-2 mb-4 mb-md-0">
              <label className="form-label">COIC</label>
              <Controller
                control={control}
                name="COIC_ID"
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value || ''}
                    readOnly
                    disabled
                  />
                )}
              />
            </div>
            <div className="col-md-9 ps-md-2">
              <label className="form-label">Description</label>
              <Controller
                control={control}
                name="description"
                rules={{
                  required: true,
                  maxLength: inputStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
              />
              {errors.description?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.description?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>
          </div>

          {fields.map((item, index) => (
            <div key={item.id}>
              <div className="d-md-flex align-items-center mt-4">
                <div className="row flex-grow-1">
                  <div className="col-md-3 pe-md-2 mb-2 mb-md-0">
                    <Controller
                      control={control}
                      name={`associatedplantestobjective.${index}.type`}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewSelect
                          options={types}
                          onChange={onChange}
                          value={value}
                          isClearable={false}
                          isMulti={false}
                          isSearchable={false}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-2 px-md-2 mb-2 mb-md-0">
                    <Controller
                      control={control}
                      name={`associatedplantestobjective.${index}.id`}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="number"
                          onChange={onChange}
                          value={value || ''}
                          // readOnly
                          // disabled
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-7 ps-md-2">
                    <Controller
                      control={control}
                      name={`associatedplantestobjective.${index}.description`}
                      rules={{
                        required: true,
                        maxLength: inputStringLength,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="text"
                          onChange={onChange}
                          value={value || ''}
                          placeholder="Enter description"
                        />
                      )}
                    />
                  </div>
                </div>
                <div
                  className="button-group flex-shrink-0 ms-md-4 mt-3 mt-md-0"
                  style={{ width: '5.25rem' }}
                >
                  {(fields.length > 1 && (
                    <StylishNewButton
                      type="button"
                      className={'button--icon primary'}
                      onClick={() => remove(index)}
                    >
                      <SharedIcon iconName="delete" />
                    </StylishNewButton>
                  )) ||
                    null}
                  {(index === fields.length - 1 && (
                    <StylishNewButton
                      type="button"
                      className={'button--icon primary'}
                      onClick={() =>
                        append({
                          type: { label: 'MOE', value: 'MOE' },
                          id: '',
                          description: '',
                        })
                      }
                    >
                      <SharedIcon iconName="add" classes="px-0" bold />
                    </StylishNewButton>
                  )) ||
                    null}
                </div>
              </div>
              {(errors.associatedplantestobjective?.[index]?.id?.type ===
                'required' ||
                errors.associatedplantestobjective?.[index]?.description
                  ?.type === 'required') && (
                <span className="form-text form-error">
                  Please enter the
                  {errors.associatedplantestobjective?.[index]?.id?.type ===
                    'required' &&
                  errors.associatedplantestobjective?.[index]?.description
                    ?.type === 'required'
                    ? ' id and description'
                    : errors.associatedplantestobjective?.[index]?.id?.type ===
                      'required'
                    ? ' id'
                    : ' description'}
                </span>
              )}

              {/* {errors.associatedplantestobjective?.[index]?.description
                ?.type === 'required' && (
                <span className="form-text form-error">
                  Please enter the description
                </span>
              )} */}
              {errors?.associatedplantestobjective?.[index]?.description
                ?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed for description
                </span>
              )}
            </div>
          ))}
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={() => onClose()}
            >
              Cancel
            </StylishNewButton>

            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
