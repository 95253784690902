import AddEditSupportDialog from 'PREPAREsrc/pages/Settings/Support/AddEditSupportDialog';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import {
  inputStringLength,
  textareaStringLength,
  toastConfig,
} from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

const legends = [
  {
    name: 'Overdue',
    color: '#FF0000',
  },
  {
    name: 'In-Progress',
    color: '#FBC02D',
  },
  {
    name: 'Completed',
    color: '#20BA38',
  },
  {
    name: 'Not Started',
    color: '#FFFFFF',
  },
].map((i, idx) => ({
  value: i?.name?.toLowerCase(),
  label: (
    <div className="d-flex align-items-center flex-grow-1" key={idx}>
      <span
        style={{
          backgroundColor: i?.color,
          height: '20px',
          width: '20px',
          borderRadius: '4px',
        }}
        className="me-2 flex-shrink-0"
      ></span>
      {i?.name}
    </div>
  ),
}));

export const AddEditPoamDialog = (props) => {
  const { show, onClose, dialogType, editPoamData } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [supportList, setSupportList] = useState([]);
  const [taskNo, setTaskNo] = useState([]);
  const [eventCells, setEventCells] = useState([]);
  const [showCreateSupportDialog, setShowCreateSupportDialog] = useState(false);
  const [isNewSupportAdded, setIsNewSupportAdded] = useState(false);

  const statusOptions = legends;

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = useForm();

  const { eventId } = useSelector((state) => state.prepare.planDashboard);
  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const reduxDispatch = useDispatch();

  useEffect(() => {
    setIsNewSupportAdded(false);
  }, []);

  const fetchSupportList = () => {
    try {
      reduxDispatch(startLoading());
      Network.get(API.getSupportList, {
        workspaceId: selectedWorkspace,
      })
        .then((resp) => {
          setSupportList([
            { label: 'Add New', value: 'add_support' },
            ...resp?.data?.response?.dataset?.map((data) => ({
              value: data?.id,
              label: data?.title,
            })),
          ]);
          reduxDispatch(endLoading());
        })
        .catch((error) => {
          console.log(error);
          reduxDispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
      reduxDispatch(endLoading());
    }
  };

  const fetchPOAMTaskId = () => {
    try {
      reduxDispatch(startLoading());
      Network.get(API.fetchPOAMTaskId, {
        workspaceId: selectedWorkspace,
        planEventId: eventId,
      })
        .then((resp) => {
          setTaskNo(resp?.data?.response?.taskId);
          reduxDispatch(endLoading());
        })
        .catch((error) => {
          console.log(error);
          reduxDispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
      reduxDispatch(endLoading());
    }
  };

  const fetchCell = () => {
    try {
      reduxDispatch(startLoading());
      Network.get(API.fetchAllCell, {
        workspaceId: selectedWorkspace,
        planEventId: eventId,
      })
        .then((resp) => {
          setEventCells(
            resp?.data?.response?.dataset?.map((data) => ({
              value: data?.id,
              label: data?.cell,
            }))
          );
          reduxDispatch(endLoading());
        })
        .catch((error) => {
          console.log(error);
          reduxDispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
      reduxDispatch(endLoading());
    }
  };

  useEffect(() => {
    if (dialogType === 'Add') {
      fetchPOAMTaskId();
    }
    fetchSupportList();
    fetchCell();
  }, [selectedWorkspace, dialogType]);

  useEffect(() => {
    if (editPoamData?.id) {
      setTaskNo(editPoamData?.taskNo);
      setValue('task', editPoamData?.task);
      setValue('due_date', moment(editPoamData?.dueDate));
      setValue(
        'support',
        supportList?.find((sl) => sl.value === editPoamData?.supportId)
      );
      setValue(
        'cell',
        eventCells.find((ec) => ec.value === editPoamData?.cellId)
      );
      setValue(
        'status',
        statusOptions?.find(
          (so) => so.value.toLowerCase() === editPoamData?.status?.toLowerCase()
        )
      );
      setValue('linked_artifact', editPoamData?.linkedArtifact);
    }
  }, [editPoamData?.id, statusOptions, supportList, eventCells]);

  const onSubmit = async (data) => {
    reduxDispatch(startLoading());

    const submitData = {
      id: editPoamData?.id,
      taskNo: taskNo,
      task: data?.task,
      dueDate: data?.due_date ? moment(data.due_date)?.startOf('day') : null,
      cellId: data?.cell?.value,
      supportId: data?.support?.value,
      linkedArtifact: data?.linked_artifact,
      status: data?.status?.value,
      planEventId: eventId,
      workspaceId: selectedWorkspace,
      completed_on:
        data?.status?.value === 'completed'
          ? editPoamData?.completed_on
            ? editPoamData?.completed_on
            : moment()
          : null,
    };

    try {
      const response = await Network.post(
        submitData.id ? API.updatePOAM : API.createPOAM,
        submitData
      );
      if (response !== undefined && response?.status === 200) {
        onClose(true, isNewSupportAdded);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      reduxDispatch(endLoading());
    } finally {
      reduxDispatch(endLoading());
    }
  };

  const handleSupportDialogClose = (data) => {
    setShowCreateSupportDialog(false);
    if (data) {
      const updatedData = [
        ...supportList,
        { label: data?.title, value: data?.id },
      ];
      setSupportList(updatedData);
      setValue('support', { label: data?.title, value: data?.id });
      setIsNewSupportAdded(true);
      clearErrors('support');
    } else {
      setValue('support', '');
    }
  };

  return (
    <>
      <Modal
        show={show}
        // onHide={onClose}
        onHide={() => onClose(false, isNewSupportAdded)}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Plan of Action & Milestones (POA&M)</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="d-flex mb-4">
              <span className="bg-gray-900 rounded-5 py-2 px-3 weight-700 d-flex">
                Task ID - {taskNo}
              </span>
            </div>
            <div className="row">
              <div className="col-md-12 mb-4">
                <label className="form-label">Task</label>
                <Controller
                  control={control}
                  name="task"
                  rules={{
                    required: true,
                    maxLength: textareaStringLength,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewTextArea
                      value={value || ''}
                      onChange={onChange}
                      placeholder="Input Task"
                      disableLengthMessage
                    />
                  )}
                />
                {errors.task?.type === 'required' && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
                {errors?.task?.type === 'maxLength' && (
                  <span className="form-text form-error">
                    Max {textareaStringLength} character allowed
                  </span>
                )}
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">Due Date</label>
                <Controller
                  control={control}
                  name="due_date"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <StylishDateTimePicker
                      value={value}
                      onChange={(e) => onChange(e)}
                      minDate={
                        editPoamData?.dueDate &&
                        moment(editPoamData?.dueDate).isBefore(moment())
                          ? value
                          : moment()
                      }
                      // minDate={moment().startOf('day')}
                      disableDateTimeLabel
                    />
                  )}
                />
                {errors.due_date?.type === 'required' && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">Support</label>
                <Controller
                  control={control}
                  name="support"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={supportList}
                      value={value}
                      // onChange={(e) => onChange(e)}
                      onChange={(e) => {
                        if (e?.value === 'add_support') {
                          setShowCreateSupportDialog(true);
                          return;
                        }
                        onChange(e);
                      }}
                      isSearchable={false}
                      isClearable={false}
                      isMulti={false}
                    />
                  )}
                />
                {errors.support?.type === 'required' && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">Cell</label>
                <Controller
                  control={control}
                  name="cell"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={eventCells}
                      value={value}
                      onChange={onChange}
                      isSearchable={false}
                      isClearable={false}
                      isMulti={false}
                      placeholder="Select..."
                    />
                  )}
                />
                {errors.cell?.type === 'required' && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">Status</label>
                <Controller
                  control={control}
                  name="status"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={statusOptions}
                      value={value}
                      onChange={onChange}
                      isClearable={false}
                      isSearchable={false}
                      isMulti={false}
                      isDisabled={false}
                      placeholder="Select..."
                    />
                  )}
                />
                {errors.status?.type === 'required' && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
              <div className="col-md-12">
                <label className="form-label">Linked Artifact</label>
                <Controller
                  control={control}
                  name="linked_artifact"
                  rules={{
                    required: false,
                    maxLength: inputStringLength,
                    pattern: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
                  }}
                  render={({ field: { onChange, value } }) => (
                    // <StylishNewTextArea
                    //   value={value || ''}
                    //   onChange={onChange}
                    //   placeholder="(Insert hyperlink to document / web page)"
                    //   disableLengthMessage
                    // />
                    <StylishNewInput
                      type="text"
                      onChange={onChange}
                      value={value || ''}
                      placeholder="(Insert hyperlink to document / web page)"
                    />
                  )}
                />
                {/* {errors.linked_artifact?.type === 'required' && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )} */}
                {errors?.linked_artifact?.type === 'maxLength' && (
                  <span className="form-text form-error">
                    Max {inputStringLength} character allowed
                  </span>
                )}
                {errors?.linked_artifact?.type === 'pattern' && (
                  <span className="form-text form-error">
                    Please insert valid URL
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                // onClick={() => onClose()}
                onClick={() => onClose(false, isNewSupportAdded)}
              >
                Cancel
              </StylishNewButton>

              <StylishNewButton className="button--primary" type="submit">
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {(showCreateSupportDialog && (
        <AddEditSupportDialog
          show={showCreateSupportDialog}
          onClose={(data) => handleSupportDialogClose(data)}
          dialogType={'Add'}
        />
      )) ||
        null}
    </>
  );
};
