import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { AllIcon } from 'assets/Icon/Icon';
import { toastConfig } from 'assets/data/config';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import IconDownload from 'assets/images/icon__download.svg';
import fileDownload from 'js-file-download';
import IconEdit from 'assets/images/icon__edit.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import IconView from 'assets/images/icon__eye.svg';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const TimelineDashboard = () => {
  const [timelineList, setTimelineList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [deleteTimelineId, setDeleteTimelineId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const perPage = 10;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedWorkspace: workspaceId, type: workspaceType } = useSelector(
    (state) => state?.prepare?.workspace
  );

  useEffect(() => {
    if (workspaceType && workspaceType !== 'T&E')
      navigate(`${PreparePath}/dashboard/plan`);
  }, [workspaceType, workspaceId]);
  const onDownload = async (file) => {
    let fullFileName = file.split('/')[file.split('/').length - 1];
    try {
      dispatch(startLoading());
      const downloadFile = await Network.post(API.generateSignedUrl, {
        url: file,
      });
      const res = await fetch(downloadFile.data.response.singedUrl, {
        headers: {},
      });

      fileDownload(
        await res.blob(),
        fullFileName.split('-')[fullFileName.split('-').length - 1]
      );
    } catch (error) {
      console.log('Error: ', error);
      toast.error('Failed to download file', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      attrs: { title: 'Timeline Name' },
    },
    {
      dataField: 'mission_name',
      text: 'Mission',
      attrs: { title: 'Mission' },
    },
    {
      dataField: 'start_date_time',
      isDummyField: true,
      text: 'Start Date/Time',
      attrs: { title: 'Start Date/Time' },
      formatter: (_, row) =>
        moment(row?.start_date).format('DD MMM YY - hh:mm a'),
    },
    {
      dataField: 'status',
      isDummyField: true,
      text: 'Status',
      attrs: { title: 'Status' },
      formatter: (_, row) => (
        <span
          className="status status--sml"
          style={{ textTransform: 'capitalize', background: row?.status_color }}
        >
          {row?.status_title}
        </span>
      ),
    },
    {
      dataField: 'profiles',
      isDummyField: true,
      text: 'Profile(s)',
      attrs: { title: 'Profile(s)' },
      formatter: (_, row) =>
        row?.profiles?.map((i, idx) => i?.name)?.join(', '),
    },
    {
      dataField: 'proevent_cellfiles',
      isDummyField: true,
      text: 'Event Cell(s)',
      attrs: { title: 'Event Cell(s)' },
      formatter: (_, row) =>
        row?.cells?.map((i, idx) => (
          <span
            key={idx}
            style={{
              textTransform: 'capitalize',
            }}
          >
            {`${idx !== 0 ? ', ' : ''}${i?.name}`}
          </span>
        )),
    },
    {
      dataField: 'notification',
      isDummyField: true,
      text: 'Notification',
      attrs: { title: 'Notification' },
      formatter: (_, row) => (
        <div className="d-flex align-items-center">
          <span
            style={{
              background: row?.notification_method_color,
              width: '1.5rem',
              height: '1.5rem',
            }}
            className="rounded-5 me-2 d-flex align-items-center justify-content-center has-img-h-16"
          >
            {
              AllIcon.find(
                (icon) => icon.name === row?.notification_method_icon
              )?.icon
            }
          </span>
          {row?.notification_method_name}
        </div>
      ),
    },
    {
      dataField: 'last_updated',
      text: 'Last Updated',
      attrs: { title: 'Last Updated' },
      formatter: (_, row) => moment(row?.updatedAt).fromNow(),
    },
    {
      dataField: 'action',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Timeline"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteTimeline}
          onEdit={() =>
            navigate(
              `${PreparePath}/dashboard/plan/missions/timeline/${row?.id}?page=1`
            )
          }
          onView={() =>
            navigate(
              `${PreparePath}/dashboard/plan/missions/timeline/view/${row?.id}`
            )
          }
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-6 mb-4">
              <span className="weight-600 d-block mb-1">
                Primary Action Officer
              </span>
              {row?.primary_action_officer || '-'}
            </div>
            <div className="col-md-6 mb-4">
              <span className="weight-600 d-block mb-1">Vignette</span>
              {row.vignette ? (
                <span className="d-flex align-items-center">
                  <span className="color-brand">
                    {row?.vignette
                      ?.split('/')
                      .pop()
                      .replace(/%20/g, ' ')
                      .split('-')
                      .slice(1)
                      .join('-')}
                  </span>
                  <span
                    className="ms-3 cursor-pointer"
                    onClick={() => onDownload(row?.vignette)}
                  >
                    <img src={IconDownload} alt="" />
                  </span>
                  <span
                    className="ms-3 cursor-pointer has-img-h-16"
                    onClick={() => handleVignetteDelete(row?.id)}
                  >
                    <svg
                      width="11"
                      height="12"
                      viewBox="0 0 11 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.57305 6.84982C8.57305 5.85587 8.57091 4.86193 8.57391 3.86758C8.5752 3.44394 8.93507 3.16655 9.32365 3.31484C9.46889 3.3704 9.58285 3.52768 9.67924 3.66005C9.72937 3.72868 9.71994 3.84388 9.71994 3.93784C9.72208 5.89182 9.72808 7.8458 9.71866 9.79978C9.71437 10.6932 9.29966 11.38 8.4471 11.7672C8.14206 11.9057 7.77747 11.9801 7.43816 11.985C5.9104 12.0075 4.38221 11.9997 2.85446 11.9936C1.55762 11.9883 0.575671 11.0556 0.571816 9.82143C0.565818 7.85397 0.569245 5.8861 0.570959 3.91864C0.570959 3.45456 0.90213 3.18575 1.31899 3.30259C1.55548 3.36877 1.71057 3.5669 1.71742 3.82509C1.72342 4.04283 1.71871 4.26099 1.71871 4.47873C1.71871 6.24193 1.71871 8.00554 1.71871 9.76873C1.71871 10.4681 2.17155 10.8991 2.9063 10.8991C4.39849 10.8995 5.89069 10.8995 7.38289 10.8991C8.1185 10.8991 8.57134 10.4685 8.5722 9.76996C8.57262 8.79644 8.5722 7.82292 8.5722 6.849L8.57305 6.84982Z"
                        fill="#efa2a9"
                      />
                      <path
                        d="M2.86541 1.63502C2.86541 1.28246 2.86369 0.957278 2.86584 0.6325C2.86884 0.212127 3.08219 0.00296181 3.52047 0.00214476C4.60524 -0.00071492 5.69 -0.00071492 6.77477 0.00214476C7.21176 0.00337034 7.42298 0.213353 7.42555 0.636177C7.42769 0.960955 7.42555 1.28614 7.42555 1.63502C7.5258 1.63502 7.60206 1.63502 7.67832 1.63502C8.33466 1.63502 8.99144 1.63053 9.64778 1.63666C10.1092 1.64074 10.3894 1.98472 10.251 2.36383C10.173 2.5779 10.0072 2.69188 9.77759 2.72579C9.72147 2.73396 9.66363 2.73028 9.60665 2.73028C6.63082 2.73028 3.65499 2.72946 0.679163 2.73232C0.39726 2.73232 0.169768 2.65552 0.0510942 2.39856C-0.124131 2.01904 0.170196 1.64074 0.646174 1.63666C1.29566 1.63094 1.94515 1.63502 2.59422 1.63502C2.67219 1.63502 2.75016 1.63502 2.86455 1.63502H2.86541ZM4.0123 1.1068V1.61868H6.27566V1.1068H4.0123Z"
                        fill="#efa2a9"
                      />
                      <path
                        d="M3.43192 6.79466C3.43192 6.18881 3.43063 5.58297 3.43235 4.97712C3.43321 4.60986 3.65941 4.3676 3.99787 4.36434C4.33204 4.36107 4.57752 4.60496 4.57881 4.96691C4.58309 6.19903 4.58309 7.43114 4.57881 8.66325C4.57752 9.0248 4.33204 9.2691 3.99787 9.26583C3.65984 9.26256 3.43321 9.01949 3.43235 8.65263C3.43063 8.03331 3.43192 7.41357 3.43192 6.79425V6.79466Z"
                        fill="#efa2a9"
                      />
                      <path
                        d="M6.8602 6.81509C6.8602 7.44136 6.8602 8.06763 6.8602 8.6939C6.8602 8.93411 6.75181 9.11713 6.52218 9.21722C6.31782 9.30628 6.09375 9.29933 5.94466 9.14246C5.82985 9.02194 5.72445 8.83974 5.72231 8.68328C5.70432 7.43768 5.7086 6.19209 5.7146 4.94608C5.71631 4.59802 5.96694 4.36066 6.29426 4.36393C6.62628 4.3672 6.85806 4.60578 6.85978 4.9563C6.86277 5.57562 6.86063 6.19535 6.86063 6.81468L6.8602 6.81509Z"
                        fill="#efa2a9"
                      />
                    </svg>
                  </span>
                </span>
              ) : (
                'No Vignette Attached'
              )}
            </div>
            <div className="col-md-12 mb-4">
              <span className="weight-600 d-block mb-1">Remarks</span>
              {row?.remarks || '-'}
            </div>
            <div className="col-md-12 mb-4">
              <span className="weight-600 d-block mb-2">Assigned Systems</span>
              <table className="table no-filter">
                <thead>
                  <tr className="empty">
                    <th>System</th>
                    <th>Component</th>
                    <th>Platform Type</th>
                    <th>MSN Quantity</th>
                    <th>Cell</th>
                    <th>Location/Launch Point</th>
                  </tr>
                </thead>
                <tbody>
                  {(!!row?.systems?.length && (
                    <>
                      {row?.systems?.map((i) => (
                        <tr key={i?.mission_systems_id}>
                          <td title="System">{i?.system_name || '-'}</td>
                          <td title="Component">
                            {i?.system_component
                              ?.map((data) => data.system_component_name)
                              ?.join(', ') || '-'}
                          </td>
                          <td title="Platform Type">
                            {i?.system_platform_type || '-'}
                          </td>
                          <td title="MSN Quantity">
                            {i?.system_msn_quantity || '-'}
                          </td>
                          <td title="Cell">
                            {i?.system_event_cell_type === 'red_cell'
                              ? 'Red Cell'
                              : i?.system_event_cell_type === 'blue_cell'
                              ? 'Blue Cell'
                              : '-'}
                          </td>
                          <td title="Location/Launch Point">
                            {i?.location_name || '-'}
                          </td>
                        </tr>
                      ))}
                    </>
                  )) || (
                    <tr>
                      <td className="text-center weight-600" colSpan={'6'}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 mb-4">
              <span className="weight-600 d-block mb-2">Test Objectives</span>
              <table className="table no-filter">
                <thead>
                  <tr className="empty">
                    <th>COIC</th>
                    <th>Associations</th>
                  </tr>
                </thead>
                <tbody>
                  {(!!row?.test_objectives?.length && (
                    <>
                      {row?.test_objectives?.map((i) => (
                        <tr key={i?.id}>
                          <td title="COIC">
                            {i?.coic
                              ? `COIC #${i.coic} ${i?.description}`
                              : '-'}
                          </td>
                          <td title="Associations">
                            {i?.associations
                              ?.map(
                                (data) =>
                                  `${data.association_type} ${data.association_item} ${data.association_description}`
                              )
                              ?.join(', ') || '-'}
                          </td>
                        </tr>
                      ))}
                    </>
                  )) || (
                    <tr>
                      <td className="text-center weight-600" colSpan={'2'}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="button-group">
              <StylishNewButton
                customButton
                className="button--icon primary"
                onClick={() =>
                  navigate(
                    `${PreparePath}/dashboard/plan/missions/timeline/view/${row?.id}`
                  )
                }
              >
                <img src={IconView} alt="" />
              </StylishNewButton>
              <StylishNewButton
                customButton
                className="button--icon primary"
                onClick={() =>
                  navigate(
                    `${PreparePath}/dashboard/plan/missions/timeline/${row?.id}?page=1`
                  )
                }
              >
                <img src={IconEdit} alt="" />
              </StylishNewButton>
              <StylishNewButton
                customButton
                className="button--icon primary"
                onClick={() => {
                  onDeleteTimeline(row);
                }}
              >
                <img src={IconDelete} alt="" />
              </StylishNewButton>
            </div>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const onDeleteTimeline = (row) => {
    setDeleteTimelineId(row.id);
  };

  const onDeleteTimelineConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMissionTimeline, {
        id: deleteTimelineId,
        workspaceId,
      });
      setDeleteTimelineId(null);
      fetchAllMissionTimeline();
      toast.success('Mission Timeline deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchAllMissionTimeline = () => {
    try {
      dispatch(startLoading());
      Network.get(API.fetchAllMissionTimeline, {
        workspaceId,
        limit: perPage,
        page,
      })
        .then((resp) => {
          setTimelineList(resp.data.response.dataset);
          setTotal(resp.data.response.total);
        })
        .catch((error) => {
          console.error(error);
          toast.error('Something went wrong!', toastConfig);
        })
        .finally(() => dispatch(endLoading()));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllMissionTimeline();
  }, [workspaceId, page]);

  const handleVignetteDelete = async (id) => {
    try {
      dispatch(startLoading());
      const response = await Network.post(API.deleteVignette, {
        id,
        workspaceId,
      });
      if (response !== undefined && response?.status === 200) {
        fetchAllMissionTimeline();
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() => navigate(`${PreparePath}/dashboard/plan/missions`)}
            >
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Mission Timeline Events List
          </li>
        </ol>
      </nav>
      <div className="d-md-flex align-items-center mb-4">
        <h4 className="m-0">Mission Timeline Events</h4>
        <StylishNewButton
          className="button--primary ms-auto w-100 w-md-auto mt-3 mt-md-0"
          onClick={() =>
            navigate(
              `${PreparePath}/dashboard/plan/missions/timeline/create-timeline`
            )
          }
        >
          Add Mission Timeline Event
        </StylishNewButton>
      </div>
      <div className="form-block">
        <StylishNewTable
          keyField="id"
          classes="no-filter"
          headerClasses="empty"
          columns={columns}
          rows={timelineList}
          expandRow={expandRow}
          pagination={true}
          total={total}
          page={page}
          perPage={perPage}
          onPageChange={setPage}
        />
      </div>

      {deleteTimelineId && (
        <StylishConfirmDialog
          show={deleteTimelineId}
          onClose={() => setDeleteTimelineId(null)}
          dialogType={'Delete'}
          dialogTitle={'Delete Mission Timeline'}
          dialogContent={'Are you sure you want this?'}
          updateDialogData={deleteTimelineId}
          onConfirm={onDeleteTimelineConfirm}
        />
      )}
    </>
  );
};
