import { AllIcon } from 'assets/Icon/Icon';
import moment from 'moment';
import { useState } from 'react';
import { MissionTimelineDialog } from './TimelineDetails/MissionTimelineDialog';

export const MissionTimelineItem = ({
  item,
  missionId,
  statusLabel,
  updateMissionTimeList,
}) => {
  const [
    showMissionTimelineDetailsDialog,
    setShownMissionTimelineDetailsDialog,
  ] = useState(false);
  const [missionTimelineId, setMissionTimelineId] = useState();
  const showMissionTimelineDetails = (missionTimelineId) => {
    setMissionTimelineId(missionTimelineId);
    setShownMissionTimelineDetailsDialog(true);
  };
  return (
    <>
      {(item?.timelines && !!item?.timelines?.length && (
        <div className="row">
          {item.timelines.map((timeline, index) => {
            return (
              <>
                <div className="row-info mb-2 mb-md-0">
                  <div className="info d-xl-flex align-items-start justify-content-end h-100 position-md-relative ps-4 ps-lg-5 ps-xl-0">
                    {index === 0 ? (
                      <span className="date">
                        {item.date && moment(item.date).format('MMM Do YYYY')}
                      </span>
                    ) : null}
                    <span
                      className="round"
                      style={{ borderColor: '#0099FF' }}
                    ></span>

                    <span
                      className="status"
                      style={{ background: timeline.status_color }}
                    >
                      {timeline.status}
                    </span>
                  </div>
                </div>

                <div
                  className={`row-cards ${
                    index !== item.timelines.length - 1
                      ? 'ms-auto mb-3 mb-md-2'
                      : ''
                  }`}
                  key={timeline.mission_timeline_id}
                >
                  <div className="row">
                    <div className="col-md-12 mb-4 mb-md-0">
                      <div
                        className="card rounded-5 d-block"
                        style={{ background: timeline.status_color }}
                      >
                        <span
                          className="stretched-link"
                          onClick={() =>
                            showMissionTimelineDetails(
                              timeline.mission_timeline_id
                            )
                          }
                        ></span>
                        <div className="d-md-flex align-items-start p-0 text-start">
                          <p className="flex-grow-1 m-0 me-md-3">
                            {/* {timeline.timeline_number && (
                              <span>#{timeline.timeline_number} - </span>
                            )} */}
                            <span>{timeline.mission_timeline_name}</span>
                          </p>
                          {(timeline?.cells && !!timeline?.cells?.length && (
                            <p className="m-0 ms-auto flex-shrink-0 mt-1 mt-md-0">
                              {timeline?.cells?.map((cell, index) => (
                                <span className="text-capitalize">
                                  {`${(index !== 0 && ', ') || ''}${cell.cell}`}
                                </span>
                              ))}
                            </p>
                          )) ||
                            null}
                        </div>
                        <div className="d-md-flex align-items-end m-0 p-0 mt-2 text-start">
                          <p className="m-0 me-3 flex-grow-0">
                            Profile -{' '}
                            {(timeline?.profiles &&
                              !!timeline?.profiles?.length &&
                              timeline?.profiles?.map((profile, index) => (
                                <span className="text-capitalize">
                                  {`${(index !== 0 && ', ') || ''}${
                                    profile.name
                                  }`}
                                </span>
                              ))) ||
                              'N/A'}
                          </p>
                          {(timeline?.notification_method_name && (
                            <div className="d-flex align-items-center ms-auto flex-shrink-0 mt-2 mt-md-0">
                              <span
                                style={{
                                  background:
                                    timeline?.notification_method_color,
                                  width: '1.5rem',
                                  height: '1.5rem',
                                }}
                                className="rounded-5 me-2 d-flex align-items-center justify-content-center has-img-h-16"
                              >
                                {
                                  AllIcon.find(
                                    (icon) =>
                                      icon.name ===
                                      timeline?.notification_method_icon
                                  )?.icon
                                }
                              </span>
                              {timeline?.notification_method_name}
                            </div>
                          )) ||
                            null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )) ||
        null}
      {(showMissionTimelineDetailsDialog && (
        <MissionTimelineDialog
          show={showMissionTimelineDetailsDialog}
          onClose={() => setShownMissionTimelineDetailsDialog(false)}
          missionTimelineId={missionTimelineId}
          missionId={missionId}
          statusLabel={statusLabel}
          updateMissionTimeList={updateMissionTimeList}
        />
      )) ||
        null}
    </>
  );
};
