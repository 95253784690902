import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import {
  createIcsResource,
  updateIcsResource,
} from 'slices/ics_resourcesSlice';

import {
  updateIcsIncidentResource,
  createIcsIncidentResource,
} from 'slices/ics_incidentResourcesSlice';

export default function NewICSIncidentResourceDialog({
  show,
  setEditResourceData,
  editResourceData,
  modalType,
  setModalType,
  onClose,
  incident_id,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [isLoaded, setIsLoaded] = useState(true);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [dateTimeOrdered, setDateTimeOrdered] = useState('');
  const [eta, setEta] = useState('');
  const [arrived, setArrived] = useState(false);
  const [location, setLocation] = useState('');
  const [status, setStatus] = useState('');
  const [notes, setNotes] = useState('');

  const [resourceId, setResourceId] = useState('');
  const reduxDispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (modalType === 'New') {
      setResourceId(generateUUID());
    } else if (modalType === 'Edit') {
      setResourceId(editResourceData.id);
      setValue('name', editResourceData.name);
      setValue('description', editResourceData.description);
      setValue('quantity', editResourceData.quantity);
      if (incident_id) {
        setValue('dateTimeOrdered', editResourceData.dateTimeOrdered);
        setValue('eta', editResourceData.eta);
        setValue('arrived', editResourceData.arrived);
        setValue('location', editResourceData.location);
        setValue('status', editResourceData.status);
        setValue('notes', editResourceData.notes);
      }
    }
  }, [modalType]);

  const onNewResourceDataSubmit = (data) => {
    let payload = {
      id: resourceId,
      name: data.name,
      description: data.description,
      quantity: data.quantity,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      archived: false,
    };

    if (incident_id) {
      payload = {
        ...payload,
        resource_id: resourceId,
        incident_id: incident_id,
        user_guid: data.user_guid,
        date_time_ordered: data.dateTimeOrdered,
        eta: data.eta,
        arrived: data.arrived,
        location: data.location,
        status: data.status,
        notes: data.notes,
      };
    }

    if (modalType === 'New') {
      if (incident_id) {
        reduxDispatch(createIcsIncidentResource(payload));
      } else {
        reduxDispatch(createIcsResource(payload));
      }
      onClose();
    } else if (modalType === 'Edit') {
      if (!incident_id) {
        reduxDispatch(updateIcsResource(payload.id, payload));
      } else if (!!incident_id) {
        payload.id = editResourceData?.versions[0]?.id;
        reduxDispatch(
          updateIcsIncidentResource(incident_id, payload.id, payload)
        );
      }
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Resource</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewResourceDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <StylishNewInput value={resourceId} readOnly disabled />
          </div>
          <div className="m-0">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.name && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <Controller
              control={control}
              name="description"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.description && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Quantity:</label>
            <Controller
              control={control}
              name="quantity"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="number"
                  onChange={(e) => onChange(parseFloat(e.target.value))}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.quantity && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          {incident_id && (
            <>
              <div className="m-0">
                <label className="form-label">Date Time Ordered:</label>
                <Controller
                  control={control}
                  name="dateTimeOrdered"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      type="datetime-local"
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">ETA:</label>
                <Controller
                  control={control}
                  name="eta"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      type="datetime-local"
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Arrived:</label>
                <Controller
                  control={control}
                  name="arrived"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.checked)}
                      checked={value}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Location:</label>
                <Controller
                  control={control}
                  name="location"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={300}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Status:</label>
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={300}
                    />
                  )}
                />
              </div>
              <div className="m-0">
                <label className="form-label">Notes:</label>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewTextArea
                      onChange={(e) => onChange(e.target.value)}
                      value={value || ''}
                      maxLength={300}
                    />
                  )}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
