import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { AppHeader } from 'components/headers/appHeader/AppHeader';
import { useNewUIFlag } from '../utils/featureFlags';
import { useSelector } from 'react-redux';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import Loader from 'components/Loader/loader';
import { toast } from 'react-toastify';
import SelectExerciseModal from './SelectExerciseModal';

export default function ExerciseLayout() {
  const newUI = useNewUIFlag();
  const params = useParams();
  const { pathname } = useLocation();

  const [customUIForMedia, setCustomUIForMedia] = useState(false);
  const [exerciseWorkspaceModal, setExerciseWorkspaceModal] = useState(false);

  const { selectedWorkspace, allWorkspace: exercises } = useSelector((state) => {
    return state?.prepare?.workspace ?? [];
  });
  const userRoles = useSelector((state) => {
    return state.prepare.user.roles;
  });

  const navigate = useNavigate();

  useEffect(() => {
    !!pathname.split(`${params.slug}`)[1]
      ? setCustomUIForMedia(true)
      : setCustomUIForMedia(false);
  }, [pathname, params]);
  
  useEffect(() => {

    if (exercises?.findIndex((exer) => exer.id === selectedWorkspace) < 0) {
      setExerciseWorkspaceModal(true);
      return;
    } else {
      setExerciseWorkspaceModal(false);
    }

    if (
      pathname.includes('/prepare') &&
      (!selectedWorkspace || !userRoles?.length)
    ) {
      setExerciseWorkspaceModal(true);
    } else if (
      pathname.includes('/dashboard') &&
      (!selectedWorkspace || !userRoles?.length)
    ) {
      toast.error(
        `You don't have access to any Exercise workspace or role in this organization. Please contact admin.`,
        toastConfig
      );
    } else if (pathname.includes('experimental-event-details')) {
      navigate(`${PreparePath}/dashboard/plan/experimental-event-details`);
    } else if (pathname.includes('event')) {
      navigate(`${PreparePath}/dashboard/plan/prepare/events`);
    } else if (pathname.includes('participants')) {
      navigate(`${PreparePath}/dashboard/plan/prepare/participants`);
    } else if (pathname.includes('training-objective')) {
      navigate(`${PreparePath}/dashboard/plan/prepare/training-objective`);
    } else if (pathname.includes('msel-view')) {
      navigate(`${PreparePath}/dashboard/plan/prepare/msel-list`);
    } else if (pathname.includes('msel-all-details')) {
      navigate(`${PreparePath}/dashboard/plan/prepare/msel-list`);
    } else if (pathname.includes('all-details')) {
      navigate(`${PreparePath}/dashboard/plan/prepare/exercise-details`);
    } else if (pathname.includes('assessment')) {
      navigate(`${PreparePath}/dashboard/plan/assessment/overview`);
    } else if (
      pathname.includes('feedback') &&
      !pathname.includes('feedback/edit')
    ) {
      navigate(`${PreparePath}/dashboard/plan/feedback`);
    } else if (pathname.includes('notebook')) {
      navigate(`${PreparePath}/dashboard/notebook/notebooks`);
    } else if (pathname.includes('media/posts')) {
      navigate(`${PreparePath}/dashboard/media/posts`);
    } else if (pathname.includes('media/personas')) {
      navigate(`${PreparePath}/dashboard/media/personas`);
    } else if (pathname.includes('media/sites')) {
      navigate(`${PreparePath}/dashboard/media/sites`);
    }
  }, [selectedWorkspace, userRoles, exercises]);

  return (
    <>
      {!customUIForMedia ? (
        <div className="site">
          {!newUI ? <AppHeader /> : null}
          <div className="site-main">
            <Outlet />
          </div>
        </div>
      ) : (
        <div className="site">
          <Outlet />
        </div>
      )}
      <Loader />
      {exerciseWorkspaceModal ? (
        <SelectExerciseModal
          show={exerciseWorkspaceModal}
          onClose={() => setExerciseWorkspaceModal(false)}
          exercises={exercises}
        />
      ) : null}
    </>
  );
}
