import {
  samplePointLayer,
  sampleDrawLayer,
} from '../constants/sampleLayers';

// ACTIONS
const geolocation_tiles_actions_polygons_source = "geolocation_tiles_actions_polygons_source"
const geolocation_tiles_actions_polygons_layer = "geolocation_tiles_actions_polygons_layer"
const geolocation_tiles_actions_polygons_source_layer = "public.geolocation_tiles_actions_polygons"

const geolocation_tiles_actions_polygons_layer_config = {
  ...sampleDrawLayer,
  id: geolocation_tiles_actions_polygons_layer,
  source: geolocation_tiles_actions_polygons_source,
  'source-layer': geolocation_tiles_actions_polygons_source_layer,
  generateId: true,
  paint: {
    ...sampleDrawLayer.paint,
    'fill-opacity': 0.3,
  },
  metadata: {
    eventlayer: false,
    usermade: undefined,
    model_layer: false,
    model_details: undefined,
    geolocation: true
  },
};

const geolocation_tiles_actions_points_source = "geolocation_tiles_actions_points_source"
const geolocation_tiles_actions_points_layer = "geolocation_tiles_actions_points_layer"
const geolocation_tiles_actions_points_source_layer = "public.geolocation_tiles_actions_points"

const geolocation_tiles_actions_points_layer_config = {
  ...samplePointLayer,
  id: geolocation_tiles_actions_points_layer,
  source: geolocation_tiles_actions_points_source,
  'source-layer': geolocation_tiles_actions_points_source_layer,
  generateId: true,
  paint: {
    ...samplePointLayer.paint,
    'circle-opacity': 0.3,
  },
  metadata: {
    eventlayer: false,
    usermade: undefined,
    model_layer: false,
    model_details: undefined,
    geolocation: true
  },
};
// /ACTIONS


// AORs 
const geolocation_tiles_aors_polygons_source = "geolocation_tiles_aors_polygons_source"
const geolocation_tiles_aors_polygons_layer = "geolocation_tiles_aors_polygons_layer"
const geolocation_tiles_aors_polygons_source_layer = "public.geolocation_tiles_aors_polygons"

const geolocation_tiles_aors_polygons_layer_config = {
  ...sampleDrawLayer,
  id: geolocation_tiles_aors_polygons_layer,
  source: geolocation_tiles_aors_polygons_source,
  'source-layer': geolocation_tiles_aors_polygons_source_layer,
  generateId: true,
  paint: {
    ...sampleDrawLayer.paint,
    'fill-opacity': 0.3,
  },
  metadata: {
    eventlayer: false,
    usermade: undefined,
    model_layer: false,
    model_details: undefined,
    geolocation: true
  },
};

const geolocation_tiles_aors_points_source = "geolocation_tiles_aors_points_source"
const geolocation_tiles_aors_points_layer = "geolocation_tiles_aors_points_layer"
const geolocation_tiles_aors_points_source_layer = "public.geolocation_tiles_aors_points"

const geolocation_tiles_aors_points_layer_config = {
  ...samplePointLayer,
  id: geolocation_tiles_aors_points_layer,
  source: geolocation_tiles_aors_points_source,
  'source-layer': geolocation_tiles_aors_points_source_layer,
  generateId: true,
  paint: {
    ...samplePointLayer.paint,
    'circle-opacity': 0.3,
  },
  metadata: {
    eventlayer: false,
    usermade: undefined,
    model_layer: false,
    model_details: undefined,
    geolocation: true
  },
};
// /AORs


// RESOURCES 
const geolocation_tiles_resources_polygons_source = "geolocation_tiles_resources_polygons_source"
const geolocation_tiles_resources_polygons_layer = "geolocation_tiles_resources_polygons_layer"
const geolocation_tiles_resources_polygons_source_layer = "public.geolocation_tiles_resources_polygons"

const geolocation_tiles_resources_polygons_layer_config = {
  ...sampleDrawLayer,
  id: geolocation_tiles_resources_polygons_layer,
  source: geolocation_tiles_resources_polygons_source,
  'source-layer': geolocation_tiles_resources_polygons_source_layer,
  generateId: true,
  paint: {
    ...sampleDrawLayer.paint,
    'fill-opacity': 0.3,
  },
  metadata: {
    eventlayer: false,
    usermade: undefined,
    model_layer: false,
    model_details: undefined,
    geolocation: true
  },
};

const geolocation_tiles_resources_points_source = "geolocation_tiles_resources_points_source"
const geolocation_tiles_resources_points_layer = "geolocation_tiles_resources_points_layer"
const geolocation_tiles_resources_points_source_layer = "public.geolocation_tiles_resources_points"

const geolocation_tiles_resources_points_layer_config = {
  ...samplePointLayer,
  id: geolocation_tiles_resources_points_layer,
  source: geolocation_tiles_resources_points_source,
  'source-layer': geolocation_tiles_resources_points_source_layer,
  generateId: true,
  paint: {
    ...samplePointLayer.paint,
    'circle-opacity': 0.3,
  },
  metadata: {
    eventlayer: false,
    usermade: undefined,
    model_layer: false,
    model_details: undefined,
    geolocation: true
  },
};
// /RESOURCES






export default function geolocationsEffect(
  map,
  mapHasLoaded,
  apiPrefix,
  reduxCurrentlySelectedGroup,
  showAORsOnMap,
  showActionsOnMap,
  showResourcesOnMap,
  showWorkAssignmentsOnMap,
  showPlanEventsOnMap,
  setLayerClicked
)
{
  const geolocation_tiles_actions_polygons_tiles = `${apiPrefix}/geolocations_tiles/${geolocation_tiles_actions_polygons_source_layer}/{z}/{x}/{y}.pbf`;
  const geolocation_tiles_actions_points_tiles = `${apiPrefix}/geolocations_tiles/${geolocation_tiles_actions_points_source_layer}/{z}/{x}/{y}.pbf`;

  const geolocation_tiles_aors_polygons_tiles = `${apiPrefix}/geolocations_tiles/${geolocation_tiles_aors_polygons_source_layer}/{z}/{x}/{y}.pbf`;
  const geolocation_tiles_aors_points_tiles = `${apiPrefix}/geolocations_tiles/${geolocation_tiles_aors_points_source_layer}/{z}/{x}/{y}.pbf`;
  
  const geolocation_tiles_resources_polygons_tiles = `${apiPrefix}/geolocations_tiles/${geolocation_tiles_resources_polygons_source_layer}/{z}/{x}/{y}.pbf`;
  const geolocation_tiles_resources_points_tiles = `${apiPrefix}/geolocations_tiles/${geolocation_tiles_resources_points_source_layer}/{z}/{x}/{y}.pbf`;
  
  // ACTIONS
  if(!!map.current.getLayer(geolocation_tiles_actions_polygons_layer))
  {
    map.current.removeLayer(geolocation_tiles_actions_polygons_layer)
  }
  if(!!map.current.getSource(geolocation_tiles_actions_polygons_source))
  {
    map.current.removeSource(geolocation_tiles_actions_polygons_source)
  }
  if(!!map.current.getLayer(geolocation_tiles_actions_points_layer))
  {
    map.current.removeLayer(geolocation_tiles_actions_points_layer)
  }
  if(!!map.current.getSource(geolocation_tiles_actions_points_source))
  {
    map.current.removeSource(geolocation_tiles_actions_points_source)
  }
  // /ACTIONS

  // aorS
  if(!!map.current.getLayer(geolocation_tiles_aors_polygons_layer))
  {
    map.current.removeLayer(geolocation_tiles_aors_polygons_layer)
  }
  if(!!map.current.getSource(geolocation_tiles_aors_polygons_source))
  {
    map.current.removeSource(geolocation_tiles_aors_polygons_source)
  }
  if(!!map.current.getLayer(geolocation_tiles_aors_points_layer))
  {
    map.current.removeLayer(geolocation_tiles_aors_points_layer)
  }
  if(!!map.current.getSource(geolocation_tiles_aors_points_source))
  {
    map.current.removeSource(geolocation_tiles_aors_points_source)
  }
  // /aorS

  // RESOURCES
  if(!!map.current.getLayer(geolocation_tiles_resources_polygons_layer))
  {
    map.current.removeLayer(geolocation_tiles_resources_polygons_layer)
  }
  if(!!map.current.getSource(geolocation_tiles_resources_polygons_source))
  {
    map.current.removeSource(geolocation_tiles_resources_polygons_source)
  }
  if(!!map.current.getLayer(geolocation_tiles_resources_points_layer))
  {
    map.current.removeLayer(geolocation_tiles_resources_points_layer)
  }
  if(!!map.current.getSource(geolocation_tiles_resources_points_source))
  {
    map.current.removeSource(geolocation_tiles_resources_points_source)
  }
  // /RESOURCES


  // ACTIONS
  if(!!showActionsOnMap)
  {
    map.current.addSource(geolocation_tiles_actions_polygons_source, {
      'type': 'vector',
      'tiles': [
        geolocation_tiles_actions_polygons_tiles+"?user_group_guid="+reduxCurrentlySelectedGroup.group_guid
      ],
    });
    map.current.addLayer(geolocation_tiles_actions_polygons_layer_config);

    


    
    map.current.addSource(geolocation_tiles_actions_points_source, {
      'type': 'vector',
      'tiles': [
        geolocation_tiles_actions_points_tiles+"?user_group_guid="+reduxCurrentlySelectedGroup.group_guid
      ],
    });
    map.current.addLayer(geolocation_tiles_actions_points_layer_config);       


  }
  // /ACTIONS


  // AORs
  if(!!showAORsOnMap)
  {
    map.current.addSource(geolocation_tiles_aors_polygons_source, {
      'type': 'vector',
      'tiles': [
        geolocation_tiles_aors_polygons_tiles+"?user_group_guid="+reduxCurrentlySelectedGroup.group_guid
      ],
    });
    map.current.addLayer(geolocation_tiles_aors_polygons_layer_config);

    map.current.on('click', geolocation_tiles_aors_polygons_layer_config.id, (e) =>
      setLayerClicked({
        layer: geolocation_tiles_aors_polygons_layer_config,
        e: e,
        features: e.features,
      })
    );
    map.current.on('touchend', geolocation_tiles_aors_polygons_layer_config.id, (e) =>
      setLayerClicked({
        layer: geolocation_tiles_aors_polygons_layer_config,
        e: e,
        features: e.features,
      })
    );
    map.current.on('mouseenter', geolocation_tiles_aors_polygons_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });
    map.current.on('mouseleave', geolocation_tiles_aors_polygons_layer_config.id, () => {
      map.current.getCanvas().style.cursor = 'grab';
    });
    


    map.current.addSource(geolocation_tiles_aors_points_source, {
      'type': 'vector',
      'tiles': [
        geolocation_tiles_aors_points_tiles+"?user_group_guid="+reduxCurrentlySelectedGroup.group_guid
      ],
    });
    map.current.addLayer(geolocation_tiles_aors_points_layer_config);    
  }  
  // AORs   
  

  // RESOURCES
  if(!!showResourcesOnMap)
  {
    map.current.addSource(geolocation_tiles_resources_polygons_source, {
      'type': 'vector',
      'tiles': [
        geolocation_tiles_resources_polygons_tiles+"?user_group_guid="+reduxCurrentlySelectedGroup.group_guid
      ],
    });
    map.current.addLayer(geolocation_tiles_resources_polygons_layer_config);
    
    map.current.addSource(geolocation_tiles_resources_points_source, {
      'type': 'vector',
      'tiles': [
        geolocation_tiles_resources_points_tiles+"?user_group_guid="+reduxCurrentlySelectedGroup.group_guid
      ],
    });
    map.current.addLayer(geolocation_tiles_resources_points_layer_config);    
  }  
  // RESOURCES    
   
}