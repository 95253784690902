import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import {
  alphabeticalNumbering,
  stringToNumber,
  toastConfig,
} from 'assets/data/config';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { useDispatch, useSelector } from 'react-redux';
import TRRCard from './TRRCard';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { AddEditTRRLevelDialog } from './AddEditTRRLevelDialog';
import { toast } from 'react-toastify';
import IconPlaceholder from 'assets/images/icon__exercise-details-empty.svg';
import { getTrrByLevelOneId } from './TrrUtils';
import { TechnicalDisciplineContext } from './TRRList';

const TRRCardList = forwardRef(({ trrLevels, updateTrrLevels }, ref) => {
  const {
    selectedTechnicalDiscipline,
    setSelectedTechnicalDiscipline,
    trrCountWithTechnicalDiscipline,
    setTrrCountWithTechnicalDiscipline,
    getTrrList,
    lastTrrLevel1ItemNo,
    setLastTrrLevel1ItemNo,
  } = useContext(TechnicalDisciplineContext);
  const reduxDispatch = useDispatch();
  const { selectedWorkspace } = useSelector((state) => {
    return state?.prepare?.workspace;
  });
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [toBeDeleteData, setToBeDeleteData] = useState();
  const [toBeModifiedTRRData, setToBeModifiedTRRData] = useState();
  const [showAddEditTRRLevelDialog, setShowAddEditTRRLevelDialog] = useState({
    show: false,
  });

  useImperativeHandle(ref, () => ({
    addTrrLevelOne: () => {
      handleShowAddEditTRRLevelDialog(true, 1);
    },
  }));

  const fetchTRRDataSilently = async (trrParent) => {
    if (!trrParent.active && Number(trrParent.level_2_count) > 0) {
      const updatedTrrLevels = await getTrrByLevelOneId(
        selectedWorkspace,
        trrParent?.id,
        trrLevels,
        selectedTechnicalDiscipline
      );
      const trrParentIndex = trrLevels.findIndex(
        (trr) => trr?.id === trrParent?.id
      );
      const trrParentIndexOnUpdated = updatedTrrLevels.findIndex(
        (trr) => trr?.id === trrParent?.id
      );
      const newTrrLevels = [...trrLevels];
      newTrrLevels[trrParentIndex] = {
        ...trrParent,
        child: updatedTrrLevels[trrParentIndexOnUpdated]?.child || [],
      };
      updateTrrLevels(newTrrLevels);
    }
  };

  const handleShowAddEditTRRLevelDialog = (
    show,
    trrLevel = 1,
    item,
    trrParent,
    trrGrandParent
  ) => {
    let trrItem;
    if (trrLevel === 1) {
      // trrItem = trrCountWithTechnicalDiscipline + 1;
      trrItem = lastTrrLevel1ItemNo + 1;
    }
    if (trrLevel === 2) {
      if (!!item && !!item?.id) {
        setToBeModifiedTRRData(item);
      } else {
        fetchTRRDataSilently(trrParent);
        const trrChildLength = trrParent.level_2_count
          ? Number(trrParent.level_2_count)
          : 0;
        if (trrChildLength > 0) {
          const trrParentNumber =
            trrParent.child[trrParent?.child?.length - 1]?.item_no ||
            trrParent.last_trr_2_item_no;
          if (trrParentNumber) {
            const match = trrParentNumber.match(/([a-zA-Z]+)/);
            trrItem = `${trrParent.item_no}${alphabeticalNumbering(
              stringToNumber(match[0]) + 1
            )}`;
          } else {
            trrItem = `${trrParent.item_no}${alphabeticalNumbering(
              trrChildLength + 1
            )}`;
          }
        } else {
          trrItem = `${trrParent.item_no}a`;
        }
      }
    }
    if (trrLevel === 3) {
      if (!!item && !!item?.id) {
        setToBeModifiedTRRData(item);
      } else {
        const trrParentNumber = trrGrandParent.last_trr_3_item_no;
        if (trrParentNumber) {
          const number = trrParentNumber.match(/\((\d+)\)/);
          trrItem = `${trrGrandParent.item_no}(${Number(number[1]) + 1})`;
        } else {
          trrItem = `${trrGrandParent.item_no}(1)`;
        }
      }
    }
    setToBeModifiedTRRData(item);
    setShowAddEditTRRLevelDialog({
      show,
      trrLevel,
      trrItem,
      trrParent,
      trrGrandParent,
    });
  };

  const handleAddEditTRRData = async (data) => {
    if (data.trrLevel === 1) {
      if (!!selectedTechnicalDiscipline) {
        setSelectedTechnicalDiscipline('');
      }
      const currentIndex = trrLevels.findIndex((trr) => trr?.id === data?.id);
      const updatedTrrLevels = [...trrLevels];
      if (currentIndex !== -1) {
        updatedTrrLevels[currentIndex] = {
          ...data,
          active: updatedTrrLevels[currentIndex].active,
          child: updatedTrrLevels[currentIndex].child,
          last_trr_2_item_no: data.item_no,
          level_2_count: updatedTrrLevels[currentIndex].level_2_count,
        };
      } else {
        updatedTrrLevels.push({ ...data });
        setTrrCountWithTechnicalDiscipline(trrCountWithTechnicalDiscipline + 1);
      }
      updateTrrLevels(updatedTrrLevels);
    } else if (data.trrLevel === 2) {
      const trrLevel1Index = trrLevels.findIndex(
        (trr) => trr?.id === data?.trr_level_1
      );
      const trrLevel2Index = trrLevels[trrLevel1Index]?.child?.findIndex(
        (trr) => trr?.id === data?.id
      );
      const updatedTrrLevels = [...trrLevels];
      if (trrLevel2Index !== -1) {
        updatedTrrLevels[trrLevel1Index].child[trrLevel2Index] = {
          ...data,
          child: updatedTrrLevels[trrLevel1Index].child[trrLevel2Index].child,
          active:
            updatedTrrLevels[trrLevel1Index].child[trrLevel2Index]?.active,
        };
      } else {
        updatedTrrLevels[trrLevel1Index].child.push({ ...data });
        updatedTrrLevels[trrLevel1Index].level_2_count = String(
          updatedTrrLevels[trrLevel1Index].child.length
        );
        updatedTrrLevels[trrLevel1Index].active = true;
        updatedTrrLevels[trrLevel1Index].last_trr_2_item_no = data.item_no;
      }
      updateTrrLevels(updatedTrrLevels);
    } else if (data.trrLevel === 3) {
      if (selectedTechnicalDiscipline && selectedTechnicalDiscipline !== '') {
        getTrrList();
        reduxDispatch(endLoading());
        return;
      }

      const trrLevel1Index = trrLevels.findIndex(
        (trr) => trr?.id === data?.trr_level_1
      );
      const trrLevel2Index = trrLevels[trrLevel1Index]?.child?.findIndex(
        (trr) => trr?.id === data?.trr_level_2
      );
      const trrLevel3Index = trrLevels[trrLevel1Index]?.child[
        trrLevel2Index
      ]?.child?.findIndex((trr) => trr?.id === data?.id);
      const updatedTrrLevels = await getTrrByLevelOneId(
        selectedWorkspace,
        data?.trr_level_1,
        trrLevels,
        selectedTechnicalDiscipline
      );
      const trrLevel1IndexOnUpdated = updatedTrrLevels.findIndex(
        (trr) => trr?.id === data?.trr_level_1
      );
      const trrLevel2IndexOnUpdated = updatedTrrLevels[
        trrLevel1IndexOnUpdated
      ]?.child?.findIndex((trr) => trr?.id === data?.trr_level_2);

      const newTrrLevels = [...trrLevels];
      newTrrLevels[trrLevel1Index] = {
        ...newTrrLevels[trrLevel1Index],
        technical_discipline:
          updatedTrrLevels[trrLevel1IndexOnUpdated]?.technical_discipline,
        technical_discipline_item:
          updatedTrrLevels[trrLevel1IndexOnUpdated]?.technical_discipline,
        risk_character:
          updatedTrrLevels[trrLevel1IndexOnUpdated]?.risk_character,
      };
      newTrrLevels[trrLevel1Index].child[trrLevel2Index] = {
        ...newTrrLevels[trrLevel1Index].child[trrLevel2Index],
        technical_discipline:
          updatedTrrLevels[trrLevel1IndexOnUpdated]?.child[
            trrLevel2IndexOnUpdated
          ]?.technical_discipline,
        technical_discipline_item:
          updatedTrrLevels[trrLevel1IndexOnUpdated]?.child[
            trrLevel2IndexOnUpdated
          ]?.technical_discipline,
        risk_character:
          updatedTrrLevels[trrLevel1IndexOnUpdated]?.child[
            trrLevel2IndexOnUpdated
          ]?.risk_character,
        last_trr_3_item_no: data.item_no,
      };

      if (trrLevel3Index !== -1) {
        newTrrLevels[trrLevel1Index].child[trrLevel2Index].child[
          trrLevel3Index
        ] = {
          ...data,
        };
      } else {
        newTrrLevels[trrLevel1Index].child[trrLevel2Index].child.push({
          ...data,
        });
        newTrrLevels[trrLevel1Index].child[trrLevel2Index].active = true;
      }
      updateTrrLevels(newTrrLevels);
    }
    reduxDispatch(endLoading());
  };
  const onDeleteTrr = (trr) => {
    setShowConfirmModal(true);
    setToBeDeleteData(trr);
  };

  const expandTrr = async (trr_level_1, level, level_two_expand_index) => {
    if (level === 2) {
      const currentTrrIndex = trrLevels.findIndex(
        (trr) => trr?.id === trr_level_1
      );
      const currentTrr = trrLevels[currentTrrIndex];
      if (currentTrr.active) {
        const updatedTrrLevels = [...trrLevels];
        updatedTrrLevels[currentTrrIndex] = { ...currentTrr, active: false };
        updateTrrLevels(updatedTrrLevels);
        return;
      }
      reduxDispatch(startLoading());
      const updatedTrrLevels = await getTrrByLevelOneId(
        selectedWorkspace,
        trr_level_1,
        trrLevels,
        selectedTechnicalDiscipline
      );
      updatedTrrLevels[currentTrrIndex] = {
        ...updatedTrrLevels[currentTrrIndex],
        active: true,
      };
      updateTrrLevels(updatedTrrLevels);
      reduxDispatch(endLoading());
    } else if (level === 3) {
      const currentTrrIndex = trrLevels.findIndex(
        (trr) => trr?.id === trr_level_1
      );
      const currentTrr = trrLevels[currentTrrIndex];
      const currentTrrLevel2 = currentTrr?.child[level_two_expand_index];
      currentTrrLevel2.active = !currentTrrLevel2.active;
      const updatedTrrLevels = [...trrLevels];
      updatedTrrLevels[currentTrrIndex].child[level_two_expand_index] = {
        ...currentTrrLevel2,
      };
      updateTrrLevels(updatedTrrLevels);
    }
  };

  const onDeleteTrrConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.get(API.deleteTRR, {
        workspaceId: selectedWorkspace,
        trr_id: toBeDeleteData?.id,
      });
      getTrrList();
      if (selectedTechnicalDiscipline && selectedTechnicalDiscipline !== '') {
        setShowConfirmModal(false);
        setToBeDeleteData();
        toast.success('TRR Deleted Successfully', toastConfig);
        return;
      }
      if (toBeDeleteData?.trrLevel === 1) {
        const updatedTrrLevels = trrLevels.filter(
          (trr) => trr?.id !== toBeDeleteData?.id
        );
        setTrrCountWithTechnicalDiscipline(trrCountWithTechnicalDiscipline - 1);
        updateTrrLevels(updatedTrrLevels);
      } else if (toBeDeleteData.trrLevel === 2) {
        const updatedTrrLevels = await getTrrByLevelOneId(
          selectedWorkspace,
          toBeDeleteData?.trr_level_1,
          trrLevels,
          selectedTechnicalDiscipline
        );
        const level1Index = trrLevels.findIndex(
          (trr) => trr?.id === toBeDeleteData?.trr_level_1
        );
        const level1IndexOnUpdated = updatedTrrLevels.findIndex(
          (trr) => trr?.id === toBeDeleteData?.trr_level_1
        );
        const newTrrLevels = [...trrLevels];
        newTrrLevels[level1Index] = {
          ...newTrrLevels[level1Index],
          technical_discipline_item:
            updatedTrrLevels[level1IndexOnUpdated]?.technical_discipline_item,
          risk_character:
            updatedTrrLevels[level1IndexOnUpdated]?.risk_character,
          child: newTrrLevels[level1Index].child.filter(
            (trr) => trr?.id !== toBeDeleteData?.id
          ),
          active: updatedTrrLevels[level1IndexOnUpdated]?.child.length > 0,
          level_2_count: updatedTrrLevels[level1IndexOnUpdated]?.level_2_count,
          trr_level_2s: updatedTrrLevels[level1IndexOnUpdated]?.trr_level_2s,
        };
        updateTrrLevels(newTrrLevels);
      } else if (toBeDeleteData.trrLevel === 3) {
        const updatedTrrLevels = await getTrrByLevelOneId(
          selectedWorkspace,
          toBeDeleteData?.trr_level_1,
          trrLevels,
          selectedTechnicalDiscipline
        );
        const level1Index = trrLevels.findIndex(
          (trr) => trr?.id === toBeDeleteData?.trr_level_1
        );
        const level1IndexOnUpdated = updatedTrrLevels.findIndex(
          (trr) => trr?.id === toBeDeleteData?.trr_level_1
        );
        const level2Index = trrLevels[level1Index]?.child?.findIndex(
          (trr) => trr?.id === toBeDeleteData?.trr_level_2
        );
        const level2IndexOnUpdated = updatedTrrLevels[
          level1IndexOnUpdated
        ]?.child?.findIndex((trr) => trr?.id === toBeDeleteData?.trr_level_2);
        const newTrrLevels = [...trrLevels];
        newTrrLevels[level1Index] = {
          ...newTrrLevels[level1Index],
          technical_discipline_item:
            updatedTrrLevels[level1IndexOnUpdated]?.technical_discipline_item,
          risk_character:
            updatedTrrLevels[level1IndexOnUpdated]?.risk_character,
          child: updatedTrrLevels[level1IndexOnUpdated]?.child,
          active: updatedTrrLevels[level1IndexOnUpdated]?.child.length > 0,
        };
        newTrrLevels[level1Index].child[level2Index] = {
          ...newTrrLevels[level1Index].child[level2Index],
          technical_discipline_item:
            updatedTrrLevels[level1IndexOnUpdated]?.child[level2IndexOnUpdated]
              ?.technical_discipline_item,
          risk_character:
            updatedTrrLevels[level1IndexOnUpdated]?.child[level2IndexOnUpdated]
              ?.risk_character,
          child:
            updatedTrrLevels[level1IndexOnUpdated]?.child[level2IndexOnUpdated]
              ?.child,
          active:
            updatedTrrLevels[level1IndexOnUpdated]?.child[level2IndexOnUpdated]
              ?.child.length > 0,
        };
        updateTrrLevels(newTrrLevels);
      }

      setShowConfirmModal(false);
      setToBeDeleteData();
      toast.success('TRR Deleted Successfully', toastConfig);
    } catch (error) {
      toast.error('Failed to delete TRR', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      {!!trrLevels && trrLevels.length > 0 ? (
        trrLevels?.map((trr, idx) => (
          <div
            key={trr?.id}
            className={`trr ${idx !== 0 ? 'mt-4' : ''} ${
              trr?.active ? 'active' : ''
            }`}
          >
            <TRRCard
              trr={trr}
              onDeleteTrr={() => onDeleteTrr(trr)}
              expandTrr={() => expandTrr(trr?.id, 2)}
              onEditTrr={() => handleShowAddEditTRRLevelDialog(true, 1, trr)}
              onAddTrr={() => handleShowAddEditTRRLevelDialog(true, 2, {}, trr)}
            />
            {(trr?.active && trr?.child && !!trr?.child?.length && (
              <>
                <div className="trr__child">
                  <div className="flex-grow-1 d-md-flex align-items-center mt-4">
                    <h4 className="m-0 mb-2 mb-lg-0 weight-700">TRR Level 2</h4>
                    <StylishNewButton
                      type={'button'}
                      className={'button--primary ms-auto'}
                      onClick={() =>
                        handleShowAddEditTRRLevelDialog(true, 2, {}, trr)
                      }
                    >
                      Add TRR Level 2
                    </StylishNewButton>
                  </div>
                  {trr?.child.map((trrLevelII, idx) => (
                    <div
                      key={trrLevelII?.id}
                      className={`trr mt-4 ${
                        trrLevelII?.active ? 'active' : ''
                      }`}
                    >
                      <TRRCard
                        onDeleteTrr={() =>
                          onDeleteTrr({ ...trrLevelII, trr_level_1: trr?.id })
                        }
                        onEditTrr={() =>
                          handleShowAddEditTRRLevelDialog(
                            true,
                            2,
                            trrLevelII,
                            trr
                          )
                        }
                        trr={trrLevelII}
                        expandTrr={() => expandTrr(trr.id, 3, idx)}
                        onAddTrr={() =>
                          handleShowAddEditTRRLevelDialog(
                            true,
                            3,
                            {},
                            trr,
                            trrLevelII
                          )
                        }
                      />
                      {(trrLevelII?.active &&
                        trrLevelII?.child &&
                        !!trrLevelII?.child?.length && (
                          <>
                            <div className="trr__child">
                              <div className="flex-grow-1 d-md-flex align-items-center mt-4">
                                <h4 className="m-0 mb-2 mb-lg-0 weight-700">
                                  TRR Level 3
                                </h4>
                                <StylishNewButton
                                  type={'button'}
                                  className={'button--primary ms-auto'}
                                  onClick={() =>
                                    handleShowAddEditTRRLevelDialog(
                                      true,
                                      3,
                                      {},
                                      trr,
                                      trrLevelII
                                    )
                                  }
                                >
                                  Add TRR Level 3
                                </StylishNewButton>
                              </div>
                              {trrLevelII?.child.map((trrLevelIII) => {
                                return (
                                  <div
                                    key={trrLevelIII?.id}
                                    className={`trr level3 mt-4 ${
                                      trrLevelIII?.active ? 'active' : ''
                                    }`}
                                  >
                                    <TRRCard
                                      trr={trrLevelIII}
                                      onDeleteTrr={() =>
                                        onDeleteTrr({
                                          ...trrLevelIII,
                                          trr_level_1: trr?.id,
                                          trr_level_2: trrLevelII?.id,
                                        })
                                      }
                                      onEditTrr={() =>
                                        handleShowAddEditTRRLevelDialog(
                                          true,
                                          3,
                                          trrLevelIII,
                                          trr,
                                          trrLevelII
                                        )
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )) ||
                        null}
                    </div>
                  ))}
                </div>
              </>
            )) ||
              null}
          </div>
        ))
      ) : (
        <div className="bg-gray-800 text-center p-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconPlaceholder} alt="" />
            </div>
            <p className="weight-600 mb-0">
              A fresh start. Create the first entry.
            </p>
          </div>
        </div>
      )}
      {showAddEditTRRLevelDialog?.show && (
        <AddEditTRRLevelDialog
          show={showAddEditTRRLevelDialog?.show}
          onClose={(data) => {
            handleShowAddEditTRRLevelDialog(false);
            setToBeModifiedTRRData();
            data && handleAddEditTRRData(data);
          }}
          data={toBeModifiedTRRData}
          trrLevel={showAddEditTRRLevelDialog?.trrLevel}
          trrItem={showAddEditTRRLevelDialog?.trrItem}
          trrParent={showAddEditTRRLevelDialog?.trrParent}
          trrGrandParent={showAddEditTRRLevelDialog?.trrGrandParent}
          setLastTrrLevel1ItemNo={setLastTrrLevel1ItemNo}
        />
      )}
      {showConfirmModal && (
        <StylishConfirmDialog
          show={showConfirmModal}
          dialogTitle={'Delete TRR?'}
          dialogContent={`Are you sure you want to delete TRR?${
            toBeDeleteData.trrLevel !== 3
              ? ' By deleting this TRR all of its children will be deleted.'
              : ''
          }`}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={onDeleteTrrConfirm}
        />
      )}
    </>
  );
});

export default TRRCardList;
