import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import * as turf from '@turf/turf';

import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog.js';
import NewActionDialog from 'components/FormBuilder/NewActionDialog';
import CoreComponentCreationDialog from 'components/AI2FP/CoreComponentCreationDialog';
import NewResourcesDialog from 'components/IncidentConfig/NewResourcesDialog';
import AddUpdateWorkAssignment from 'components/FormBuilder/WorkAssignment/AddUpdateWorkAssignMent';

import geolocationsEffect from '../mapEffects/geolocationsEffect.js';

import IconClose from '../../../assets/images/icon__times.svg';
import IconArrowRight from '../../../assets/images/icon__arrow--right.svg';
import IconArrowLeft from '../../../assets/images/icon__arrow--left.svg';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

import { endLoading, startLoading } from 'reducers/loading/loading.action.js';
import {
  deleteAction,
  getAllActionByIncident,
} from 'actions/IAPForm201Actions.js';
import {
  deleteCoreComponent,
  fetchCoreComponents,
} from 'actions/ai2fpActions.js';
import {
  deleteResourceToIncident,
  getAllObjectiveToIncident,
  getAllResourcesToGroup,
  getAllResourcesToIncident,
} from 'actions/incidentActions.js';
import { getAllWorkAssignments } from 'actions/IAPForm204Actions.js';

export const flyToGeolocation = (map, item) => {
  if (
    !!item.geolocation &&
    item.geolocation.geojson &&
    item.geolocation.geojson.data &&
    item.geolocation.geojson.data.features &&
    item.geolocation.geojson.data.features.length
  ) {
    var box = turf.bbox(item.geolocation.geojson.data.features[0].geometry);
    map.current.fitBounds(box, { padding: 50, maxZoom:15 });
  }
};

const MapGeolocations = (props) => {
  const {
    map,
    mapHasLoaded,
    apiPrefix,
    selectedGeolocation,
    setLayerClicked,
    onClose,
  } = props;

  const reduxDispatch = useDispatch();

  // Selector variables
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const groupResources = useSelector((state) => state.app.groupResources);
  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });
  const reduxActions = useSelector((state) => state.app.actions);
  const reduxCoreComponents = useSelector((state) => {
    return state.app.coreComponents;
  });
  const reduxAllWorkAssignments = useSelector(
    (state) => state.app.allWorkAssignments
  );
  const incidentResources = useSelector((state) => state.app.incidentResources);
  const incidentObjectives = useSelector(
    (state) => state.app.incidentObjectives || []
  );
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  // State Declarations
  const [showActionsOnMap, setShowActionsOnMap] = useState(true);
  const [showAORsOnMap, setShowAORsOnMap] = useState(true);
  const [showResourcesOnMap, setShowResourcesOnMap] = useState(true);
  const [showWorkAssignmentsOnMap, setShowWorkAssignmentsOnMap] = useState(
    true
  );
  const [showPlanEventsOnMap, setShowPlanEventsOnMap] = useState(true);

  const [showSidebarSecondary, setShowSidebarSecondary] = useState(false);
  const [sidebarSecondaryActiveItem, setSidebarSecondaryActiveItem] = useState(
    null
  );

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteGeolocationType, setDeleteGeolocationType] = useState(null);
  const [deleteGeolocationData, setDeleteGeolocationData] = useState(null);

  const [actionSelected, setActionSelected] = useState();
  const [actionCreationDialogActive, setActionCreationDialogActive] = useState(
    false
  );
  const [AORSelected, setAORSelected] = useState();
  const [aorCreationDialogActive, setAORCreationDialogActive] = useState(false);
  const [resourceSelected, setResourceSelected] = useState();
  const [
    resourceCreationDialogActive,
    setResourceCreationDialogActive,
  ] = useState(false);
  const [workAssignmentSelected, setWorkAssignmentSelected] = useState();
  const [
    workAssignmentCreationDialogActive,
    setWorkAssignmentCreationDialogActive,
  ] = useState(false);

  const [reRender, setReRender] = useState(false);

  // functions
  const handleClickClose = () => {
    onClose();
  };

  const sidebarSecondaryHandler = (show, key) => {
    setShowSidebarSecondary(show);
    setSidebarSecondaryActiveItem(key);
  };

  const actionClicked = (type, action) => {
    setActionSelected(action);
    if (type === 'edit') setActionCreationDialogActive(true);
    if (type === 'delete') {
      setDeleteGeolocationType('Actions');
      setDeleteGeolocationData(action);
      setShowConfirmDialog(true);
    }
  };

  const workAssignmentClicked = (type, wa) => {
    setWorkAssignmentSelected(wa);
    if (type === 'edit') setWorkAssignmentCreationDialogActive(true);
    if (type === 'delete') {
      setDeleteGeolocationType('Work Assignment');
      setDeleteGeolocationData(wa);
      setShowConfirmDialog(true);
    }
  };

  const resourceClicked = (type, resource) => {
    setResourceSelected(resource);
    if (type === 'edit') setResourceCreationDialogActive(true);
    if (type === 'delete') {
      setDeleteGeolocationType('Resource');
      setDeleteGeolocationData(resource);
      setShowConfirmDialog(true);
    }
  };

  const AORClicked = (type, aor) => {
    setAORSelected(aor);
    if (type === 'edit') setAORCreationDialogActive(true);
    if (type === 'delete') {
      setDeleteGeolocationType('AORs');
      setDeleteGeolocationData(aor);
      setShowConfirmDialog(true);
    }
  };

  const deleteActionClicked = () => {
    reduxDispatch(startLoading());
    reduxDispatch(
      deleteAction(deleteGeolocationData.action_id, deleteGeolocationData.type)
    );
    setShowConfirmDialog(false);
    setDeleteGeolocationType(null);
    setDeleteGeolocationData(null);
  };

  const deleteAORClicked = async () => {
    reduxDispatch(startLoading());
    await reduxDispatch(deleteCoreComponent(deleteGeolocationData));
    await map.current.removeLayer('geolocation_tiles_aors_polygons_layer');

    setReRender(!reRender);
    setShowConfirmDialog(false);
    setDeleteGeolocationType(null);
    setDeleteGeolocationData(null);
  };

  const deleteResourceClicked = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteResourceToIncident(deleteGeolocationData.id));
    setShowConfirmDialog(false);
    setDeleteGeolocationType(null);
    setDeleteGeolocationData(null);
  };

  const deleteWorkAssignmentClicked = () => {
    // TODO
    // reduxDispatch(startLoading());
    // reduxDispatch(deleteResourceToIncident(deleteGeolocationData.id));
    setShowConfirmDialog(false);
    setDeleteGeolocationType(null);
    setDeleteGeolocationData(null);
  };

  // useEffect
  useEffect(() => {
    setShowActionsOnMap(false);
    setShowAORsOnMap(true);
    setShowResourcesOnMap(false);
    setShowWorkAssignmentsOnMap(false);
    setShowPlanEventsOnMap(false);
  }, []);

  useEffect(() => {
    if (selectedGeolocation?.type === 'Action') {
      setActionSelected(selectedGeolocation?.geolocation);
    } else if (selectedGeolocation?.type === 'AOR') {
      setAORSelected(selectedGeolocation?.geolocation);
    } else if (selectedGeolocation?.type === 'Resource') {
      setResourceSelected(selectedGeolocation?.geolocation);
    } else if (selectedGeolocation?.type === 'Work Assignment') {
      setWorkAssignmentSelected(selectedGeolocation?.geolocation);
    }
  }, [selectedGeolocation]);

  useEffect(() => {
    if (
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid &&
      !!map &&
      !!reduxCurrentIncident &&
      !!mapHasLoaded
    ) {
      if (!reduxCoreComponents) {
        reduxDispatch(
          fetchCoreComponents(reduxCurrentlySelectedGroup.group_guid)
        );
      }

      if (!groupResources || !groupResources.length) {
        reduxDispatch(getAllResourcesToGroup());
      }

      if (!incidentResources || !incidentResources.length) {
        reduxDispatch(getAllResourcesToIncident());
      }

      if (!reduxAllWorkAssignments || !reduxAllWorkAssignments.length) {
        reduxDispatch(getAllWorkAssignments());
      }

      if (!incidentObjectives || !incidentObjectives.length) {
        reduxDispatch(getAllObjectiveToIncident());
      }
    }
  }, [reduxCurrentlySelectedGroup, map, mapHasLoaded]);

  useEffect(() => {
    if (!!reduxCurrentIncident && (!reduxActions || !reduxActions.length)) {
      reduxDispatch(getAllActionByIncident());
    }
  }, [reduxCurrentIncident]);

  useEffect(() => {
    if (!!mapHasLoaded && !!reduxCurrentlySelectedGroup && !!groupResources) {
      geolocationsEffect(
        map,
        mapHasLoaded,
        apiPrefix,
        reduxCurrentlySelectedGroup,
        showAORsOnMap,
        showActionsOnMap,
        showResourcesOnMap,
        showWorkAssignmentsOnMap,
        showPlanEventsOnMap,
        setLayerClicked
      );
    }
  }, [
    map,
    mapHasLoaded,
    groupResources,
    reduxCurrentlySelectedGroup,
    showActionsOnMap,
    showAORsOnMap,
    showResourcesOnMap,
    showWorkAssignmentsOnMap,
    showPlanEventsOnMap,
    reRender,
  ]);

  useEffect(() => {
    reduxDispatch(endLoading());
  }, [reduxActions, reduxCoreComponents, groupResources]);

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        {showSidebarSecondary && (
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={() => {
              setShowSidebarSecondary(false);
              setSidebarSecondaryActiveItem(null);
            }}
          >
            <img src={IconArrowLeft} alt="" />
          </StylishNewButton>
        )}
        <h4 className="m-0">Geolocations</h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => handleClickClose()}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        {!showSidebarSecondary ? (
          <>
            {!!reduxFeatureFlags.includes('MAP_GEOLOCATION') && (
              <div className="d-flex align-items-center bg-gray-900--light-5 p-3 pe-0 rounded mb-3">
                <span className="text-truncate me-3">Actions</span>
                <StylishSwitcher
                  className={'ms-auto'}
                  checked={showActionsOnMap}
                  onChange={() => setShowActionsOnMap(!showActionsOnMap)}
                />
                <span
                  className="px-3 cursor-pointer"
                  onClick={() =>
                    sidebarSecondaryHandler(true, 'Geolocations Actions')
                  }
                >
                  <img src={IconArrowRight} alt="" className="img-h-16" />
                </span>
              </div>
            )}

            <div className="d-flex align-items-center bg-gray-900--light-5 p-3 pe-0 rounded mb-3">
              <span className="text-truncate me-3">AORs</span>
              <StylishSwitcher
                className={'ms-auto'}
                checked={showAORsOnMap}
                onChange={() => setShowAORsOnMap(!showAORsOnMap)}
              />
              <span
                className="px-3 cursor-pointer"
                onClick={() =>
                  sidebarSecondaryHandler(true, 'Geolocations AORs')
                }
              >
                <img src={IconArrowRight} alt="" className="img-h-16" />
              </span>
            </div>

            {!!reduxFeatureFlags.includes('MAP_GEOLOCATION') && (
              <div className="d-flex align-items-center bg-gray-900--light-5 p-3 pe-0 rounded mb-3">
                <span className="text-truncate me-3">Resources</span>
                <StylishSwitcher
                  className={'ms-auto'}
                  checked={showResourcesOnMap}
                  onChange={() => setShowResourcesOnMap(!showResourcesOnMap)}
                />
                <span
                  className="px-3 cursor-pointer"
                  onClick={() =>
                    sidebarSecondaryHandler(true, 'Geolocations Resources')
                  }
                >
                  <img src={IconArrowRight} alt="" className="img-h-16" />
                </span>
              </div>
            )}

            {!!reduxFeatureFlags.includes('MAP_GEOLOCATION') && (
              <div className="d-flex align-items-center bg-gray-900--light-5 p-3 pe-0 rounded mb-3">
                <span className="text-truncate me-3 d-flex align-items-center">
                  Work Assignments
                  <span className="text-uppercase bg-danger weight-600 txt--xsml p-1 rounded-5 ms-2">
                    Blocked
                  </span>
                </span>
                <StylishSwitcher
                  className={'ms-auto'}
                  checked={showWorkAssignmentsOnMap}
                  onChange={() =>
                    setShowWorkAssignmentsOnMap(!showWorkAssignmentsOnMap)
                  }
                />
                <span
                  className="px-3 cursor-pointer"
                  onClick={() =>
                    sidebarSecondaryHandler(
                      true,
                      'Geolocations Work Assignments'
                    )
                  }
                >
                  <img src={IconArrowRight} alt="" className="img-h-16" />
                </span>
              </div>
            )}

            {!!reduxFeatureFlags.includes('MAP_GEOLOCATION') && (
              <div className="d-flex align-items-center bg-gray-900--light-5 p-3 pe-0 rounded">
                <span className="text-truncate me-3 d-flex align-items-center">
                  Plan Events
                  <span className="text-uppercase bg-warning weight-600 txt--xsml p-1 rounded-5 ms-2">
                    Todo
                  </span>
                </span>
                <StylishSwitcher
                  className={'ms-auto'}
                  checked={showPlanEventsOnMap}
                  onChange={() => setShowPlanEventsOnMap(!showPlanEventsOnMap)}
                />
                <span
                  className="px-3 cursor-pointer"
                  onClick={() =>
                    sidebarSecondaryHandler(true, 'Geolocations Plan Events')
                  }
                >
                  <img src={IconArrowRight} alt="" className="img-h-16" />
                </span>
              </div>
            )}
          </>
        ) : (
          <>
            {sidebarSecondaryActiveItem === 'Geolocations Actions' && (
              <>
                <div className="d-flex align-items-center">
                  <h5 className="me-3 mb-0 text-truncate">Actions</h5>
                  <StylishNewButton
                    className={'button--primary button--sml ms-auto'}
                    onClick={() => {
                      setActionSelected();
                      setActionCreationDialogActive(true);
                    }}
                  >
                    Create
                  </StylishNewButton>
                </div>
                <hr className="thin w-10 mt-3 mb-4" />
                {!!reduxActions &&
                  reduxActions.map((a, idx) => {
                    return (
                      <>
                        {idx !== 0 ? (
                          <hr key={idx} className="dashed w-10 thin m-0" />
                        ) : null}
                        <div
                          key={idx}
                          className={`d-flex align-items-center py-3 ${
                            idx === 0 ? 'pt-0' : ''
                          } ${idx === reduxActions.length - 1 ? 'pb-0' : ''}`}
                        >
                          <span className="text-truncate">{a.description}</span>
                          <Dropdown className="flex-shrink-0 ms-auto">
                            <Dropdown.Toggle className="button--icon">
                              <SharedIcon iconName="more_vert" bold />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                              <Dropdown.Item
                                href="#"
                                onClick={() => actionClicked('edit', a)}
                              >
                                <SharedIcon iconName="stylus" />
                                Edit Action
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => actionClicked('delete', a)}
                              >
                                <SharedIcon iconName="delete" />
                                Delete Action
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => flyToGeolocation(map, a)}
                              >
                                <SharedIcon iconName="location_on" />
                                View Location
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </>
                    );
                  })}
              </>
            )}
            {sidebarSecondaryActiveItem === 'Geolocations AORs' && (
              <>
                <div className="d-flex align-items-center">
                  <h5 className="me-3 mb-0 text-truncate">AORs</h5>
                  <StylishNewButton
                    className={'button--primary button--sml ms-auto'}
                    onClick={() => {
                      setAORSelected();
                      setAORCreationDialogActive(true);
                    }}
                  >
                    Create
                  </StylishNewButton>
                </div>
                <hr className="thin w-10 mt-3 mb-4" />
                {!!reduxCoreComponents &&
                  reduxCoreComponents.CCs &&
                  reduxCoreComponents.CCs['Areas of Responsibility'] &&
                  reduxCoreComponents.CCs['Areas of Responsibility'].map(
                    (aor, idx) => {
                      return (
                        <>
                          {idx !== 0 ? (
                            <hr key={idx} className="dashed w-10 thin m-0" />
                          ) : null}
                          <div
                            key={idx}
                            className={`d-flex align-items-center py-3 ${
                              idx === 0 ? 'pt-0' : ''
                            } ${
                              idx ===
                              reduxCoreComponents.CCs['Areas of Responsibility']
                                .length -
                                1
                                ? 'pb-0'
                                : ''
                            }`}
                          >
                            <span className="text-truncate">{aor.name}</span>
                            <Dropdown className="flex-shrink-0 ms-auto">
                              <Dropdown.Toggle className="button--icon">
                                <SharedIcon iconName="more_vert" bold />
                              </Dropdown.Toggle>
                              <Dropdown.Menu align="end">
                                <Dropdown.Item
                                  href="#"
                                  onClick={() => AORClicked('edit', aor)}
                                >
                                  <SharedIcon iconName="stylus" />
                                  Edit AORs
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#"
                                  onClick={() => AORClicked('delete', aor)}
                                >
                                  <SharedIcon iconName="delete" />
                                  Delete AORs
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#"
                                  onClick={() => flyToGeolocation(map, aor)}
                                >
                                  <SharedIcon iconName="location_on" />
                                  View Location
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </>
                      );
                    }
                  )}
              </>
            )}
            {sidebarSecondaryActiveItem === 'Geolocations Resources' && (
              <>
                <div className="d-flex align-items-center">
                  <h5 className="me-3 mb-0 text-truncate">Resources</h5>
                  <StylishNewButton
                    className={'button--primary button--sml ms-auto'}
                    onClick={() => {
                      setResourceSelected();
                      setResourceCreationDialogActive(true);
                    }}
                  >
                    Create
                  </StylishNewButton>
                </div>
                <hr className="thin w-10 mt-3 mb-4" />
                {!!groupResources &&
                  groupResources.map((gr, idx) => {
                    return (
                      <>
                        {idx !== 0 ? (
                          <hr key={idx} className="dashed w-10 thin m-0" />
                        ) : null}
                        <div
                          key={idx}
                          className={`d-flex align-items-center py-3 ${
                            idx === 0 ? 'pt-0' : ''
                          } ${idx === groupResources.length - 1 ? 'pb-0' : ''}`}
                        >
                          <span className="text-truncate">{gr.name}</span>
                          <Dropdown className="flex-shrink-0 ms-auto">
                            <Dropdown.Toggle className="button--icon">
                              <SharedIcon iconName="more_vert" bold />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                              <Dropdown.Item
                                href="#"
                                onClick={() => resourceClicked('edit', gr)}
                              >
                                <SharedIcon iconName="stylus" />
                                Edit Resource
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => resourceClicked('delete', gr)}
                              >
                                <SharedIcon iconName="delete" />
                                Delete Resource
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => flyToGeolocation(map, gr)}
                              >
                                <SharedIcon iconName="location_on" />
                                View Location
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </>
                    );
                  })}
              </>
            )}
            {sidebarSecondaryActiveItem === 'Geolocations Work Assignments' && (
              <>
                <div className="d-flex align-items-center">
                  <h5 className="me-3 mb-0 text-truncate">Work Assignments</h5>
                  <StylishNewButton
                    className={'button--primary button--sml ms-auto'}
                    onClick={() => {
                      setWorkAssignmentSelected();
                      setWorkAssignmentCreationDialogActive(true);
                    }}
                  >
                    Create
                  </StylishNewButton>
                </div>
                <hr className="thin w-10 mt-3 mb-4" />
                {!!reduxAllWorkAssignments &&
                  reduxAllWorkAssignments.map((wa, idx) => (
                    <>
                      {idx !== 0 ? (
                        <hr key={idx} className="dashed w-10 thin m-0" />
                      ) : null}
                      <div
                        key={idx}
                        className={`d-flex align-items-center py-3 ${
                          idx === 0 ? 'pt-0' : ''
                        } ${
                          idx === reduxAllWorkAssignments.length - 1
                            ? 'pb-0'
                            : ''
                        }`}
                      >
                        <span className="text-truncate">{wa.description}</span>
                        <Dropdown className="flex-shrink-0 ms-auto">
                          <Dropdown.Toggle className="button--icon">
                            <SharedIcon iconName="more_vert" bold />
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Dropdown.Item
                              href="#"
                              onClick={() => workAssignmentClicked('edit', wa)}
                            >
                              <SharedIcon iconName="stylus" />
                              Edit Resource
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              onClick={() =>
                                workAssignmentClicked('delete', wa)
                              }
                            >
                              <SharedIcon iconName="delete" />
                              Delete Resource
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              onClick={() => flyToGeolocation(map, wa)}
                            >
                              <SharedIcon iconName="location_on" />
                              View Location
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </>
                  ))}
              </>
            )}
            {sidebarSecondaryActiveItem === 'Geolocations Plan Events' && (
              <>
                <div className="d-flex align-items-center">
                  <h5 className="me-3 mb-0 text-truncate">Plan Events</h5>
                  <StylishNewButton
                    className={'button--primary button--sml ms-auto'}
                    disabled
                  >
                    Create
                  </StylishNewButton>
                </div>
                <hr className="dashed thin w-10 mt-3 mb-4" />
                <p>Plan Events (Prepare Only) Coming Soon!</p>
              </>
            )}
          </>
        )}
      </div>

      {!!actionCreationDialogActive && (
        <NewActionDialog
          show={actionCreationDialogActive}
          onClose={() => setActionCreationDialogActive(false)}
          incidentResources={incidentResources}
          incidentObjectives={incidentObjectives}
          updateActionData={actionSelected}
          actionModalType={(!!actionSelected && 'Edit') || 'New'}
          setActionModalType={() => {}}
          setUpdateActionData={setActionSelected}
        />
      )}
      {!!aorCreationDialogActive && (
        <CoreComponentCreationDialog
          show={aorCreationDialogActive}
          onClose={() => {
            setAORCreationDialogActive(false);
          }}
          ccType="Areas of Responsibility"
          existingCC={AORSelected}
        />
      )}
      {!!resourceCreationDialogActive && (
        <NewResourcesDialog
          show={resourceCreationDialogActive}
          modalType={(!!resourceSelected && 'Edit') || 'New'}
          setModalType={() => {}}
          editResourceData={resourceSelected}
          setEditResourceData={setResourceSelected}
          onClose={() => setResourceCreationDialogActive(false)}
        />
      )}
      {!!workAssignmentCreationDialogActive && (
        <AddUpdateWorkAssignment
          incidentObjectives={incidentObjectives}
          incidentResources={incidentResources}
          workAssignmentModalType={
            (!!workAssignmentSelected && 'Edit') || 'New'
          }
          updateActionData={workAssignmentSelected}
          show={workAssignmentCreationDialogActive}
          onClose={() => setWorkAssignmentCreationDialogActive(false)}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={`Delete ${deleteGeolocationType}`}
          dialogContent={
            'Are you sure you want to delete this?  This action cannot be reversed.'
          }
          onClose={() => {
            setShowConfirmDialog(false);
            setDeleteGeolocationType(null);
            setDeleteGeolocationData(null);
          }}
          onConfirm={
            deleteGeolocationType === 'Actions'
              ? deleteActionClicked
              : deleteGeolocationType === 'AORs'
              ? deleteAORClicked
              : deleteGeolocationType === 'Resource'
              ? deleteResourceClicked
              : deleteGeolocationType === 'Work Assignment'
              ? deleteWorkAssignmentClicked
              : null
          }
        />
      )}
    </div>
  );
};

export default MapGeolocations;
