import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSTacticDialog from 'components/ICS/NewICSTacticDialog';

import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

import ICSIncidentTactic from './ICSIncidentTactic';

import { updateIcsTactic } from 'slices/ics_tacticsSlice';

import {
  createIcsIncidentTactic,
  updateIcsIncidentTactic,
} from 'slices/ics_incidentTacticsSlice';

export default function ICSTacticsDialog({
  show,
  onClose,
  ics_tactics,
  ics_incident_tactics,
  incident_id = null,
  ics_incident_strategies,
}) {
  const reduxDispatch = useDispatch();

  const [isNewTacticDialogOpen, setIsNewTacticDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('New');
  const [editTacticData, setEditTacticData] = useState();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [dialogTactics, setDialogTactics] = useState([...ics_incident_tactics]);

  useEffect(() => {
    setDialogTactics(ics_incident_tactics);
  }, [ics_incident_tactics]);

  useEffect(() => {
    // Use to rerender
  }, [ics_tactics]);

  const allColumns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'user_name',
      text: 'Last Modified By',
      sort: true,
      attrs: { title: 'Last Modified By' },
    },
    {
      dataField: 'archived',
      text: 'Archived',
      sort: true,
      attrs: { title: 'Archived' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <ICSIncidentTactic
            row={row}
            ics_tactics={ics_tactics}
            ics_incident_tactics={ics_incident_tactics}
            ics_incident_strategies={ics_incident_strategies}
          />
          {(!incident_id ||
            !!ics_incident_tactics.find((it) => it.tactic_id === row.id)) && (
            <div className="button-group">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onArchive(row)}
              >
                {row.archived ? `Unarchive` : `Archive`}
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: ics_tactics
      .filter((t) => {
        const incTactic = ics_incident_tactics.find(
          (it) => it.tactic_id === t.id && !it.archived
        );
        return !!incTactic;
      })
      .map((t) => t.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex) => {
      const existingIncidentTactic = ics_incident_tactics.find(
        (it) => it.tactic_id === row.id
      );
      if (existingIncidentTactic) {
        reduxDispatch(
          updateIcsIncidentTactic(incident_id, existingIncidentTactic.id, {
            ...existingIncidentTactic,
            archived: !existingIncidentTactic.archived,
          })
        );
      } else {
        // Create a new incident tactic
        const incidentTacticData = {
          tactic_id: row.id,
          name: row.name,
          description: row.description,
          user_guid: row.user_guid,
          incident_id: incident_id,
          group_guid: reduxCurrentlySelectedGroup.group_guid,
          archived: false,
        };
        reduxDispatch(createIcsIncidentTactic(incident_id, incidentTacticData));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogTactics(rows) : setDialogTactics([]);
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewTacticDialogOpen(true);
    setEditTacticData({
      ...row
    });
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      incident_id,
      archived: !archiveConfirmationData.archived,
    };

    if (!!incident_id) {
      reduxDispatch(
        updateIcsIncidentTactic(
          incident_id,
          archiveConfirmationData.id,
          payload
        )
      );
    } else {
      reduxDispatch(updateIcsTactic(archiveConfirmationData.id, payload));
    }

    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewTactic = () => {
    setModalType('New');
    setIsNewTacticDialogOpen(true);
  };

  const tableRows = ics_tactics.map((t) => {
    const incTactic = ics_incident_tactics.find((it) => it.tactic_id === t.id);
    if (incTactic) {
      return {
        ...incTactic,
        id: t.id,
      };
    } else {
      return t;
    }
  });

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Tactics</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentlySelectedGroup?.group_name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewTactic}
        >
          New Tactic
        </StylishNewButton>
        <div>
          <label>
            {!!incident_id && (
              <>
                Select to add to incident. De-select to archive. Re-select to
                un-archive.
              </>
            )}
          </label>
        </div>
        <StylishNewTable
          keyField={'id'}
          rows={tableRows}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={(!!incident_id && selectRow) || undefined}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewTacticDialogOpen && (
        <NewICSTacticDialog
          show={isNewTacticDialogOpen}
          setEditTacticData={setEditTacticData}
          setModalType={setModalType}
          editTacticData={editTacticData}
          modalType={modalType}
          onClose={() => setIsNewTacticDialogOpen(false)}
          incident_id={incident_id}
          ics_incident_strategies={ics_incident_strategies}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Tactic?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
