import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import {
  fetchIcsResources
} from 'slices/ics_resourcesSlice'

import {
    getUserGuid,
    getGroupGuid
} from 'slices/sliceHelpers'

// Slice
const ics_incident_resourcesSlice = createSlice({
    name: 'ics_incident_resources',
    initialState: {
        ics_incident_resources: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        fetchIcsIncidentResourcesSuccess: (state, action) => {
            state.ics_incident_resources = action.payload;
            state.status = 'succeeded';
        },
        createIcsIncidentResourceSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        updateIcsIncidentResourceSuccess: (state, action) => {
            state.status = 'succeeded';
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    fetchIcsIncidentResourcesSuccess,
    createIcsIncidentResourceSuccess,
    updateIcsIncidentResourceSuccess,
    setError
} = ics_incident_resourcesSlice.actions;

export default ics_incident_resourcesSlice.reducer;

export const createIcsIncidentResource = (incidentId, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_incident_resources}/${incidentId}`, {...data, user_guid});
        dispatch(fetchIcsResources(group_guid));
        dispatch(fetchIcsIncidentResources(incidentId));
        dispatch(createIcsIncidentResourceSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsIncidentResources = (incidentId) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_incident_resources}/${incidentId}`,{
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsIncidentResourcesSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsIncidentResource = (incidentId, id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_incident_resources}/${incidentId}/${id}`, {...data, user_guid});
        dispatch(fetchIcsResources(group_guid));
        dispatch(fetchIcsIncidentResources(incidentId));
        dispatch(updateIcsIncidentResourceSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
