import React from 'react';
import classnames from 'classnames';

export default function StylishNewInput({ className, ...props }) {
  return (
    <input
      className={classnames('form-control', className)}
      {...props}
    />
  );
}
