import React, { useState, useEffect, useRef } from 'react';

export default function GeolocationLayerPopup({
  layer,
  e,
  features,
  map,
  actions,
  AORs,
  resources,
  setSelectedGeolocation,
  setSidebarSelection,
  sidebarItemExpandHandler,
}) {
  function geolocationSelected(feature) {
    //const event = events.find((ev) => ev.feature_id === feature.properties.id);

    const ifSelectedAction = actions.find(
      (a) => a.action_id === feature.properties.id
    );
    const ifSelectedAOR = AORs.find((a) => a.id === feature.properties.id);
    const ifSelectedResource = resources.find(
      (r) => r.id === feature.properties.id
    );

    if (ifSelectedAction) {
      setSelectedGeolocation(ifSelectedAction, 'Action');
      setSidebarSelection(true);
      sidebarItemExpandHandler();
    }

    if (ifSelectedAOR) {
      setSelectedGeolocation(ifSelectedAOR, 'AOR');
      setSidebarSelection(true);
      sidebarItemExpandHandler();
    }

    if (ifSelectedResource) {
      setSelectedGeolocation(ifSelectedResource, 'Resource');
      setSidebarSelection(true);
      sidebarItemExpandHandler();
    }
  }

  function isEqual(item1, item2) {
    return item1.properties.id === item2.properties.id;
  }

  const feature = features.filter(
    (item, index, self) => index === self.findIndex((i) => isEqual(i, item))
  );

  return (
    <>
      <h6 className="my-1">Geolocations</h6>
      <hr className="dashed thin my-1" />
      {!!feature && feature.length ? (
        <>
          <ul className="list">
            {feature.map((f) => {
              return (
                <li
                  className="cursor-default"
                  key={'GeolocationLayerPopup-Feature-' + f.properties.id}
                  onClick={() => geolocationSelected(f)}
                >
                  {f.properties.name}
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <p className="m-0 weight-500">No Geolocations.</p>
      )}
      <hr className="thin my-1" />
    </>
  );
}
