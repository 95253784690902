// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { updateCurrentlySelectedGroup } from 'actions/diceActions';
import {
  updateCurrentIncident,
  updateCurrentDashboard,
} from 'actions/diceActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import Dropdown from 'react-bootstrap/Dropdown';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import moment from 'moment';
import IconNavigation from 'assets/images/icon__navigation.svg';
import PratusLogo from 'assets/images/pratus__logo.svg';
import PratusLogoIcon from 'assets/images/pratus__logoIcon.svg';
import IconHelp from 'assets/images/icon__help.svg';
import IconUser from 'assets/images/icon__user.svg';
import IconActivity from 'assets/images/icon__activity.svg';
import { AppList } from '../../Dashboard/DashboardConstant';
import LogoOrganizationConfiguration from 'assets/images/logo__organization-configuration.svg';
import LogoIncidentConfiguration from 'assets/images/logo__incident-configuration.svg';
import LogoTaskManagement from 'assets/images/logo__task_management.svg';
import LogoNotification from 'assets/images/logo__notification.svg';
import LogoSituationIntelligence from 'assets/images/logo__situation-intelligence.svg';
import LogoIncidentActionPlanning from 'assets/images/logo__incident-action-planning.svg';
import LogoReport from 'assets/images/logo__report.svg';
import LogoEventMonitor from 'assets/images/logo__event-monitor.svg';
import LogoMissionManager from 'assets/images/logo__mission-manager.svg';
import LogoWorkflows from 'assets/images/logo__workflows.svg';
import LogoSubscriptionManagement from 'assets/images/logo__subscription-management.svg';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { useAuth0 } from '@auth0/auth0-react';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import {
  changeSelectedWorkSpace,
  changeSelectedWorkSpaceName,
  updateWorkSpaceTimeZone,
} from 'PREPAREsrc/store/workspaces/workspaces.action';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import LogoSettings from 'assets/images/logo__settings.svg';
import LogoDeveloper from 'assets/images/logo__developer.svg';
import LogoPlan from 'assets/images/logo__plan.svg';
import LogoNotebook from 'assets/images/logo__notebook.svg';
import LogoMedia from 'assets/images/logo__media.svg';
import IconSites from 'assets/images/icon__sites.svg';
import IconProfiles from 'assets/images/icon__profiles.svg';
import IconPersonas from 'assets/images/icon__personas.svg';
import IconNetworks from 'assets/images/icon__networks.svg';
import IconPosts from 'assets/images/icon__posts.svg';
import { toastConfig } from 'assets/data/config';
import {
  updateUserRole,
  updateUserTimeFormat,
} from 'PREPAREsrc/store/user/user.action';

import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly';

export const AppHeader = ({ context }) => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const reduxDispatch = useDispatch();
  const [appList, setAppList] = useState([]);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [hideDropdown, setHideDropdown] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});
  const [currentGroupSortName, setCurrentGroupSortName] = useState('');
  const [clock, setClock] = useState(moment().format('HH:mm'));
  const [isDev, setIsDev] = useState(false);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [currentIncident, setCurrentIncident] = useState({ name: 'Incident' });
  const [allIncidents, setAllIncidents] = useState([]);
  const [incidentOptions, setIncidentOptions] = useState([]);
  const [layoutOptions, setLayoutOptions] = useState([]);
  const [currentLayout, setCurrentLayout] = useState({ label: 'Layout' });
  const [isNavModuleVisible, setIsNavModuleVisible] = useState(false);
  const [allExercisesWorkSpace, setAllExercisesWorkSpace] = useState([]);
  const [currentExercisesWorkSpace, setCurrentExercisesWorkSpace] = useState(
    null
  );
  const [time, setTime] = useState();
  const [timeInDropDown, setTimeInDropDown] = useState();
  // const firstRender = useRef(false);
  const [showMediaAnalytics, setShowMediaAnalytics] = useState(false);
  const [showMediaNoise, setShowMediaNoise] = useState(false);

  const noIncidentContexts = [
    'Mission Manager',
    'Event Monitor',
    'Notifications (by group)',
    'Organization',
    'Profile',
    'Notifications',
    'notifications',
  ];

  const noToolItemContexts = [
    'Event Monitor',
    'Notifications (by group)',
    'Organization',
    'Profile',
    'Incident Workspace',
    'Mission Manager',
    'Notifications',
    'notifications',
    'Workflows',
    'Incident Management',
    'Report',
  ];
  const {
    selectedWorkspace,
    timezone,
    timezoneOffset,
    type: workspaceType,
  } = useSelector((state) => {
    return state?.prepare?.workspace;
  });
  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const reduxCurrentGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const user = useSelector((state) => {
    return state.app.user;
  });

  const reduxUser = useSelector((state) => state?.prepare?.user);

  const { teamsInitialized } = useSelector((state) => {
    return state.auth;
  });

  const groups = useSelector((state) => {
    return state.app.groups;
  });

  const reduxIncidents = useSelector((state) => {
    return state.app.incidents;
  });

  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const reduxDashboards = useSelector((state) => {
    return state.app.dashboards;
  });

  const reduxCurrentLayout = useSelector((state) => {
    return state.app.currentDashboard;
  });

  const userRoles = useSelector((state) => {
    return state.prepare.user.roles;
  });

  useEffect(() => {
    featureFlags.map((flag) => {
      if (flag === 'MEDIA_ANALYTICS') {
        setShowMediaAnalytics(true);
      } else {
        setShowMediaAnalytics(false);
      }

      if (flag === 'MEDIA_NOISE_MODULE') {
        setShowMediaNoise(true);
      } else {
        setShowMediaNoise(false);
      }
    });
  }, [featureFlags]);

  useEffect(() => {
    let newAppList = AppList.filter((app) => {
      let includeInFeatureFlagList = false;
      app.featureFlag.every((element) => {
        if (featureFlags.includes(element)) {
          includeInFeatureFlagList = true;
          return false;
        }
        return true;
      });
      return app.featureFlag.length === 0 || includeInFeatureFlagList;
    });

    setAppList(newAppList);
  }, [featureFlags]);

  useEffect(() => {
    if (groups && groups.length) {
      const devFound =
        !!groups.find((group) =>
          group.group_name.includes('DisasterTechDev')
        ) ||
        user.email_address.includes('@qn1k.onmicrosoft.com') ||
        user.email_address.includes('@13bl1.onmicrosoft.com');
      setIsDev(devFound);
    }
  }, [groups]);

  useEffect(() => {
    if (groups && groups.length) {
      setOrganizationOptions(groups.filter((group) => !group.is_closed));
    }
  }, [groups]);

  useEffect(() => {
    setAllOrganizations(
      organizationOptions.map((group) => ({
        value: group.group_guid,
        label: group.group_name,
      }))
    );
  }, [organizationOptions]);

  useEffect(() => {
    if (!!reduxCurrentGroup) {
      setCurrentGroup(reduxCurrentGroup);
      getPrepareWorkSpace(reduxCurrentGroup);
    }
  }, [reduxCurrentGroup]);

  const getPrepareWorkSpace = async (currentGroup) => {
    try {
      const dashboardData = await Network.get(API.dashboard);

      const allExWorkspace =
        dashboardData?.data?.response?.dataset[0].userData.workspace;

      const allGroupWorkspace = allExWorkspace.filter(
        (e) => e.organizationId === currentGroup.group_guid
      );

      const currentlySelectedExWorkspace = allGroupWorkspace.find(
        (e) =>
          e.id ===
          dashboardData.data.response.dataset[0].userData.lastWorkspaceId
      );

      reduxDispatch(
        updateUserRole(
          dashboardData?.data?.response?.dataset[0]?.userData?.roles
        )
      );

      setAllExercisesWorkSpace(allGroupWorkspace);

      reduxDispatch(
        updateUserTimeFormat(
          dashboardData?.data?.response?.dataset[0]?.userData?.timeFormat
        )
      );

      let newWorkSpace = null;

      if (currentlySelectedExWorkspace?.id) {
        newWorkSpace = currentlySelectedExWorkspace;
        reduxDispatch(
          changeSelectedWorkSpaceName(
            currentlySelectedExWorkspace?.name,
            currentlySelectedExWorkspace?.ownertype
          )
        );
      } else {
        newWorkSpace =
          allGroupWorkspace?.length > 0 ? allGroupWorkspace[0] : null;
      }

      changeWorkSpace(newWorkSpace ? newWorkSpace.id : null, allGroupWorkspace);
    } catch (e) {
      //do nothing
    }
  };

  useEffect(() => {
    const sortName =
      reduxCurrentGroup &&
      reduxCurrentGroup.group_name &&
      reduxCurrentGroup.group_name
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase();
    setCurrentGroupSortName(sortName);
  }, [reduxCurrentGroup]);

  const organizationChangeHandler = (chosen) => {
    if (
      !!reduxCurrentGroup &&
      chosen &&
      chosen !== reduxCurrentGroup.group_guid
    ) {
      reduxDispatch(
        updateCurrentlySelectedGroup(
          organizationOptions.find((group) => group.group_guid === chosen)
        )
      );
    }
  };

  useEffect(() => {
    if (reduxIncidents && reduxIncidents.length && reduxCurrentGroup) {
      setIncidentOptions(
        reduxIncidents.filter(
          (i) => i.group_guid === reduxCurrentGroup.group_guid
        )
      );
    }
  }, [reduxIncidents]);

  useEffect(() => {
    setAllIncidents(
      incidentOptions.map((incident) => ({
        value: incident.id,
        label: incident.name,
      }))
    );
  }, [incidentOptions]);

  useEffect(() => {
    if (!!reduxCurrentIncident) {
      setCurrentIncident(reduxCurrentIncident);
    }
  }, [reduxCurrentIncident]);

  useEffect(() => {
    if (!!reduxCurrentGroup) {
      setCurrentGroup(reduxCurrentGroup);

      const incidentOptions =
        (reduxIncidents &&
          reduxIncidents.length &&
          reduxIncidents.filter(
            (i) => i.group_guid === reduxCurrentGroup.group_guid
          )) ||
        [];

      if (incidentOptions.length) {
        setIncidentOptions(incidentOptions);
      } else {
        setCurrentIncident({ name: 'Incident' });
        reduxDispatch(updateCurrentIncident());
      }
    }
  }, [reduxCurrentGroup]);

  useEffect(() => {
    if (reduxDashboards) {
      setCurrentIncident(reduxCurrentIncident);
      setLayoutOptions(
        reduxDashboards.filter((d) => !d.type || d.type === 'SI')
      );
    }
  }, [reduxDashboards]);

  useEffect(() => {
    if (reduxCurrentLayout) {
      setCurrentLayout(reduxCurrentLayout);
    }
  }, [reduxCurrentLayout]);

  const incidentChangeHandler = (chosen) => {
    if (
      !!reduxCurrentIncident &&
      chosen &&
      chosen !== reduxCurrentIncident.id
    ) {
      if (path === '/incident_management/ICS_213-RR') {
        navigate('/incident_management');
      }
      reduxDispatch(
        updateCurrentIncident(
          incidentOptions.find((incident) => incident.id === chosen)
        )
      );
    }
  };

  useEffect(() => {
    if (selectedWorkspace) {
      changeWorkSpaceTimeZone();
    }
  }, [selectedWorkspace]);

  useEffect(() => {
    const interval =
      timezoneOffset &&
      setInterval(() => {
        reduxUser?.timeFormat === 'hour12Mode'
          ? setTime(moment().utcOffset(timezoneOffset).format('hh:mm a'))
          : setTime(moment().utcOffset(timezoneOffset).format('HH:mm'));
        setTimeInDropDown(
          moment().utcOffset(timezoneOffset).format('HH:mm DD MMM YY')
        );
      }, 5000);
    return () => clearInterval(interval);
  }, [timezoneOffset, reduxUser]);

  const changeWorkSpaceTimeZone = async () => {
    const workspaceTz = await Network.get(API.getWorkspaceTimezone, {
      workspaceId: selectedWorkspace,
    });
    reduxDispatch(
      updateWorkSpaceTimeZone(
        workspaceTz?.data?.response?.dataset?.timezone,
        workspaceTz?.data?.response?.dataset?.offset
      )
    );
  };

  const logo =
    path === '/dashboard' ||
    path === '/profile' ||
    path === '/feature_flags' ? (
      <>
        <Link className="logo__dt" to="/dashboard">
          <img src={PratusLogo} alt="" className="d-none d-lg-block" />
          <img src={PratusLogoIcon} alt="" className="d-block d-lg-none" />
        </Link>
      </>
    ) : path.includes('/groups') ? (
      <>
        <Link
          to="/groups"
          className="logo logo__apps logo--organization-configuration"
        >
          <span className="icon">
            <img src={LogoOrganizationConfiguration} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Organization</h5>
        </Link>
      </>
    ) : path.includes('/incident_configuration') ? (
      <>
        <Link
          to="/incident_configuration"
          className="logo logo__apps logo--incident-configuration"
        >
          <span className="icon">
            <img src={LogoIncidentConfiguration} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Incident Workspace</h5>
        </Link>
      </>
    ) : path.includes('/osint') ? (
      <>
        <Link to="/osint" className="logo logo__apps logo--osint">
          <span className="icon">
            <img src={LogoIncidentConfiguration} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">OSINT</h5>
        </Link>
      </>
    ) : path.includes('/task_management') ? (
      <>
        <Link
          to="/task_management"
          className="logo logo__apps logo--task-management"
        >
          <span className="icon">
            <img src={LogoTaskManagement} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Task Management</h5>
        </Link>
      </>
    ) : path.includes('/notifications') ? (
      <>
        <Link
          to="/notifications"
          className="logo logo__apps logo--notification"
        >
          <span className="icon">
            <img src={LogoNotification} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Notification</h5>
        </Link>
      </>
    ) : path.includes('/incident_management') ? (
      <>
        <Link
          to="/incident_management"
          className="logo logo__apps logo--incident-action-planning"
        >
          <span className="icon">
            <img src={LogoIncidentActionPlanning} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Incident Management</h5>
        </Link>
      </>
    ) : path.includes(`${PreparePath}/dashboard/plan/reports`) ? (
      <>
        <Link
          to={`${PreparePath}/dashboard/plan`}
          className="logo logo__apps logo--plan"
        >
          <span className="icon">
            <img src={LogoPlan} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Plan</h5>
        </Link>
      </>
    ) : path === '/report' ? (
      <>
        <Link to="/report" className="logo logo__apps logo--report">
          <span className="icon">
            <img src={LogoReport} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Report</h5>
        </Link>
      </>
    ) : path.includes('/workflows') ? (
      <>
        <Link to="/workflows" className="logo logo__apps logo--workflows">
          <span className="icon">
            <img src={LogoWorkflows} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Workflows</h5>
        </Link>
      </>
    ) : path.includes('/map') ? (
      <>
        <Link
          to="/map"
          className="logo logo__apps logo--situation-intelligence"
        >
          <span className="icon">
            <img src={LogoSituationIntelligence} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Map</h5>
        </Link>
      </>
    ) : path.includes('/activity') ? (
      <>
        <Link to="/activity" className="logo logo__apps logo--activity">
          <span className="icon">
            <img src={IconActivity} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Activity</h5>
        </Link>
      </>
    ) : path.includes('/subscription_management') ? (
      <>
        <Link
          to="/subscription_management"
          className="logo logo__apps logo--subscription-management"
        >
          <span className="icon">
            <img src={LogoSubscriptionManagement} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Subscription Management</h5>
        </Link>
      </>
    ) : path.includes(`${PreparePath}/dashboard/developer`) ? (
      <>
        <Link
          to={`${PreparePath}/dashboard/developer`}
          className="logo logo__apps logo--developer"
        >
          <span className="icon">
            <img src={LogoDeveloper} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Developer</h5>
        </Link>
      </>
    ) : path.includes(`${PreparePath}/dashboard/settings`) ? (
      <>
        <Link
          to={`${PreparePath}/dashboard/settings/members`}
          className="logo logo__apps logo--settings"
        >
          <span className="icon">
            <img src={LogoSettings} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Exercise Settings</h5>
        </Link>
      </>
    ) : path.includes(`${PreparePath}/dashboard/plan`) ? (
      <>
        <Link
          to={`${PreparePath}/dashboard/plan`}
          className="logo logo__apps logo--plan"
        >
          <span className="icon">
            <img src={LogoPlan} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Plan</h5>
        </Link>
      </>
    ) : path.includes(`${PreparePath}/dashboard/report`) ? (
      <>
        <Link
          to={`${PreparePath}/dashboard/report`}
          className="logo logo__apps logo--report"
        >
          <span className="icon">
            <img src={LogoReport} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Report</h5>
        </Link>
      </>
    ) : path.includes(`${PreparePath}/dashboard/notebook`) ? (
      <>
        <Link
          to={`${PreparePath}/dashboard/notebook/notebooks`}
          className="logo logo__apps logo--notebook"
        >
          <span className="icon">
            <img src={LogoNotebook} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Notebook</h5>
        </Link>
      </>
    ) : path.includes(`${PreparePath}/dashboard/media`) ? (
      <>
        <Link
          to={`${PreparePath}/dashboard/media`}
          className="logo logo__apps logo--media"
        >
          <span className="icon">
            <img src={LogoMedia} alt="" />
          </span>
          <h5 className="m-0 d-none d-lg-flex">Media</h5>
        </Link>
      </>
    ) : null;

  const navToggleHandler = (e) => {
    e.stopPropagation();
    setIsNavVisible(!isNavVisible);
  };

  const dropdownToggleHandler = (e) => {
    e.stopPropagation();
    setHideDropdown(!hideDropdown);
  };

  const clickEvent = (e) => {
    if (isNavVisible) {
      if (e.target.id !== ('site-header__nav' || 'site-header__toggle')) {
        setIsNavVisible(false);
      }
    }

    if (hideDropdown) {
      for (let i = 0; i < e.composedPath().length; i++) {
        if (e.composedPath()[i].id === 'site-header__dropdown-content') return;
      }

      if (e.target.id !== 'site-header__dropdown-content') {
        setHideDropdown(false);
      }
    }
  };

  useEffect(() => {
    const clickEventListner = document;
    clickEventListner.addEventListener('click', clickEvent);

    return () => {
      clickEventListner.removeEventListener('click', clickEvent);
    };
  }, [isNavVisible, hideDropdown]);

  const updateClock = () => {
    setClock(moment().format('HH:mm'));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updateClock();
    }, 1000);

    return () => clearInterval(interval);
  }, [clock]);

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleLayoutOptionsClose = (chosen) => {
    if (!!reduxCurrentLayout && chosen && chosen !== reduxCurrentLayout.id) {
      reduxDispatch(
        updateCurrentDashboard(
          layoutOptions.find((layout) => layout.id === chosen)
        )
      );
    }
  };

  const navModuleToggleHandler = () => {
    setIsNavModuleVisible(!isNavModuleVisible);
  };

  const changeWorkSpace = (workspaceId, allGroupWorkspace = null) => {
    reduxDispatch(startLoading());
    try {
      Network.post(API.updateUserCurrentWorkspace, {
        workspaceId: workspaceId ? workspaceId : null,
        userId: user.epic_user_id,
      });

      let workspace;

      if (allGroupWorkspace) {
        workspace = allGroupWorkspace.find((e) => e.id === workspaceId);
        if (!workspace) {
          workspace = allGroupWorkspace && allGroupWorkspace[0]; // This Condition will work on the time of switching the org
        }
      } else {
        workspace =
          allExercisesWorkSpace &&
          allExercisesWorkSpace.find((item) => item.id === workspaceId);
        if (!workspace) {
          workspace = allExercisesWorkSpace && allExercisesWorkSpace[0]; // This Condition will work on the time of switching the org
        }
      }
      setCurrentExercisesWorkSpace(workspace ? workspace : null);
      reduxDispatch(
        changeSelectedWorkSpaceName(
          workspace?.displayName,
          workspace?.ownertype
        )
      );
      if (workspaceId && workspaceId !== selectedWorkspace) {
        reduxDispatch(changeSelectedWorkSpace(workspaceId));
      } else {
        reduxDispatch(changeSelectedWorkSpace(workspaceId)); // for null
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  const [
    navTaskManagementToggleLabel,
    setNavTaskManagementToggleLabel,
  ] = useState('Dashboard');

  const handleTaskManagementToggle = ({ label, path }) => {
    setNavTaskManagementToggleLabel(label);
    navigate(path);
  };

  return (
    <>
      <div className="site-header">
        <StylishNewButton
          id="site-header__toggle"
          type="button"
          customButton
          className="site-header__toggle"
          onClick={navToggleHandler}
        >
          <img src={IconNavigation} alt="" />
        </StylishNewButton>

        {logo}

        {!noToolItemContexts.find((c) => c === context) && (
          <>
            {context === 'default' &&
              !!layoutOptions &&
              !!layoutOptions.length && (
                <>
                  <StylishNewButton
                    customButton
                    className={
                      'button--icon site-header__navigationToggle ' +
                      (isNavModuleVisible ? 'active' : '')
                    }
                    onClick={navModuleToggleHandler}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </StylishNewButton>
                  <div
                    className={
                      'site-header__navigation ' +
                      (isNavModuleVisible ? 'active' : '')
                    }
                  >
                    <Dropdown className="dropdown__navigation" autoClose={true}>
                      <Dropdown.Toggle>Layout</Dropdown.Toggle>
                      <Dropdown.Menu align="start">
                        {layoutOptions.map((layout, index) => (
                          <Dropdown.Item
                            as="button"
                            onClick={() => handleLayoutOptionsClose(layout.id)}
                            className={
                              currentLayout.name === layout.name ? 'active' : ''
                            }
                            key={`layout1-${index}`}
                          >
                            {layout.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              )}
          </>
        )}

        {path.includes('/incident_management') && (
          <>
            <StylishNewButton
              customButton
              className={
                'button--icon site-header__navigationToggle ' +
                (isNavModuleVisible ? 'active' : '')
              }
              onClick={navModuleToggleHandler}
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </StylishNewButton>
            <div
              className={
                'site-header__navigation ' +
                (isNavModuleVisible ? 'active' : '')
              }
            >
              <Dropdown className="dropdown__navigation" autoClose={true}>
                <Dropdown.Toggle>Plan</Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  <Dropdown.Item
                    as="button"
                    onClick={() => navigate('/incident_management/ICS_202-CG')}
                  >
                    Objectives (202)
                  </Dropdown.Item>
                  {!!featureFlags.includes(
                    'FULL_INCIDENT_MANAGEMENT_LANDING_PAGE'
                  ) && (
                    <>
                      <Dropdown.Item as="button">
                        Incident Summary (209)
                      </Dropdown.Item>
                      <Dropdown.Item as="button">
                        Organization (203/207)
                      </Dropdown.Item>
                    </>
                  )}
                  {!!featureFlags.includes(
                    'FULL_INCIDENT_MANAGEMENT_LANDING_PAGE'
                  ) && (
                    <>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate('/incident_management/ICS_206-CG')
                        }
                      >
                        Medical (206)
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate('/incident_management/ICS_208-CG')
                        }
                      >
                        Safety (208)
                      </Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Item
                    as="button"
                    onClick={() => navigate('/incident_management/ICS_205-CG')}
                  >
                    Radio Communications (205)
                  </Dropdown.Item>
                  {!reduxFeatureFlags.includes(
                    'FULL_INCIDENT_MANAGEMENT_LANDING_PAGE'
                  ) && (
                    <>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate('/incident_management/ICS_206-CG')
                        }
                      >
                        Medical (206)
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate('/incident_management/ICS_208-CG')
                        }
                      >
                        Safety (208)
                      </Dropdown.Item>
                    </>
                  )}
                  {!!reduxFeatureFlags.includes(
                    'FULL_INCIDENT_MANAGEMENT_LANDING_PAGE'
                  ) && (
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        navigate('/incident_management/ICS_205A-CG')
                      }
                    >
                      Communications List (205A)
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      navigate('/incident_management/IAP_COVER_SHEET')
                    }
                  >
                    Full Incident Action Plan
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="dropdown__navigation" autoClose={true}>
                <Dropdown.Toggle>Assignments</Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      navigate('/incident_management/assignments_list')
                    }
                  >
                    List
                  </Dropdown.Item>
                  {!!reduxFeatureFlags.includes(
                    'FULL_INCIDENT_MANAGEMENT_LANDING_PAGE'
                  ) && (
                    <>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate('/incident_management/assignments_board')
                        }
                      >
                        Board
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate('/incident_management/assignments_calendar')
                        }
                      >
                        Calendar
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="dropdown__navigation" autoClose={true}>
                <Dropdown.Toggle>Resources</Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      navigate('/incident_management/resource_list')
                    }
                  >
                    List
                  </Dropdown.Item>
                  {!!reduxFeatureFlags.includes(
                    'FULL_INCIDENT_MANAGEMENT_LANDING_PAGE'
                  ) && (
                    <>
                      <Dropdown.Item as="button">
                        Operational Planning (215)
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate('/incident_management/ICS_213-RR')
                        }
                      >
                        213 RR
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="dropdown__navigation" autoClose={true}>
                <Dropdown.Toggle>Analysis</Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  {!!reduxFeatureFlags.includes(
                    'FULL_INCIDENT_MANAGEMENT_LANDING_PAGE'
                  ) && (
                    <>
                      <Dropdown.Item as="button">
                        Work Analysis (234)
                      </Dropdown.Item>
                      <Dropdown.Item as="button">
                        Action Tracker (233)
                      </Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Item
                    as="button"
                    onClick={() => navigate('/incident_management/ICS_201-CG')}
                  >
                    Initial Reports/Briefing (201)
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="dropdown__navigation" autoClose={true}>
                <Dropdown.Toggle>Reports</Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  <Dropdown.Item
                    as="button"
                    onClick={() => navigate('/report')}
                  >
                    Reports
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )}

        {path.includes('/task_management') && (
          <>
            <StylishNewButton
              customButton
              className={
                'button--icon site-header__navigationToggle ' +
                (isNavModuleVisible ? 'active' : '')
              }
              onClick={navModuleToggleHandler}
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </StylishNewButton>
            <div
              className={
                'site-header__navigation ' +
                (isNavModuleVisible ? 'active' : '')
              }
            >
              <Dropdown className="dropdown__navigation" autoClose={true}>
                <Dropdown.Toggle>
                  {navTaskManagementToggleLabel}
                </Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      handleTaskManagementToggle({
                        label: 'Dashboard',
                        path: '/task_management',
                      })
                    }
                  >
                    Dashboard
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      handleTaskManagementToggle({
                        label: 'Task Timeline',
                        path: '/task_management/timeline',
                      })
                    }
                  >
                    Task Timeline
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      handleTaskManagementToggle({
                        label: 'Task Map',
                        path: '/task_management/map',
                      })
                    }
                  >
                    Task Map
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )}

        {path.includes(`${PreparePath}/dashboard/plan`) &&
          !isRoleReadOnly(userRoles) && (
            <>
              <StylishNewButton
                customButton
                className={
                  'button--icon site-header__navigationToggle ' +
                  (isNavModuleVisible ? 'active' : '')
                }
                onClick={navModuleToggleHandler}
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </StylishNewButton>
              {(workspaceType === 'T&E' && (
                <>
                  <div
                    className={
                      'site-header__navigation ' +
                      (isNavModuleVisible ? 'active' : '')
                    }
                  >
                    <Dropdown className="dropdown__navigation" autoClose={true}>
                      <Dropdown.Toggle>Design</Dropdown.Toggle>
                      <Dropdown.Menu align="start">
                        <div className="block">
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(`${PreparePath}/dashboard/plan`)
                            }
                          >
                            Dashboard
                          </Dropdown.Item>

                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/experimental-event-details`
                              )
                            }
                          >
                            Experimental Event Details
                          </Dropdown.Item>

                          {featureFlags.includes(
                            'NAVY_CEAD_2ND_DELIVERABLE'
                          ) && (
                            <Dropdown.Item
                              as="button"
                              onClick={() =>
                                navigate(
                                  `${PreparePath}/dashboard/plan/systems`
                                )
                              }
                            >
                              Systems
                            </Dropdown.Item>
                          )}
                          {featureFlags.includes(
                            'NAVY_CEAD_2ND_DELIVERABLE'
                          ) && (
                            <Dropdown.Item
                              as="button"
                              onClick={() =>
                                navigate(
                                  `${PreparePath}/dashboard/plan/missions`
                                )
                              }
                            >
                              Missions
                            </Dropdown.Item>
                          )}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Link
                      to={`${PreparePath}/dashboard/plan/trr`}
                      className="link"
                    >
                      TRR
                    </Link>
                    {featureFlags.includes('NAVY_CEAD_2ND_DELIVERABLE') && (
                      <Dropdown
                        className="dropdown__navigation"
                        autoClose={true}
                      >
                        <Dropdown.Toggle>Conduct</Dropdown.Toggle>
                        <Dropdown.Menu align="start">
                          <div className="block">
                            <>
                              <Dropdown.Item
                                as="button"
                                onClick={() =>
                                  navigate(
                                    `${PreparePath}/dashboard/plan/missions/timeline`
                                  )
                                }
                              >
                                Dashboard
                              </Dropdown.Item>

                              <Dropdown.Item
                                as="button"
                                onClick={() =>
                                  navigate(
                                    `${PreparePath}/dashboard/plan/missions/mission-timeline`
                                  )
                                }
                              >
                                Mission Timeline
                              </Dropdown.Item>
                            </>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    <Dropdown className="dropdown__navigation" autoClose={true}>
                      <Dropdown.Toggle>Assess</Dropdown.Toggle>
                      <Dropdown.Menu align="start">
                        <div className="block">
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(`${PreparePath}/dashboard/plan`)
                            }
                          >
                            Overview
                          </Dropdown.Item>

                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(`${PreparePath}/dashboard/plan`)
                            }
                          >
                            Quantitative Assessment
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(`${PreparePath}/dashboard/plan`)
                            }
                          >
                            Qualitative Assessment
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="dropdown__navigation" autoClose={true}>
                      <Dropdown.Toggle>Analyze</Dropdown.Toggle>
                      <Dropdown.Menu align="start">
                        <div className="block">
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(`${PreparePath}/dashboard/plan`)
                            }
                          >
                            Analytics
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              )) || (
                <>
                  <div
                    className={
                      'site-header__navigation ' +
                      (isNavModuleVisible ? 'active' : '')
                    }
                  >
                    <Dropdown className="dropdown__navigation" autoClose={true}>
                      <Dropdown.Toggle>Design</Dropdown.Toggle>
                      <Dropdown.Menu align="start">
                        <div className="block">
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/prepare/dashboard`
                              )
                            }
                          >
                            Dashboard{' '}
                          </Dropdown.Item>

                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/prepare/exercise-details`
                              )
                            }
                          >
                            Exercise Details
                          </Dropdown.Item>

                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/prepare/participants`
                              )
                            }
                          >
                            Participants
                          </Dropdown.Item>

                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/prepare/training-objective`
                              )
                            }
                          >
                            Objectives
                          </Dropdown.Item>

                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/prepare/events`
                              )
                            }
                          >
                            Scenarios
                          </Dropdown.Item>
                        </div>
                        <div className="block">
                          <h6>MSEL</h6>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/prepare/msel-list`
                              )
                            }
                          >
                            List
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/prepare/msel-board`
                              )
                            }
                          >
                            Board
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/prepare/msel-calendar`
                              )
                            }
                          >
                            Calendar
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="dropdown__navigation">
                      <Dropdown.Toggle>Conduct</Dropdown.Toggle>
                      <Dropdown.Menu align="start">
                        <div className="block">
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/prepare/train/dashboard`
                              )
                            }
                          >
                            Dashboard
                          </Dropdown.Item>
                        </div>
                        <div className="block">
                          {/* <h6>MSEL</h6> */}
                          <Dropdown.Item
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/train/msel-timeline`
                              )
                            }
                          >
                            MSEL Timeline
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="dropdown__navigation">
                      <Dropdown.Toggle>Assess</Dropdown.Toggle>
                      <Dropdown.Menu align="start">
                        <div className="block">
                          <Dropdown.Item
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/assessment/overview`
                              )
                            }
                          >
                            Overview
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/assessment/assess-list`
                              )
                            }
                          >
                            Assessment List
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              navigate(`${PreparePath}/dashboard/plan/feedback`)
                            }
                          >
                            Feedback
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="dropdown__navigation">
                      <Dropdown.Toggle>Analyze</Dropdown.Toggle>
                      <Dropdown.Menu align="start">
                        <div className="block">
                          <Dropdown.Item
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/plan/reports/list`
                              )
                            }
                          >
                            Analytics
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              )}
            </>
          )}

        {path.includes(`${PreparePath}/dashboard/media`) && (
          <>
            <StylishNewButton
              customButton
              className={
                'button--icon site-header__navigationToggle ' +
                (isNavModuleVisible ? 'active' : '')
              }
              onClick={navModuleToggleHandler}
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </StylishNewButton>
            <div
              className={
                'site-header__navigation ' +
                (isNavModuleVisible ? 'active' : '')
              }
            >
              <Dropdown className="dropdown__navigation" autoClose={true}>
                <Dropdown.Toggle className="flex align-items-center">
                  <img
                    className="flex-shrink-0 me-2 img-h-16"
                    src={IconPosts}
                    alt=""
                  />
                  Posts
                </Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      navigate(`${PreparePath}/dashboard/media/posts`)
                    }
                  >
                    View Posts
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() =>
                      navigate(`${PreparePath}/dashboard/media/calendar`)
                    }
                  >
                    Calendar
                  </Dropdown.Item>
                  {showMediaAnalytics ? (
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        navigate(`${PreparePath}/dashboard/media/analytics`)
                      }
                    >
                      Analytics
                    </Dropdown.Item>
                  ) : null}
                  {!!showMediaNoise && (
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        navigate(`${PreparePath}/dashboard/media/noise`)
                      }
                    >
                      Noise
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <Link
                to={`${PreparePath}/dashboard/media/profiles`}
                className="link"
              >
                <img
                  className="flex-shrink-0 me-2 img-h-16"
                  src={IconProfiles}
                  alt=""
                />
                Profiles
              </Link>
              <Link
                className="link"
                to={`${PreparePath}/dashboard/media/personas`}
              >
                <img
                  className="flex-shrink-0 me-2 img-h-16"
                  src={IconPersonas}
                  alt=""
                />
                Personas
              </Link>
              <Link
                className="link"
                to={`${PreparePath}/dashboard/media/networks`}
              >
                <img
                  className="flex-shrink-0 me-2 img-h-16"
                  src={IconNetworks}
                  alt=""
                />
                Networks
              </Link>
              <Link
                className="link"
                to={`${PreparePath}/dashboard/media/sites`}
              >
                <img
                  className="flex-shrink-0 me-2 img-h-16"
                  src={IconSites}
                  alt=""
                />
                Sites
              </Link>
            </div>
          </>
        )}

        {path.includes(`${PreparePath}/dashboard/settings`) && (
          <>
            <StylishNewButton
              customButton
              className={
                'button--icon site-header__navigationToggle ' +
                (isNavModuleVisible ? 'active' : '')
              }
              onClick={navModuleToggleHandler}
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </StylishNewButton>
            <div
              className={
                'site-header__navigation ' +
                (isNavModuleVisible ? 'active' : '')
              }
            >
              <Dropdown className="dropdown__navigation" autoClose={true}>
                <Dropdown.Toggle>Workspace</Dropdown.Toggle>
                <Dropdown.Menu align="start">
                  <div className="block">
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        navigate(`${PreparePath}/dashboard/settings/members`)
                      }
                    >
                      Member
                    </Dropdown.Item>

                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        navigate(`${PreparePath}/dashboard/settings/time-zone`)
                      }
                    >
                      Time Zone
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {!isRoleReadOnly(reduxUser.roles) && (
                <Dropdown className="dropdown__navigation">
                  <Dropdown.Toggle>Plan</Dropdown.Toggle>
                  <Dropdown.Menu align="start">
                    <div className="block">
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(`${PreparePath}/dashboard/settings/jmets`)
                        }
                      >
                        JMETs
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/organizations`
                          )
                        }
                      >
                        Organizations
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(`${PreparePath}/dashboard/settings/platform`)
                        }
                      >
                        Platforms
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/organization-type`
                          )
                        }
                      >
                        ESF/Organization Type
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(`${PreparePath}/dashboard/settings/service`)
                        }
                      >
                        City/Town/Service/Department
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/department-type`
                          )
                        }
                      >
                        Jurisdiction/Service/Department Types
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/command-agency`
                          )
                        }
                      >
                        State/County/Chapter/Command/Agency{' '}
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/strategic-priority`
                          )
                        }
                      >
                        Strategic Priorities
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/inject-method`
                          )
                        }
                        end
                      >
                        Inject Methods
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/inject-owner`
                          )
                        }
                        end
                      >
                        Inject Owners
                      </Dropdown.Item>

                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/status-label`
                          )
                        }
                        end
                      >
                        Status Labels
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/assessment-reasons`
                          )
                        }
                      >
                        Assessment Reasons
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/training-priority`
                          )
                        }
                        end
                      >
                        Objective Priority
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/exercise-type`
                          )
                        }
                      >
                        Exercise Type
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(`${PreparePath}/dashboard/settings/fromto`)
                        }
                      >
                        From To
                      </Dropdown.Item>

                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(`${PreparePath}/dashboard/settings/position`)
                        }
                      >
                        Position
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(`${PreparePath}/dashboard/settings/metric`)
                        }
                      >
                        Metric
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/primary-source`
                          )
                        }
                      >
                        Primary Source
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/settings/sub-source`
                          )
                        }
                      >
                        Sub Source
                      </Dropdown.Item>
                      {workspaceType === 'T&E' && (
                        <>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/settings/support`
                              )
                            }
                          >
                            Support
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/settings/technical-discipline`
                              )
                            }
                          >
                            Technical Discipline
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/settings/mission-type`
                              )
                            }
                          >
                            Mission Type
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/settings/mission-profile`
                              )
                            }
                          >
                            Mission Profile
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() =>
                              navigate(
                                `${PreparePath}/dashboard/settings/mission-location`
                              )
                            }
                          >
                            Mission Location
                          </Dropdown.Item>
                        </>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </>
        )}

        <div className="site-header__timezone d-none d-lg-flex">
          <div>
            <span>EXCON</span>
            <span>Time Zone</span>
          </div>
          <div>
            <span>
              {time}
              {/* {moment().format('HH:mm')} */}
            </span>
            <span>{timezone}</span>
          </div>
        </div>
        <ul className="site-header__buttons">
          <li>
            <Link to="/profile" className={path === '/profile' ? 'active' : ''}>
              <img src={IconUser} alt="" />
            </Link>
          </li>

          <li>
            <Link
              to="/activity"
              className={path === '/activity' ? 'active' : ''}
            >
              <img src={IconActivity} alt="" />
            </Link>
          </li>

          <li className="d-none d-md-block">
            {reduxFeatureFlags.includes('SELF_HOST_KNOWLEDGE_BASE') ? (
              <Link to="/knowledge_base">
                <img src={IconHelp} alt="" />
              </Link>
            ) : (
              <a
                href={'https://disastertech-6694186.hs-sites.com/knowledge'}
                target="_blank"
                rel="noreferrer"
              >
                <img src={IconHelp} alt="" />
              </a>
            )}
          </li>
        </ul>
        <Dropdown className="site-header__dropdown" show={hideDropdown}>
          <Dropdown.Toggle onClick={dropdownToggleHandler}>
            <span className="name">{currentGroup.group_name}</span>
            <span className="pic">{currentGroupSortName}</span>
            <i className="fa fa-angle-down" aria-hidden="true"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end" id="site-header__dropdown-content">
            <div className="block p-3 p-md-4">
              <div className="mb-0">
                <label className="form-label text-uppercase">
                  Sponsor Organization
                </label>
                <StylishNewSelect
                  options={allOrganizations}
                  value={
                    currentGroup?.group_guid
                      ? {
                          value: currentGroup.group_guid,
                          label: currentGroup.group_name,
                        }
                      : ''
                  }
                  placeholder="Select a Organization"
                  onChange={(e) => organizationChangeHandler(e.value)}
                  isClearable={false}
                  isSearchable={false}
                  isMulti={false}
                  isDisabled={false}
                />
              </div>
              {!noIncidentContexts.find((c) => c === context) &&
                !!currentIncident && (
                  <div className="mt-3">
                    <label className="form-label text-uppercase">
                      Incident Workspace
                    </label>
                    <StylishNewSelect
                      options={allIncidents}
                      value={
                        currentIncident?.id
                          ? {
                              value: currentIncident.id,
                              label: currentIncident.name,
                            }
                          : ''
                      }
                      placeholder="Select an Incident Workspace"
                      onChange={(e) => incidentChangeHandler(e.value)}
                      isClearable={false}
                      isSearchable={false}
                      isMulti={false}
                      isDisabled={false}
                    />
                  </div>
                )}
              {!noIncidentContexts.find((c) => c === context) &&
                !!currentIncident && (
                  <div className="mt-3">
                    <label className="form-label text-uppercase">
                      Exercise Workspace
                    </label>
                    <StylishNewSelect
                      options={allExercisesWorkSpace.map((e) => ({
                        label: e.displayName,
                        value: e.id,
                      }))}
                      value={
                        currentExercisesWorkSpace?.id
                          ? {
                              value: currentExercisesWorkSpace.id,
                              label: currentExercisesWorkSpace.displayName,
                            }
                          : ''
                      }
                      placeholder="Select an Exercise Workspace"
                      onChange={(e) => changeWorkSpace(e.value)}
                      isClearable={false}
                      isSearchable={false}
                      isMulti={false}
                      isDisabled={false}
                    />
                  </div>
                )}
            </div>
            <div className="block p-3 p-md-4">
              <label className="form-label weight-700 text-uppercase">
                EXCON Time Zone
              </label>
              <p className="mb-2">
                {timeInDropDown} <br />
                {timezone}
              </p>
              <p className="mb-0 d-flex align-items-center">
                <span className="me-2 flex-shrink-0">User Name</span>
                <span className="badge text-truncate">
                  {user?.email_address}
                </span>
              </p>
            </div>
            <div
              className={
                'block p-3 p-md-4 ' + (teamsInitialized ? 'border-0' : '')
              }
            >
              <ul>
                <li className="d-block d-md-none">
                  {reduxFeatureFlags.includes('SELF_HOST_KNOWLEDGE_BASE') ? (
                    <Link to="/knowledge_base">
                      Help Center
                    </Link>
                  ) : (
                    <a
                      href={'https://disastertech-6694186.hs-sites.com/knowledge'}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Help Center
                    </a>
                  )}
                </li>
                {user && user.status !== 'Subscribed' && (
                  <li>
                    <Link
                      to="#"
                      onClick={() => {
                        setHideDropdown(false);
                        window.open(
                          'https://appsource.microsoft.com/en-us/product/office/WA200001909?tab=Overview',
                          '_blank'
                        );
                      }}
                    >
                      Sign up for Premium
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      setHideDropdown(false);
                      window.open(
                        window.location.origin + '/privacy.html',
                        '_blank'
                      );
                    }}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      setHideDropdown(false);
                      window.open(
                        window.location.origin + '/tos.html',
                        '_blank'
                      );
                    }}
                  >
                    Terms of Service
                  </Link>
                </li>
                {featureFlags.includes('SUBSCRIPTION_MANAGEMENT') && (
                  <li>
                    <Link to="/subscription_management">
                      Subscription Management
                    </Link>
                  </li>
                )}
                {isDev && (
                  <li>
                    <Link to="/feature_flags">Feature Flags</Link>
                  </li>
                )}
                {isDev && (
                  <li>
                    Version{' '}
                    <span className="badge text-truncate">release/v2.44</span>
                  </li>
                )}
              </ul>
            </div>
            {!teamsInitialized && (
              <div className="block px-3 px-md-4 py-3 border-0">
                <ul>
                  <li>
                    <Link to="#" onClick={() => handleLogout()}>
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        id="site-header__nav"
        className={'site-header__nav ' + (isNavVisible ? 'active' : '')}
      >
        <Link to="/dashboard">
          <h5 className="weight-500 text-uppercase text-center color-white mb-3">
            PRATUS
          </h5>
        </Link>

        <ul>
          {appList.map((item) => (
            <li
              key={item.id}
              className={`${
                !!item.link.startsWith('/prepare') &&
                (!selectedWorkspace || !userRoles?.length)
                  ? 'disabled'
                  : ''
              }`}
            >
              <Link
                to={item.link}
                className={'logo ' + item.className}
                onClick={() => setIsNavVisible(false)}
              >
                <span className="icon">
                  <img src={item.logo} alt="" />
                </span>
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
