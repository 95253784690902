import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import React from 'react';

export const System = ({ mission_system }) => {
  const columns = [
    {
      dataField: 'system_name',
      text: 'System',
      sort: false,
      attrs: { title: 'System' },
    },
    {
      dataField: 'system_category',
      text: 'Category',
      sort: false,
      attrs: { title: 'Category' },
    },
    {
      dataField: 'system_event_cell_type',
      text: 'Cell',
      sort: false,
      attrs: { title: 'Cell' },
      formatter: (_, row) => (
        <>
          {row?.system_event_cell_type === 'red_cell'
            ? 'Red Cell'
            : row?.system_event_cell_type === 'blue_cell'
            ? 'Blue Cell'
            : null}
        </>
      ),
    },
    {
      dataField: 'system_quantity',
      text: 'Quantity',
      sort: false,
      attrs: { title: 'Quantity' },
    },
    {
      dataField: 'system_provider',
      text: 'Provider',
      sort: false,
      attrs: { title: 'Provider' },
    },
    {
      dataField: 'Primary POC',
      text: 'Primary POC',
      sort: false,
      attrs: { title: 'Primary POC' },
      formatter: (_, row) => {
        const primary = row?.point_of_contacts?.find((poc) => poc?.is_primary);
        return primary?.name || primary?.email;
      },
    },
    {
      dataField: 'launch_point',
      text: 'Location/Launch Point',
      sort: false,
      attrs: { title: 'Location/Launch Point' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="mb-4 col-md-4">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">Command</span>
                {row?.system_command || '-'}
              </p>
            </div>
            <div className="mb-4 col-md-4">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">Service Branch</span>
                {row?.system_service_branch || '-'}
              </p>
            </div>
            <div className="mb-4 col-md-4">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">
                  Capabilities and Limitations
                </span>
                {row?.system_capabilities_limitations || '-'}
              </p>
            </div>
            <div className="mb-4 col-md-4">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">
                  Data Link Connection(s)
                </span>
                {row?.system_data_link_connections || '-'}
              </p>
            </div>
            <div className="col-md-12">
              <span className="weight-600 d-block mb-2">Components</span>
              <table className="table no-filter">
                <thead>
                  <tr className="empty">
                    <th>Component Name</th>
                    <th>Component type</th>
                  </tr>
                </thead>
                <tbody>
                  {(!!row?.components?.length && (
                    <>
                      {row?.components?.map((i, idx) => (
                        <tr key={idx}>
                          <td title="Component Name">{i?.name || '-'}</td>
                          <td title="Component type">{i?.type || '-'}</td>
                        </tr>
                      ))}
                    </>
                  )) || (
                    <td className="text-center weight-600" colSpan={'2'}>
                      No Data Found
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <StylishNewTable
        keyField="system_id"
        columns={columns}
        rows={mission_system || []}
        expandRow={expandRow}
        classes="no-filter"
        headerClasses="empty"
      />
    </>
  );
};
