import React, { MutableRefObject, useRef, useState } from 'react';
import MainNavPanel from './sidebarNav/MainNavPanel';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import IncidentNavPanel from './sidebarNav/IncidentNavPanel';
import ExerciseNavPanel from './sidebarNav/ExerciseNavPanel';
import MediaNavPanel from './sidebarNav/MediaNavPanel';
import TaskManagementNavPanel from './sidebarNav/TaskManagementNavPanel';
import { useClickAway } from 'react-use';
import { useSidebarNavContext } from './sidebarNav/SidebarNavProvider';
import dayjs from 'dayjs';

export type PanelType =
  | 'incident'
  | 'exercise'
  | 'media'
  | 'task';

/**
 * TODO: check scrollbars in Windows/Firefox/Safari
 * */
const SidebarNav: React.FC<{toggleRef: MutableRefObject<HTMLButtonElement | null>}> = ({toggleRef}) => {
  const { close, isOpen } = useSidebarNavContext();

  const [secondPanel, setSecondPanel] = useState<PanelType | null>(null);
  const navRef = useRef(null);

  useClickAway(navRef, (e) => {
    if (isOpen && !toggleRef.current?.contains(e.target as Node)) {
      close();
    }
  });

  return (
    <div id="sidebar-nav" className={isOpen ? 'open' : ''} ref={navRef}>
      <div className="sidebar-nav-content">
        <SwitchTransition>
          <CSSTransition
            key={secondPanel ? 'MainNavPanel' : 'SecondPanel'}
            classNames="nav-slide"
            timeout={300}
          >
            {!secondPanel ? (
              <MainNavPanel
                setSecondPanel={(key: PanelType) => setSecondPanel(key)}
              />
            ) : (
              <SecondPanelSwitch
                secondPanel={secondPanel}
                navBack={() => setSecondPanel(null)}
              />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
      <div className="sidebar-nav-footer flex-shrink-0 flex-grow-0">
        © {dayjs().format('YYYY')} PRATUS • All rights reserved.
      </div>
    </div>
  );
};

export default SidebarNav;

const SecondPanelSwitch: React.FC<{
  secondPanel: PanelType;
  navBack: () => void;
}> = ({ secondPanel, navBack }) => {
  switch (secondPanel) {
    case 'incident':
      return <IncidentNavPanel navBack={navBack} />;
    case 'exercise':
      return <ExerciseNavPanel navBack={navBack} />;
    case 'media':
      return <MediaNavPanel navBack={navBack} />;
    case 'task':
      return <TaskManagementNavPanel navBack={navBack} />;
    default:
      return null;
  }
};
