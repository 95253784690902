import { PropsWithChildren, ReactNode } from 'react';
import { Accordion as ReactAccordion, Card } from 'react-bootstrap';

import { AccordionHeader } from './AccordionHeader';

interface Props {
  eventKey: string;
  header: ReactNode | (() => ReactNode);
  showIcon?: boolean;
}

export const Accordion = ({
  children,
  eventKey,
  header,
  showIcon = true,
}: PropsWithChildren<Props>) => {
  return (
    <ReactAccordion>
      <Card className="accordion--card">
        <AccordionHeader eventKey={eventKey} hasIcon={showIcon}>
          {typeof header === 'function' ? header() : header}
        </AccordionHeader>

        <ReactAccordion.Collapse eventKey={eventKey}>
          <Card.Body className="p-0">{children}</Card.Body>
        </ReactAccordion.Collapse>
      </Card>
    </ReactAccordion>
  );
};
