import React, { useEffect, useRef, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { PanelType } from '../SidebarNav';
import { useSelector } from 'react-redux';
import { getInitials } from '../../../helpers/intials';
import {
  ListGroupButtonItem,
  ListGroupNavItem,
} from './ListGroupNavComponents';
import { useFeatureFlag } from '../../../utils/featureFlags';

const MainNavPanel: React.FC<{
  setSecondPanel: (key: PanelType) => void;
}> = ({ setSecondPanel }) => {
  const graphistryStable = useFeatureFlag('GRAPHISTRY_STABILITY');
  const streamlinePlatform = useFeatureFlag('STREAMLINE_PLATFORM')
  const currentlySelected = useSelector(
    (state: any) => state.app.currentlySelectedGroup as Group
  );
  
  const user = useSelector((state: any) => {
    // TODO: obviously this should just be done in the reducer.
    // BEWARE using this user in dependency arrays. depend on a specific property.
    return {
      ...(state.app.user as User),
      ...(state.prepare.user as PrepareUser),
    };
  });

  const exercises = useSelector((state: any) => {
    return state.prepare.workspace.allWorkspace as ExerciseWorkspace[];
  });

  const [hasExercise, setHasExercise] = useState<boolean>(false);

  useEffect(() => {

    const selectedWorkspace = exercises.find(
      (exercise) => exercise.id === user.epic_user_lastWorkspaceId
    );

    if (Array.isArray(exercises) && !!exercises.length && !!selectedWorkspace) {
      setHasExercise(true)
    }
  }, [user.epic_user_lastWorkspaceId, exercises, currentlySelected]);

  return (
    <nav aria-label="primary sidebar">
      <div className="org-label">
        <div className="avatar-initials sml me-2">
          {getInitials(currentlySelected.group_name)}
        </div>
        <div className="name-container">
          <div className="word-break">{currentlySelected.group_name}</div>
        </div>
      </div>

      <ListGroup variant="flush">
        <div className="list-group-title">Situational Awareness</div>
        <ListGroupNavItem to="/map" icon="map" label="Map" />
        {graphistryStable ? (
          <ListGroupNavItem to="/osint" icon="search" label="OSINT" />
        ) : null}
        {streamlinePlatform ? null : (
          <ListGroupNavItem
            to="/notifications"
            icon="notifications"
            label="Notifications"
          />
        )}
      </ListGroup>

      <ListGroup variant="flush">
        <div className="list-group-title">Incident Management</div>
        <ListGroupNavItem
          to="/report/new"
          icon="report"
          label="Report Incident"
        />
        <ListGroupNavItem
          to="/report"
          icon="list"
          label="Reports List"
          end
        />
        <ListGroupNavItem
          to="/incident_configuration"
          icon="desktop_windows"
          label="Setup Workspace"
        />
        <ListGroupButtonItem
          onClick={() => setSecondPanel('incident')}
          icon="problem"
          label="Incident Management"
        />
        {streamlinePlatform ? null : (
          <ListGroupButtonItem
            onClick={() => setSecondPanel('task')}
            icon="task"
            label="Task Management"
          />
        )}
      </ListGroup>

        <>
          <ListGroup variant="flush">
            <div className="list-group-title">Exercise Management</div>
            <ListGroupButtonItem
              onClick={() => setSecondPanel('exercise')}
              icon="directions_run"
              label="Plan Exercise"
              // isDisabled={!hasExercise}
            />
            {streamlinePlatform ? null : (
              <ListGroupButtonItem
                onClick={() => setSecondPanel('media')}
                icon="thumb_up"
                label="Media"
                // isDisabled={!hasExercise}
              />
            )}
          </ListGroup>
        </>

      <ListGroup variant="flush">
        <div className="list-group-title">Settings</div>
        <ListGroupNavItem to="/groups" icon="settings" label="Org Settings" />
      </ListGroup>
    </nav>
  );
};

export default MainNavPanel;
