import React, { Suspense } from 'react';
import PratusHeader from '../components/headers/PratusHeader';
import { Outlet, useMatch, matchRoutes, useLocation } from 'react-router-dom';
import SidebarNavProvider from '../components/headers/sidebarNav/SidebarNavProvider';
import Loader from '../components/Loader/loader';
import ErrorBoundary from '../components/Error/ErrorBoundary';

const PratusLayout: React.FC = () => {
  const location = useLocation();
  const mapPage = matchRoutes(
    [{ path: '/map' }, { path: '/' }, { path: '/task_management/map' }],
    location
  );
  return (
    <SidebarNavProvider>
      <PratusHeader />
      <main className={mapPage ? 'pratus-map' : undefined}>
        <ErrorBoundary>
          <Suspense
            fallback={<Loader showLoading={true} classes="maincontent" />}
          >
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </main>
    </SidebarNavProvider>
  );
};
export default PratusLayout;
