import React, { useEffect, useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useSelector, useDispatch } from 'react-redux';

import { getRosterForIncident } from 'actions/incidentActions';

import ICSIncidentInfo from 'components/ICS/ICSIncidentInfo';

import ICSObjectivesDialog from 'components/ICS/ICSObjectivesDialog';
import ICSStrategiesDialog from 'components/ICS/ICSStrategiesDialog';
import ICSTacticsDialog from 'components/ICS/ICSTacticsDialog';
import ICSActionsDialog from 'components/ICS/ICSActionsDialog';
import ICSResourcesDialog from 'components/ICS/ICSResourcesDialog';
import ICSMedicalAidStationsDialog from 'components/ICS/ICSMedicalAidStationsDialog';
import ICSMedicalTransportationDialog from 'components/ICS/ICSMedicalTransportationDialog';
import ICSHospitalsDialog from 'components/ICS/ICSHospitalsDialog';
import ICSWorkAssignmentsDialog from 'components/ICS/ICSWorkAssignmentsDialog';
import ICSComsDialog from 'components/ICS/ICSComsDialog'

import { exportAsForm } from 'actions/formActions';

import { fetchIcsIncidentInfo } from 'slices/ics_incidentInfoSlice';

import {
  fetchIcsObjectives,
  updateIcsObjective,
} from 'slices/ics_objectivesSlice';

import {
  fetchIcsIncidentObjectives,
  updateIcsIncidentObjective,
} from 'slices/ics_incidentObjectivesSlice';

import {
  fetchIcsStrategies,
  updateIcsStrategy,
} from 'slices/ics_strategiesSlice';

import {
  fetchIcsIncidentStrategies,
  updateIcsIncidentStrategy,
} from 'slices/ics_incidentStrategiesSlice';

import { fetchIcsTactics, updateIcsTactic } from 'slices/ics_tacticsSlice';

import {
  fetchIcsIncidentTactics,
  updateIcsIncidentTactic,
} from 'slices/ics_incidentTacticsSlice';

import { fetchIcsActions, updateIcsAction } from 'slices/ics_actionsSlice';

import {
  fetchIcsIncidentActions,
  updateIcsIncidentAction,
} from 'slices/ics_incidentActionsSlice';

import {
  fetchIcsResources,
  updateIcsResource,
} from 'slices/ics_resourcesSlice';

import {
  fetchIcsIncidentResources,
  updateIcsIncidentResource,
} from 'slices/ics_incidentResourcesSlice';

import {
  fetchIcsMedicalAidStations,
  updateIcsMedicalAidStation,
} from 'slices/ics_medicalAidStationsSlice';

import {
  fetchIcsIncidentMedicalAidStations,
  updateIcsIncidentMedicalAidStation,
} from 'slices/ics_incidentMedicalAidStationsSlice';

import {
  fetchIcsMedicalTransportation,
  updateIcsMedicalTransportation,
} from 'slices/ics_medicalTransportationSlice';

import {
  fetchIcsIncidentMedicalTransportation,
  updateIcsIncidentMedicalTransportation,
} from 'slices/ics_incidentMedicalTransportationSlice';

import {
  fetchIcsHospitals,
  updateIcsHospital,
} from 'slices/ics_hospitalsSlice';

import {
  fetchIcsIncidentHospitals,
  updateIcsIncidentHospital,
} from 'slices/ics_incidentHospitalsSlice';

import {
  fetchIcsWorkAssignments,
  updateIcsWorkAssignment,
} from 'slices/ics_workAssignmentsSlice';

import {
  fetchIcsIncidentWorkAssignments,
  updateIcsIncidentWorkAssignment,
} from 'slices/ics_incidentWorkAssignmentsSlice';

import {
  fetchIcsComs,
  updateIcsComs
} from 'slices/ics_comsSlice'

import {
  fetchIcsIncidentComs,
  updateIcsIncidentComs
} from 'slices/ics_incidentComsSlice'


export default function ICS({ reduxCurrentIncident }) {
  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [
    isManageObjectivesDialogOpen,
    setIsManageObjectivesDialogOpen,
  ] = useState(false);
  const [
    isManageStrategiesDialogOpen,
    setIsManageStrategiesDialogOpen,
  ] = useState(false);
  const [isManageTacticsDialogOpen, setIsManageTacticsDialogOpen] = useState(
    false
  );
  const [isManageActionsDialogOpen, setIsManageActionsDialogOpen] = useState(
    false
  );
  const [
    isManageResourcesDialogOpen,
    setIsManageResourcesDialogOpen,
  ] = useState(false);

  const [
    isManageMedicalAidStationsDialogOpen,
    setIsManageMedicalAidStationsDialogOpen,
  ] = useState(false);
  const [
    isManageMedicalTransportationDialogOpen,
    setIsManageMedicalTransportationDialogOpen,
  ] = useState(false);
  const [
    isManageHospitalsDialogOpen,
    setIsManageHospitalsDialogOpen,
  ] = useState(false);

  const [
    isManageWorkAssignmentsDialogOpen,
    setIsManageWorkAssignmentsDialogOpen,
  ] = useState(false);

  const [
    isManageComsDialogOpen,
    setIsManageComsDialogOpen
  ] = useState(false)

  function manageObjectivesClicked() {
    setIsManageObjectivesDialogOpen(true);
  }

  function manageStrategiesClicked() {
    setIsManageStrategiesDialogOpen(true);
  }

  function manageTacticsClicked() {
    setIsManageTacticsDialogOpen(true);
  }

  function manageActionsClicked() {
    setIsManageActionsDialogOpen(true);
  }

  function manageResourcesClicked() {
    setIsManageResourcesDialogOpen(true);
  }

  function manageMedicalAidStationsClicked() {
    setIsManageMedicalAidStationsDialogOpen(true);
  }
  function manageMedicalTransportationClicked() {
    setIsManageMedicalTransportationDialogOpen(true);
  }
  function manageHospitalsClicked() {
    setIsManageHospitalsDialogOpen(true);
  }

  function manageWorkAssignmentsClicked() {
    setIsManageWorkAssignmentsDialogOpen(true);
  }

  function manageComsClicked() {
    setIsManageComsDialogOpen(true);
  }

  const reduxRosterForIncident = useSelector(state => {
    return state.app.rosterForIncident || []
  })

  const { ics_incident_info } = useSelector((state) => state.ics_incident_info);

  const { ics_objectives } = useSelector((state) => state.ics_objectives);
  const { ics_incident_objectives } = useSelector(
    (state) => state.ics_incident_objectives
  );

  const { ics_strategies } = useSelector((state) => state.ics_strategies);
  const { ics_incident_strategies } = useSelector(
    (state) => state.ics_incident_strategies
  );

  const { ics_tactics } = useSelector((state) => state.ics_tactics);
  const { ics_incident_tactics } = useSelector(
    (state) => state.ics_incident_tactics
  );

  const { ics_actions } = useSelector((state) => state.ics_actions);
  const { ics_incident_actions } = useSelector(
    (state) => state.ics_incident_actions
  );

  const { ics_resources } = useSelector((state) => state.ics_resources);
  const { ics_incident_resources } = useSelector(
    (state) => state.ics_incident_resources
  );

  const { ics_medical_aid_stations } = useSelector((state) => state.ics_medical_aid_stations);
  const { ics_incident_medical_aid_stations } = useSelector(
    (state) => state.ics_incident_medical_aid_stations
  );
  const { ics_medical_transportation } = useSelector((state) => state.ics_medical_transportation);
  const { ics_incident_medical_transportation } = useSelector(
    (state) => state.ics_incident_medical_transportation
  );
  const { ics_hospitals } = useSelector((state) => state.ics_hospitals);
  const { ics_incident_hospitals } = useSelector(
    (state) => state.ics_incident_hospitals
  );

  const { ics_work_assignments } = useSelector((state) => state.ics_work_assignments);
  const { ics_incident_work_assignments } = useSelector(
    (state) => state.ics_incident_work_assignments
  );

  const { ics_coms } = useSelector((state) => state.ics_coms);
  const { ics_incident_coms } = useSelector(
    (state) => state.ics_incident_coms
  );

  useEffect(() => {
    if (!!reduxCurrentlySelectedGroup) {
      reduxDispatch(fetchIcsObjectives(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsStrategies(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsTactics(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsActions(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsResources(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsMedicalAidStations(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsMedicalTransportation(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsHospitals(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsWorkAssignments(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsComs(reduxCurrentlySelectedGroup.group_guid))
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (!!reduxCurrentIncident) {
      reduxDispatch(getRosterForIncident())
      reduxDispatch(fetchIcsIncidentInfo(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentObjectives(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentStrategies(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentTactics(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentActions(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentResources(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentMedicalAidStations(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentMedicalTransportation(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentHospitals(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentWorkAssignments(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentComs(reduxCurrentlySelectedGroup.group_guid))
    }
  }, [reduxCurrentIncident]);

  return (
    <>
      <div className="">
        <div className="section">
          <h5>ICS</h5>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => manageObjectivesClicked()}>
              Objectives
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => manageStrategiesClicked()}>
              Strategies
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => manageTacticsClicked()}>
              Tactics
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => manageActionsClicked()}>
              Actions
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => manageResourcesClicked()}>
              Resources
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => manageMedicalAidStationsClicked()}>
              Medical Aid Stations
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => manageMedicalTransportationClicked()}>
              Medical Transportation
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => manageHospitalsClicked()}>
              Hospitals
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => manageComsClicked()}>
              Coms
            </StylishNewButton>
          </div>
          
          {!!reduxCurrentIncident && (
            <>
              <div className="ICS-button-wrap">
                <StylishNewButton onClick={() => manageWorkAssignmentsClicked()}>
                  Work Assignments
                </StylishNewButton>
              </div>
              <div className="ICS-button-wrap">
                <ICSIncidentInfo
                  reduxCurrentIncident={reduxCurrentIncident}
                  ics_incident_info={ics_incident_info}
                />
              </div>
            </>
          )}
          <div className="ICS-button-wrap">
            <StylishNewButton
              onClick={() =>
                reduxDispatch(exportAsForm({ formname: 'ICS_201-CG' }))
              }
            >
              Export ICS 201-CG
            </StylishNewButton>
          </div>
        </div>
      </div>
      {isManageObjectivesDialogOpen && (
        <ICSObjectivesDialog
          show={isManageObjectivesDialogOpen}
          onClose={() => setIsManageObjectivesDialogOpen(false)}
          ics_objectives={ics_objectives}
          ics_incident_objectives={ics_incident_objectives}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
      {isManageStrategiesDialogOpen && (
        <ICSStrategiesDialog
          show={isManageStrategiesDialogOpen}
          onClose={() => setIsManageStrategiesDialogOpen(false)}
          ics_strategies={ics_strategies}
          ics_incident_strategies={ics_incident_strategies}
          incident_id={reduxCurrentIncident?.id}
          ics_incident_objectives={ics_incident_objectives}
        />
      )}
      {isManageTacticsDialogOpen && (
        <ICSTacticsDialog
          show={isManageTacticsDialogOpen}
          onClose={() => setIsManageTacticsDialogOpen(false)}
          ics_tactics={ics_tactics}
          ics_incident_tactics={ics_incident_tactics}
          incident_id={reduxCurrentIncident?.id}
          ics_incident_strategies={ics_incident_strategies}
        />
      )}
      {isManageActionsDialogOpen && (
        <ICSActionsDialog
          show={isManageActionsDialogOpen}
          onClose={() => setIsManageActionsDialogOpen(false)}
          ics_actions={ics_actions}
          ics_incident_actions={ics_incident_actions}
          incident_id={reduxCurrentIncident?.id}
          incident_roster={reduxRosterForIncident}
        />
      )}
      {isManageResourcesDialogOpen && (
        <ICSResourcesDialog
          show={isManageResourcesDialogOpen}
          onClose={() => setIsManageResourcesDialogOpen(false)}
          ics_resources={ics_resources}
          ics_incident_resources={ics_incident_resources}
          incident_id={reduxCurrentIncident?.id}
        />
      )}

      {isManageMedicalAidStationsDialogOpen && (
        <ICSMedicalAidStationsDialog
          show={isManageMedicalAidStationsDialogOpen}
          onClose={() => setIsManageMedicalAidStationsDialogOpen(false)}
          ics_medical_aid_stations={ics_medical_aid_stations}
          ics_incident_medical_aid_stations={ics_incident_medical_aid_stations}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
      {isManageMedicalTransportationDialogOpen && (
        <ICSMedicalTransportationDialog
          show={isManageMedicalTransportationDialogOpen}
          onClose={() => setIsManageMedicalTransportationDialogOpen(false)}
          ics_medical_transportation={ics_medical_transportation}
          ics_incident_medical_transportation={ics_incident_medical_transportation}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
      {isManageHospitalsDialogOpen && (
        <ICSHospitalsDialog
          show={isManageHospitalsDialogOpen}
          onClose={() => setIsManageHospitalsDialogOpen(false)}
          ics_hospitals={ics_hospitals}
          ics_incident_hospitals={ics_incident_hospitals}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
      {isManageWorkAssignmentsDialogOpen && (
        <ICSWorkAssignmentsDialog
          show={isManageWorkAssignmentsDialogOpen}
          onClose={() => setIsManageWorkAssignmentsDialogOpen(false)}
          ics_work_assignments={ics_work_assignments}
          ics_incident_work_assignments={ics_incident_work_assignments}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
      {isManageComsDialogOpen && (
        <ICSComsDialog
          show={isManageComsDialogOpen}
          onClose={() => setIsManageComsDialogOpen(false)}
          ics_coms={ics_coms}
          ics_incident_coms={ics_incident_coms}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
    </>
  );
}
