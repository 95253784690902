import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import { createIcsTactic, updateIcsTactic } from 'slices/ics_tacticsSlice';

import { updateIcsIncidentTactic } from 'slices/ics_incidentTacticsSlice';

export default function NewICSTacticDialog({
  show,
  setEditTacticData,
  editTacticData,
  modalType,
  setModalType,
  onClose,
  incident_id,
  ics_strategies,
  ics_incident_strategies,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const strategiesToUse =
    (!!incident_id && ics_incident_strategies) || ics_strategies;

  const [isLoaded, setIsLoaded] = useState(true);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [strat, setStrat] = useState(
    strategiesToUse.find((s) => s.strategy_id === editTacticData?.strategy_id)
  );
  const [descriptionError, setDescriptionError] = useState({
    hasError: true,
    message: '',
    touched: false,
  });

  const [tacticId, setTacticId] = useState('');
  const reduxDispatch = useDispatch();
  const isIncidentTacticsLoaded = useSelector((state) => {
    return state.app.isIncidentTacticsLoaded;
  });
  const isAddIncidentTacticsLoaded = useSelector((state) => {
    return state.app.isAddIncidentTacticsLoaded;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (!isLoaded && isIncidentTacticsLoaded && isAddIncidentTacticsLoaded) {
      onClose();
      setModalType('New');
      setEditTacticData(undefined);
    }
  }, [isIncidentTacticsLoaded, isAddIncidentTacticsLoaded, isLoaded, onClose]);

  useEffect(() => {
    errorCheck(descriptionError);
  }, [description]);

  const errorCheck = (descriptionErrorNew) => {
    if (descriptionErrorNew.touched) {
      if (!description || description === '') {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: true,
          message: '*Description is required',
        });
      } else {
        setDescriptionError({
          ...descriptionErrorNew,
          hasError: false,
          message: '',
        });
      }
    }
  };
  const onTouched = () => {
    const descriptionErrorNew = { ...descriptionError, touched: true };
    setDescriptionError(descriptionErrorNew);
    errorCheck(descriptionErrorNew);
  };

  useEffect(() => {
    if (modalType === 'New') {
      setTacticId(generateUUID());
    } else if (modalType === 'Edit') {
      setTacticId(editTacticData.id);
      setValue('description', editTacticData.description);
      setValue('name', editTacticData.name);
    }
  }, [modalType]);

  const onNewTacticDataSubmit = (data) => {
    let payload = {
      id: tacticId,
      tactic_id: tacticId,
      name: data.name,
      description: data.description,
      incident_id: incident_id,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      archived: false,
      strategies: strat.map((s) => s.value.strategy_id ?? s.value.id)
    };
    if (modalType === 'New') {
      reduxDispatch(createIcsTactic(payload));
      onClose();
    } else if (modalType === 'Edit') {
      payload.id = editTacticData?.id;
      if (!incident_id) {
        reduxDispatch(updateIcsTactic(payload.id, payload));
      } else if (!!incident_id) {
        reduxDispatch(
          updateIcsIncidentTactic(incident_id, payload.id, payload)
        );
      }

      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{modalType} Tactic</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewTacticDataSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">ID</label>
            <StylishNewInput value={tacticId} readOnly disabled />
          </div>
          <div className="m-0">
            <label className="form-label">Name:</label>
            <Controller
              control={control}
              name="name"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.description && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Description:</label>
            <Controller
              control={control}
              name="description"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => onChange(e.target.value)}
                  value={value || ''}
                  maxLength={300}
                />
              )}
            />
            {errors.description && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Strategy:</label>
            {(!!editTacticData && (
              <div>
                <label>{strat?.name}</label>
              </div>
            )) || (
              <StylishNewSelect
                options={strategiesToUse.map((s) => {
                  return {
                    label: s.name,
                    value: s,
                  };
                })}
                defaultValue={strat}
                onChange={(e) => {
                  setStrat(e);
                }}
                placeholder={strat?.name || 'Select Strategy (required)'}
                disabled={modalType === 'Edit'}
                isMulti={true}
              />
            )}
            <div>
              <i>(Strategy cannot be changed after creation)</i>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton
              className="button--primary"
              type="submit"
              disabled={!strat}
            >
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
