import React from 'react';
import { ListGroup } from 'react-bootstrap';
import Collapse from '@mui/material/Collapse';
import { useFeatureFlag } from '../../../utils/featureFlags';
import {
  ListGroupDropdownItem,
  ListGroupNavItem,
} from './ListGroupNavComponents';

const MediaNavPanel: React.FC<{ navBack: () => void }> = ({ navBack }) => {
  const [postsOpen, setPostsOpen] = React.useState(false);
  const mediaFeatureFlag = useFeatureFlag('MEDIA_ANALYTICS');
  const noiseFeatureFlag = useFeatureFlag('MEDIA_NOISE_MODULE');
  const isSubscriptionFlag = useFeatureFlag('SUBSCRIPTION_MANAGEMENT');

  return (
    <nav aria-label="secondary sidebar" className="second-panel">
      <div className="second-panel-back">
        <button
          className="button button--icon border-0 bg-bglight"
          onClick={navBack}
        >
          <i className="material-symbols-outlined">arrow_back</i>
        </button>
        <div>Media</div>
      </div>
      <ListGroup>
        <div className="list-group-title">Media</div>

        <ListGroupNavItem
          to="/prepare/dashboard/media"
          label="Dashboard"
          icon="dashboard"
          end
        />
        <ListGroupDropdownItem
          onClick={() => setPostsOpen(!postsOpen)}
          open={postsOpen}
          icon="post_add"
          label="Posts"
          isLocked={isSubscriptionFlag}
        />
        <Collapse in={postsOpen}>
          <ListGroupNavItem
            to="/prepare/dashboard/media/posts"
            label="View Posts"
            secondary
          />
          <ListGroupNavItem
            to="/prepare/dashboard/media/calendar"
            label="Calendar"
            secondary
          />
          {mediaFeatureFlag ? (
            <ListGroupNavItem
              to="/prepare/dashboard/media/analytics"
              label="Analytics"
              secondary
            />
          ) : null}
          {noiseFeatureFlag ? (
            <ListGroupNavItem
              to="/prepare/dashboard/media/noise"
              label="Noise"
              secondary
            />
          ) : null}
        </Collapse>
        <ListGroupNavItem
          to="/prepare/dashboard/media/profiles"
          icon="group"
          label="Profiles"
        />
        <ListGroupNavItem
          to="/prepare/dashboard/media/personas"
          icon="settings_accessibility"
          label="Personas"
        />
        <ListGroupNavItem
          to="/prepare/dashboard/media/networks"
          icon="bring_your_own_ip"
          label="Networks"
          isLocked={isSubscriptionFlag}
        />
        <ListGroupNavItem
          to="/prepare/dashboard/media/sites"
          icon="share"
          label="Sites"
          isLocked={isSubscriptionFlag}
        />
      </ListGroup>
    </nav>
  );
};

export default MediaNavPanel;
