export const AVAILABLE_ROLES = [
  'Incident Commander',
  'Qualified Individual',
  'Legal Officer',
  'Public Information Officer',
  'Safety Officer',
  'Liaison officer',
  'Medical Unit Leader',
  'Operations Section Chief',
  'Planning Section Chief',
  'Logistics Section Chief',
  'Finance Section Chief',
  'Communications Unit Leader',
];