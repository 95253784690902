import { Modal } from 'react-bootstrap';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import React from 'react';
import {
  endLoading,
  startLoading,
} from '../../reducers/loading/loading.action';
import { editGroup } from '../../actions/roleActions';
import { useDispatch, useSelector } from 'react-redux';
import StylishNewInput from '../DesignSystems/New/StylishNewInput';

type EditGroupModalProps = {
  group: any;
  onClose: () => void;
  startDelete: () => void;
  show: boolean;
};
const EditGroupModal: React.FC<EditGroupModalProps> = ({
  group,
  onClose,
  startDelete,
  show,
}) => {
  const dispatch = useDispatch();

  const currentlySelectedGroup = useSelector(
    (s: any) => s.app.currentlySelectedGroup
  );
  const RBACPermissions = useSelector((state: any) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const {
    resetField,
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const onEditGroupSubmit: SubmitHandler<any> = async ({
    editGroupName,
    default_sender_email,
  }) => {
    dispatch(startLoading());
    const existingGroup = group;
    existingGroup.key = editGroupName;
    existingGroup.group_guid = group.value;
    existingGroup.group_name = group.key;
    existingGroup.daily_digest_team = group.dailyDigestTeam;
    existingGroup.default_sender_email = default_sender_email;

    await dispatch(editGroup(existingGroup));
    dispatch(endLoading());
    onClose();
  };

  const onCloseRequest = () => {
    resetField("editGroupName")
    resetField("default_sender_email")
    onClose()
  }

  return (
    <Modal show={show} onHide={onCloseRequest} centered>
      <form onSubmit={handleSubmit(onEditGroupSubmit)}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Edit Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Name</label>
            {/* @ts-ignore */}
            <Controller
              control={control}
              name="editGroupName"
              rules={{ required: true }}
              defaultValue={currentlySelectedGroup.group_name}
              render={({ field: { onChange, value } }) => (
                // @ts-ignore
                <StylishNewInput
                  type="text"
                  onChange={(e: any) => onChange(e.target.value)}
                  value={value}
                />
              )}
            />
            {errors.editGroupName?.type === 'required' && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
            {errors.editGroupName?.type === 'custom' && (
              <span className="form-text form-error">
                This Group name is in use. Please enter a new one.
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Organization From Email</label>
            <Controller
              control={control}
              name="default_sender_email"
              rules={{
                required: true,
                pattern: {
                  value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-z]+)$/,
                  message: 'Invalid email address',
                },
              }}
              defaultValue={currentlySelectedGroup.default_sender_email}
              render={({ field: { onChange, value } }) => (
                // @ts-ignore
                <StylishNewInput
                  type="text"
                  onChange={(e: any) => onChange(e.target.value)}
                  value={value}
                />
              )}
            />
            {errors.default_sender_email?.type === 'required' && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
            {errors.default_sender_email?.type === 'pattern' && (
              <span className="form-text form-error">
                Please Enter a Valid Email
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              type="button"
              className="button--error"
              onClick={startDelete}
              disabled={!RBACPermissions['Open or Close Group']}
            >
              Delete this Group
            </StylishNewButton>
            <StylishNewButton
              type="button"
              className="button--secondary"
              onClick={onCloseRequest}
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton
              type="submit"
              className="button--primary"
              disabled={isSubmitted && !isValid}
            >
              Save Group
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditGroupModal;
