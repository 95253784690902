import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import AddEditPlanMeetingsDialog from '../Prepare/ExerciseDetails/PlanMeetings/BkAddEditPlanMeetingsDialog';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
export default function ViewEventCalendarDialog({
  onClose,
  show,
  selectedIncidentMember,
  start,
  end,
}) {
  const dispatch = useDispatch();
  const { timezone } = useSelector((state) => state.prepare.workspace);

  const startDate = moment(selectedIncidentMember?.start).format('DD MMM YYYY');
  const endDate = moment(selectedIncidentMember?.end).format('DD MMM YYYY');
  const [
    showEventMeetingsAddEditDialog,
    setShowEventMeetingsAddEditDialog,
  ] = useState(false);
  const [deleteEvent, setDeleteEvent] = useState(false);

  const onEditEvent = (afterSave) => {
    setShowEventMeetingsAddEditDialog(false);
    onClose(afterSave);
  };

  const onDeleteClose = (afterSave) => {
    setDeleteEvent(false);
    onClose(afterSave);
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMeetings, {
        id: selectedIncidentMember?.id,
      });
      setDeleteEvent(false);
      onClose('Deleted');
      toast.success('Plan Meeting deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Event Meeting</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="mb-1">{selectedIncidentMember?.title}</h5>
          {show?._def?.title.includes('Exercise Details') ? (
            <p className="m-0">
              {start}
              {timezone.split('/')[0]} - {end}
              {timezone.split('/')[0]}
            </p>
          ) : (
            <p className="m-0">
              {startDate}
              {timezone.split('/')[0]} - {endDate}
              {timezone.split('/')[0]}
            </p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <button
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </button>
            <button
              className="button button--tertiary button--reverse"
              type="button"
              onClick={() => setDeleteEvent(true)}
            >
              Delete
            </button>
            <button
              className="button button--primary"
              type="button"
              onClick={() => setShowEventMeetingsAddEditDialog(true)}
            >
              Edit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {showEventMeetingsAddEditDialog && (
        <AddEditPlanMeetingsDialog
          show={showEventMeetingsAddEditDialog}
          onClose={onEditEvent}
          dialogType={'Edit'}
          updateDialogData={selectedIncidentMember}
        />
      )}
      {deleteEvent && (
        <StylishConfirmDialog
          show={deleteEvent}
          onClose={onDeleteClose}
          dialogTitle={'Delete Event'}
          dialogContentType={'html'}
          dialogContent={`
              Deleting <span class="weight-700 break-word">${selectedIncidentMember?.title}</span> will remove all its occurance from this workspace.<span class="d-block pt-3">Are you sure you want to delete this Event ?</span>`}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
}
