// @ts-nocheck
import ReactDOM from 'react-dom/client';
import App from './App';
import { getStore } from './store/store';
import { Provider } from 'react-redux';
import { productionEnv } from './constants/debug';
import { isFirefox, isSafari } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import * as Msal from '@azure/msal-browser';
import ThemeProvider from './ThemeProvider';

// Third party styles
import './vendors.scss';

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import * as microsoftTeams from '@microsoft/teams-js';
import parseJWT from 'utils/parseJWT';
import { msalConfig } from 'components/Auth/MSALAuthObjects';
import { datadogRum } from '@datadog/browser-rum';
import { LoginPageButton } from 'LoginPageButton';

const datadogAppId = productionEnv && window.env.DATA_DOG_APP_ID;

const datadogClientToken = productionEnv
  && window.env.DATA_DOG_CLIENT_TOKEN;


if (!!datadogAppId && !!datadogClientToken) {
  
  console.log("initializing datadog")

  datadogRum.init({
    applicationId: datadogAppId,
    clientToken: datadogClientToken,
    site: 'datadoghq.com',
    service: 'pratus',
    env: 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: 'release/v2.36',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

}

const msalInstance = new Msal.PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
const isBrave = async () => {
  return (navigator.brave && (await navigator.brave.isBrave())) || false;
};


// IMPORTANT: Auth notes
// Auth is very complex with Microsoft and our Teams integration.  Following the steps of action is essential to understanding what is going on
// 1: Check if app is running in browser or Teams.
// 1.1: If teams, render login page with function to use SSO to log in to Teams
// 1.2: If not teams...
// 1.2.1: Check to see if any accounts are known to the browser with msalInstance.getAllAccounts()
// 1.2.1.1: If valid accounts, add account as loginHint to ssoRequest, attempt acquireTokenSilent -> If no accounts or request fail, render login button
// 1.2.2: Login button click => redirect

let teamsInitialized = false;

microsoftTeams.initialize(() => {
  teamsInitialized = true;
});

function initTeamsAuth() {
  var authTokenRequest = {
    successCallback: function (result) {
      renderAppInTeams(result);
    },
    failureCallback: function (error) {
      console.warn('Failure: ' + error);
    },
  };
  microsoftTeams.authentication.getAuthToken(authTokenRequest);
}

const auth0Domain = productionEnv
  ? window.env.AUTH_0_DOMAIN
  : process.env.REACT_APP_AUTH_0_DOMAIN;

const auth0ClientId = productionEnv
  ? window.env.AUTH_0_CLIENT_ID
  : process.env.REACT_APP_AUTH_0_CLIENT_ID;

const auth0Audience = productionEnv
  ? window.env.AUTH_0_AUDIENCE
  : process.env.REACT_APP_AUTH_0_AUDIENCE;

let providerConfig = {
  domain: auth0Domain,
  clientId: auth0ClientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: auth0Audience,
  },
  // useRefreshTokens: true,
  cacheLocation: 'memory',
};
setTimeout(async () => {
  let useRefreshTokens = (isSafari || isFirefox || await isBrave()) ? true : false;
  providerConfig['useRefreshTokens'] = useRefreshTokens;
  providerConfig['cacheLocation'] = useRefreshTokens ? 'localstorage' : 'memory';
  
  root.render(
    <Auth0Provider {...providerConfig}>
      <InitApp teamsInitialized={teamsInitialized} />
    </Auth0Provider>
  );
}, 250);

function InitApp({ teamsInitialized }) {
  const { isLoading, isAuthenticated, error, user } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <LoginPageButton isUsingTeams={teamsInitialized} error={error} />;
  }
  if (teamsInitialized) {
    initTeamsAuth();
    return <div>Loading...</div>;
  }
  if (isAuthenticated) {
    const finalAuthInfo = {
      teamsInitialized: teamsInitialized || false,
      account: {
        userName: user.email,
      },
      type: 'AUTH0',
    };
    if (!user.email_verified) {
      return <LoginPageButton isUsingTeams={teamsInitialized} user={user} />;
    }
    return (
      <DndProvider backend={HTML5Backend}>
        <Provider
          store={getStore({
            auth: finalAuthInfo,
          })}
        >
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </Provider>
      </DndProvider>
    );
  } else {
    return <LoginPageButton isUsingTeams={teamsInitialized} />;
  }
}

const renderAppInTeams = (authInfo) => {
  const parsedID = parseJWT(authInfo);
  const finalAuthInfo = {
    ...authInfo,
    account: {
      userName: parsedID['preferred_username'],
    },
  };
  finalAuthInfo['accessToken'] = authInfo;
  finalAuthInfo['teamsInitialized'] = true;
  finalAuthInfo['expiresOn'] = parsedID.expiresOn;
  finalAuthInfo['msalInstance'] = msalInstance;
  finalAuthInfo['type'] = 'MSAL';
  sessionStorage.setItem('accessToken', authInfo);
  sessionStorage.setItem('isUsingTeams', true);
  root.render(
    <DndProvider backend={HTML5Backend}>
      <Provider
        store={getStore({
          auth: finalAuthInfo,
        })}
      >
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Provider>
    </DndProvider>
  );
};
