import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';

import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import {
    fetchIcsIncidentResources
} from './ics_incidentResourcesSlice'

import {
    getUserGuid,
    getGroupGuid
} from 'slices/sliceHelpers'

// Slice
const ics_resourcesSlice = createSlice({
    name: 'ics_resources',
    initialState: {
        ics_resources: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        createIcsResourceSuccess: (state, action) => {
            state.ics_resources.push(action.payload);
        },
        fetchIcsResourcesSuccess: (state, action) => {
            state.ics_resources = action.payload;
        },
        updateIcsResourceSuccess: (state, action) => {
            const index = state.ics_resources.findIndex((res) => res.id === action.payload.id);
            state.ics_resources[index] = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const {
    createIcsResourceSuccess,
    fetchIcsResourcesSuccess,
    updateIcsResourceSuccess,
    setError
} = ics_resourcesSlice.actions;

export default ics_resourcesSlice.reducer;

export const createIcsResource = (data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.post(`${config.apiGateway.ics_resources}`, {...data, user_guid});
        if(data.incident_id) {
            dispatch(fetchIcsIncidentResources(data.incident_id));
        }
        dispatch(fetchIcsResources(group_guid));
        dispatch(createIcsResourceSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const fetchIcsResources = (group_guid) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    try {
        const response = await axios.get(`${config.apiGateway.ics_resources}/${group_guid}`,{
            headers: { 'user_guid': user_guid }
        });
        dispatch(fetchIcsResourcesSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};

export const updateIcsResource = (id, data) => async (dispatch, getState) => {
    const user_guid = getUserGuid(getState);
    const group_guid = getGroupGuid(getState);
    try {
        const response = await axios.put(`${config.apiGateway.ics_resources}/${id}`, {...data, user_guid});
        dispatch(fetchIcsResources(group_guid));
        dispatch(updateIcsResourceSuccess(response.data));
    } catch (error) {
        toast.error(error.message, toastConfig);
        dispatch(setError(error.message));
    }
};
