import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import AddLocationDialog from './AddLocationDialog';
import { Type } from 'react-bootstrap-table2-editor';

export const MissionSystems = () => {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const { id: missionTimelineId } = params;

  const [locationList, setLocationList] = useState([]);
  const [availableSystems, setAvailableSystems] = useState([]);
  const [assignedSystems, setAssignedSystems] = useState([]);
  const [previousAssignedSystems, setPreviousAssignedSystems] = useState([]);
  const [tableValid, setTableValid] = useState([]);
  const [showAddLocationDialog, setShowAddLocationDialog] = useState(false);
  const [locationForRowId, setLocationForRowId] = useState(null);

  const columnsAvailableSystem = [
    {
      dataField: 'name',
      text: 'System',
      sort: false,
      attrs: { title: 'System' },
    },
    {
      dataField: 'category',
      text: 'Category',
      sort: false,
      attrs: { title: 'Category' },
    },
    {
      dataField: 'event_cell_type',
      text: 'Event Cell',
      sort: false,
      attrs: { title: 'Event Cell' },
      formatter: (_, row) => (
        <>
          {row?.event_cell_type === 'red_cell'
            ? 'Red Cell'
            : row?.event_cell_type === 'blue_cell'
            ? 'Blue Cell'
            : null}
        </>
      ),
    },
    {
      dataField: 'quantity',
      text: 'Qty Available',
      sort: false,
      attrs: { title: 'Qty Available' },
    },
    {
      dataField: 'provider',
      text: 'Provider',
      sort: false,
      attrs: { title: 'Provider' },
    },
    {
      dataField: 'Primary POC',
      text: 'Primary POC',
      sort: false,
      attrs: { title: 'Primary POC' },
      formatter: (_, row) => <>{row?.user_name || row?.user_email}</>,
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      attrs: { title: 'Action' },
      formatter: (_, row) => (
        <StylishNewButton
          className="button--action add"
          type="button"
          onClick={() => {
            setAssignedSystems((prev) => [
              ...prev,
              { msn_quantity: 0, prev_msn_quantity: 0, ...row },
            ]);
            setAvailableSystems((prev) => prev.filter((p) => p.id !== row.id));
            setTableValid((prev) => [...prev, true]);
          }}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
        </StylishNewButton>
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const columnsAssignedSystem = [
    {
      dataField: 'name',
      text: 'System',
      sort: false,
      attrs: { title: 'System' },
      editable: false,
    },
    {
      dataField: 'category',
      text: 'Category',
      sort: false,
      attrs: { title: 'Category' },
      editable: false,
    },
    {
      dataField: 'event_cell_type',
      text: 'Event Cell',
      sort: false,
      attrs: { title: 'Event Cell' },
      editable: false,

      formatter: (_, row) => (
        <>
          {row?.event_cell_type === 'red_cell'
            ? 'Red Cell'
            : row?.event_cell_type === 'blue_cell'
            ? 'Blue Cell'
            : null}
        </>
      ),
    },
    {
      dataField: 'quantity',
      text: 'Qty Available',
      sort: false,
      attrs: { title: 'Qty Available' },
      editable: false,
    },
    {
      dataField: 'msn_quantity',
      text: 'Qty for MSN Event',
      sort: false,
      attrs: { title: 'Qty for MSN Event' },
      validator: (newValue, row) => {
        const rowIndex = assignedSystems.findIndex((aS) => aS.id === row.id);
        const updatedTableValid = [...tableValid];
        newValue = Math.round(Number(newValue));
        if (isNaN(newValue)) {
          toast.error('MSN quantity should be a number!', toastConfig);
          updatedTableValid[rowIndex] = false;
          setTableValid(updatedTableValid);
          return false;
        } else if (parseInt(newValue) > parseInt(row.quantity)) {
          toast.error(
            'MSN quantity should be less than available quantity!',
            toastConfig
          );
          updatedTableValid[rowIndex] = false;
          setTableValid(updatedTableValid);
          return false;
        } else if (parseInt(newValue) < 0) {
          toast.error(
            'MSN quantity should be greater than or equal to 0!',
            toastConfig
          );
          updatedTableValid[rowIndex] = false;
          setTableValid(updatedTableValid);
          return false;
        }
        updatedTableValid[rowIndex] = true;
        setTableValid(updatedTableValid);

        return true;
      },
      formatter: (_, row) => (
        <StylishNewInput type="number" value={row?.msn_quantity} />
      ),
      // editorRenderer: (editorProps, value) => (
      //   <UpdateMSNQuantity {...editorProps} value={value} />
      // ),
    },
    {
      dataField: 'provider',
      text: 'Provider',
      sort: false,
      attrs: { title: 'Provider' },
      editable: false,
    },
    {
      dataField: 'Primary POC',
      text: 'Primary POC',
      sort: false,
      attrs: { title: 'Primary POC' },
      editable: false,

      formatter: (_, row) => <>{row?.user_name || row?.user_email}</>,
    },
    {
      dataField: 'Location/Launch Point',
      text: 'Location/Launch Point',
      sort: false,
      attrs: { title: 'Location/Launch Point' },
      editable: false,

      formatter: (_, row, rowIndex) => (
        <StylishNewSelect
          options={[{ label: 'Add New', value: 'add_new' }, ...locationList]}
          value={
            assignedSystems[rowIndex].location_name
              ? {
                  label: assignedSystems[rowIndex].location_name,
                  value: assignedSystems[rowIndex].location_id,
                }
              : undefined
          }
          placeholder="Select Mission Location"
          onChange={(e) => {
            if (e.value === 'add_new') {
              setLocationForRowId(row.id);
              setShowAddLocationDialog(true);
              return;
            } else {
              setLocationForRowId(null);
              setAssignedSystems((prev) =>
                prev.map((data) => {
                  if (data.id === row.id) {
                    return {
                      ...row,
                      location_id: e.value,
                      location_name: e.label,
                    };
                  } else {
                    return data;
                  }
                })
              );
            }
          }}
        />
      ),
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      attrs: { title: 'Action' },
      editable: false,

      formatter: (_, row, rowIndex) => (
        <StylishNewButton
          className="button--action remove"
          type="button"
          onClick={() => {
            setAvailableSystems((prev) => [...prev, row]);
            setAssignedSystems((prev) => prev.filter((p) => p.id !== row.id));
            setTableValid((prev) => prev.filter((p, idx) => idx !== rowIndex));
          }}
        >
          <i className="fa fa-minus" aria-hidden="true"></i>
        </StylishNewButton>
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const expandRowSystems = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="mb-4 col-md-4">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">Command</span>
                {row?.system_command || '-'}
              </p>
            </div>
            <div className="mb-4 col-md-4">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">Service Branch</span>
                {row?.service_branch || '-'}
              </p>
            </div>
            <div className="mb-4 col-md-4">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">
                  Capabilities and Limitations
                </span>
                {row?.capabilities_limitations || '-'}
              </p>
            </div>
            <div className="mb-4 col-md-12">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">
                  Data Link Connection(s)
                </span>
                {row?.data_link_connections || '-'}
              </p>
            </div>
            <div className="col-md-12">
              <span className="weight-600 d-block mb-2">Components</span>
              <table className="table no-filter">
                <thead>
                  <tr className="empty">
                    <th>Component Name</th>
                    <th>Component type</th>
                  </tr>
                </thead>
                <tbody>
                  {(!!row?.components?.length && (
                    <>
                      {row?.components?.map((i, idx) => (
                        <tr key={idx}>
                          <td title="Component Name">{i?.name || '-'}</td>
                          <td title="Component type">{i?.type || '-'}</td>
                        </tr>
                      ))}
                    </>
                  )) || (
                    <td className="text-center weight-600" colSpan={'2'}>
                      No Data Found
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const fetchLocationList = () => {
    try {
      dispatch(startLoading());
      Network.get(API.fetchMissionLocations, {
        workspaceId: selectedWorkspace,
        missionTimelineId,
      })
        .then((resp) => {
          setLocationList(
            resp.data.response.dataset.rows?.map((loc) => ({
              label: loc.name,
              value: loc.id,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error('Location list fetching failed!', toastConfig);
        })
        .finally(() => dispatch(endLoading()));
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  const fetchAvailableSystems = () => {
    try {
      dispatch(startLoading());
      Network.get(API.fetchMissionSystemList, {
        workspaceId: selectedWorkspace,
        missionTimelineId,
      })
        .then((resp) => {
          const assignSystems = resp.data.response.associated_system.map(
            (aS) => ({
              ...aS,
              mission_timeline_id: missionTimelineId,
              workspaceId: selectedWorkspace,
              system_id: aS.id,
              prev_msn_quantity: aS.msn_quantity,
            })
          );
          setTableValid(new Array(assignSystems.length).fill(true));
          setAssignedSystems(assignSystems);
          setPreviousAssignedSystems(assignSystems);
          setAvailableSystems(
            resp.data.response.not_associated_system.map((nAS) => ({
              ...nAS,
              mission_timeline_id: missionTimelineId,
              workspaceId: selectedWorkspace,
              system_id: nAS.id,
            }))
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error('Available Systems list fetching failed!', toastConfig);
        })
        .finally(() => dispatch(endLoading()));
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (selectedWorkspace && missionTimelineId) {
      fetchAvailableSystems();
    }
  }, [selectedWorkspace, missionTimelineId]);

  useEffect(() => {
    selectedWorkspace && fetchLocationList();
  }, [selectedWorkspace]);

  const onPrevious = () => {
    if (params.id) {
      navigate(
        `${PreparePath}/dashboard/plan/missions/timeline/${params.id}?page=1`
      );
    } else {
      navigate(`${PreparePath}/dashboard/plan/missions/timeline`);
    }
  };

  const handleSubmit = (type = '') => {
    let allowSubmit = true;

    assignedSystems.map((aS) => {
      if (!aS.location_id) {
        allowSubmit = false;
      }
    });
    const isTableValid = tableValid.every((v) => v === true);
    if (!isTableValid) {
      toast.error('1 or more invalid MSN quantity', toastConfig);
      return;
    }

    if (!allowSubmit) {
      toast.error('Location missing for Mission System!', toastConfig);
    } else {
      const submitData = {
        mission_system: assignedSystems.map((aS) => ({
          system_id: aS.system_id,
          mission_timeline_id: aS.mission_timeline_id,
          location_id: aS.location_id,
          msn_quantity: aS.msn_quantity,
          workspaceId: selectedWorkspace,
        })),
        mission_timeline_id: missionTimelineId,
        workspaceId: selectedWorkspace,
        ...compareData(),
      };

      try {
        dispatch(startLoading());
        Network.post(API.upsertMissionSystem, submitData)
          .then(() => {
            toast.success('Mission System upserted successfully!', toastConfig);
            if (type === 'exit') {
              navigate(`${PreparePath}/dashboard/plan/missions/timeline`);
            } else {
              navigate(
                `${PreparePath}/dashboard/plan/missions/timeline/${missionTimelineId}?page=3`
              );
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error('Failed to upsert Mission System!', toastConfig);
          })
          .finally(() => dispatch(endLoading()));
      } catch (error) {
        console.error(error);
        dispatch(endLoading());
      }
    }
  };

  const compareData = () => {
    let previous_data = {};
    let current_data = {};
    if (
      JSON.stringify(previousAssignedSystems) ===
      JSON.stringify(assignedSystems)
    ) {
      return { previous_data: null, current_data: null };
    }
    previous_data = previousAssignedSystems.map((pAS) => ({
      system_name: pAS.name,
      category: pAS.category,
      event_cell: pAS.event_cell_type === 'red_cell' ? 'Red Cell' : 'Blue Cell',
      quantity: pAS.quantity,
      msn_quantity: pAS.msn_quantity,
      location: pAS.location_name,
      provider: pAS.provider,
      primary_poc: pAS.user_name || pAS.user_email,
      service_branch: pAS.service_branch,
      capabilities_limitations: pAS.capabilities_limitations,
      data_link_connections: pAS.data_link_connections,
      components: pAS.components,
    }));
    current_data = assignedSystems.map((aS) => ({
      system_name: aS.name,
      category: aS.category,
      event_cell: aS.event_cell_type === 'red_cell' ? 'Red Cell' : 'Blue Cell',
      quantity: aS.quantity,
      msn_quantity: aS.msn_quantity,
      location: aS.location_name,
      provider: aS.provider,
      primary_poc: aS.user_name || aS.user_email,
      service_branch: aS.service_branch,
      capabilities_limitations: aS.capabilities_limitations,
      data_link_connections: aS.data_link_connections,
      components: aS.components,
    }));
    return { previous_data, current_data };
  };

  const onSaveAndExitClick = async () => {
    // const valid = await trigger();
    // if (valid) {
    //   const data = getValues();
    handleSubmit('exit');
    // }
  };
  const cellEditCallback = (oldValue, newValue, row, column) => {
    console.log({ newValue });
    if (column.dataField === 'msn_quantity') {
      setAssignedSystems((prev) =>
        prev.map((data) => {
          if (data.id === row.id) {
            const newUpdatedValue = Math.round(Number(newValue));
            return {
              ...row,
              msn_quantity:
                !isNaN(newValue) && newUpdatedValue <= Number(row.quantity)
                  ? parseInt(newValue)
                  : row.prev_msn_quantity,
            };
          } else {
            return data;
          }
        })
      );
    }
  };

  const handleLocationDialogClose = (data) => {
    if (data && data.value) {
      setLocationList([
        ...locationList,
        { label: data.label, value: data.value },
      ]);
      const previousAssignedSystems = [...assignedSystems];
      const updatedLocationIndex = previousAssignedSystems.findIndex(
        (as) => as.id === locationForRowId
      );

      if (updatedLocationIndex === -1) {
        return;
      }
      previousAssignedSystems[updatedLocationIndex] = {
        ...previousAssignedSystems[updatedLocationIndex],
        location_id: data.value,
        location_name: data.label,
      };
      setAssignedSystems(previousAssignedSystems);
      // setAssignedSystems((prev) =>
      //   prev.map((pdata) => {
      //     if (pdata.id === locationForRowId) {
      //       return {
      //         ...pdata,
      //         location_id: data.value,
      //         location_name: data.label,
      //       };
      //     } else {
      //       return pdata;
      //     }
      //   })
      // );

      // console.log('here');
      // fetchLocationList();
    } else {
      const previousAssignedSystems = [...assignedSystems];
      const updatedLocationIndex = previousAssignedSystems.findIndex(
        (as) => as.id === locationForRowId
      );

      if (updatedLocationIndex === -1) {
        return;
      }
      previousAssignedSystems[updatedLocationIndex] = {
        ...previousAssignedSystems[updatedLocationIndex],
        location_id: previousAssignedSystems[updatedLocationIndex]?.location_id
          ? previousAssignedSystems[updatedLocationIndex]?.location_id
          : undefined,
        location_name: previousAssignedSystems[updatedLocationIndex]
          ?.location_name
          ? previousAssignedSystems[updatedLocationIndex]?.location_name
          : 'Select Mission Location',
      };
      console.log(previousAssignedSystems);
      setAssignedSystems(previousAssignedSystems);
    }
    setShowAddLocationDialog(false);
    dispatch(endLoading());
  };

  return (
    <>
      <h4>Mission Systems</h4>
      <div className="form-block">
        <>
          <p className="weight-600">
            Assigned Systems: {assignedSystems?.length}
          </p>
          <StylishNewTable
            keyField="id"
            columns={columnsAssignedSystem}
            rows={assignedSystems || []}
            classes="mb-4 no-filter"
            headerClasses="empty"
            expandRow={expandRowSystems}
            cellEditCallback={cellEditCallback}
          />
        </>
        <p className="weight-600">
          Available Systems: {availableSystems?.length}
        </p>
        <StylishNewTable
          keyField="id"
          columns={columnsAvailableSystem}
          rows={availableSystems || []}
          classes="no-filter"
          headerClasses="empty"
          expandRow={expandRowSystems}
        />
      </div>
      <div className="button-group d-block d-md-flex mt-4">
        <StylishNewButton
          type={'button'}
          className={'button--primary button--reverse w-100 w-md-auto'}
          onClick={onPrevious}
        >
          Previous
        </StylishNewButton>
        <StylishNewButton
          type="button"
          className={
            'button--tertiary button--reverse w-100 w-md-auto ms-0 ms-md-auto mt-2 mt-md-0'
          }
          onClick={onSaveAndExitClick}
        >
          Save & Exit
        </StylishNewButton>
        <StylishNewButton
          type="button"
          className={
            'button--primary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0'
          }
          onClick={handleSubmit}
        >
          Next
        </StylishNewButton>
      </div>

      {(showAddLocationDialog && (
        <AddLocationDialog
          show={showAddLocationDialog}
          onClose={(data) => handleLocationDialogClose(data)}
          dialogType={'Add'}
        />
      )) ||
        null}
    </>
  );
};
