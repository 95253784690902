import React from 'react';
export const StylishNewCheckbox = ({
  className,
  disabled,
  checked,
  onClick,
  key,
  label,
  onLabelClick,
}) => {
  return (
    <>
      <div
        className={
          'form-checkbox align-items-center ' + (className ? className : '')
        }
      >
        {!!onLabelClick ? (
          <div className="form-checkbox__inner">
            <input
              type="checkbox"
              disabled={disabled}
              checked={checked}
              onClick={onClick}
              key={key}
              readOnly
            />
            <span className="icon"></span>
          </div>
        ) : (
          <>
            <input
              type="checkbox"
              disabled={disabled}
              checked={checked}
              onClick={onClick}
              key={key}
              readOnly
            />
            <span className="icon"></span>
          </>
        )}
        <label className="form-label" onClick={onLabelClick}>
          {label}
        </label>
      </div>
    </>
  );
};
