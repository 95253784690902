import { useEffect, useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconPlaceholder from 'assets/images/icon__exercise-details-empty.svg';
import { AddEditTestObjectiveDialog } from './AddEditTestObjectiveDialog';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useDispatch, useSelector } from 'react-redux';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { useForm } from 'react-hook-form';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";

export const TestObjectives = (props) => {
  const { selectedTab } = props;
  const [testObjectivesData, setTestObjectivesData] = useState([]);
  const [toBeEditedObjective, setToBeEditedObjective] = useState();
  const [dialogType, setDialogType] = useState('Add');
  const [deleteTestObjective, setDeleteTestObjective] = useState(false);
  const [deleteTestObjectiveTitle, setDeleteTestObjectiveTitle] = useState(
    null
  );

  const [
    showEEDTestObjectivesDialog,
    setShowEEDTestObjectivesDialog,
  ] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const { setValue } = useForm();
  const reduxUser = useSelector((state) => state.prepare.user);
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector((state) => {
    return state.prepare.workspace;
  });
  const { eventId } = useSelector((state) => state?.prepare?.planDashboard);

  const handleOnClose = (formData) => {
    if (formData?.PlanTestObjective?.id && dialogType === 'Edit') {
      getTestObjective();
    } else if (dialogType === 'Add' && formData?.id) {
      getTestObjective();
    }
    setToBeEditedObjective();
    setShowEEDTestObjectivesDialog(false);
    setDialogType('Add');
  };

  const columns = [
    {
      dataField: 'coic',
      text: 'COIC',
      sort: false,
      attrs: { title: 'COIC' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: false,
      attrs: { title: 'Description' },
    },
    {
      text: 'Action',
      dataField: 'action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Objective"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={() => handleDeleteObjective(row)}
          onEdit={() => handleEditObjective(row)}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          {(row?.associatedplantestobjective &&
            !!row?.associatedplantestobjective?.length &&
            row?.associatedplantestobjective?.map((obj, idx) => (
              <p
                className={`${
                  (idx === row?.associatedplantestobjective?.length - 1 &&
                    'm-0') ||
                  ''
                }`}
                key={obj?.id}
              >
                <strong className="weight-600 d-block mb-1">
                  {obj?.type} {obj?.item}
                </strong>
                {obj?.description || '-'}
              </p>
            ))) ||
            null}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const handleDeleteObjective = (row) => {
    setDeleteTestObjective(true);
    setDeleteTestObjectiveTitle(row);
  };

  const handleEditObjective = (row) => {
    setToBeEditedObjective(row);
    setShowEEDTestObjectivesDialog(true);
    setDialogType('Edit');
  };

  const getTestObjective = async () => {
    dispatch(startLoading());
    if (selectedWorkspace) {
      try {
        Network.get(API.getTestObjective, {
          workspaceId: selectedWorkspace,
          limit: perPage,
          page: page,
          planEventId: eventId,
        })
          .then((resp) => {
            setTestObjectivesData(resp.data.response.dataset);
            setTotal(resp?.data?.response?.total);
          })
          .catch(console.log)
          .finally(() => {
            dispatch(endLoading());
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteTestObjective, {
        id: deleteTestObjectiveTitle?.id,
        workspaceId: selectedWorkspace,
      });
      setDeleteTestObjectiveTitle(null);
      setDeleteTestObjective(false);
      getTestObjective();
      toast.success('Deleted  successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (selectedTab === 'test_objectives' && eventId) {
      getTestObjective();
    }
    setValue('page', {
      value: perPage,
      label: perPage + ' per page',
    });
  }, [reduxUser, selectedWorkspace, page, perPage, selectedTab, eventId]);

  useEffect(() => {
    setPage(1);
  }, [selectedTab, selectedWorkspace, eventId]);

  return (
    <>
      {(testObjectivesData && !!testObjectivesData?.length && (
        <>
          <div className="d-md-flex align-items-center mb-4">
            <StylishNewButton
              type="button"
              className={'button--primary w-100 w-md-auto mb-3 mb-md-0'}
              onClick={() => {
                setShowEEDTestObjectivesDialog(true), setDialogType('Add');
              }}
            >
              Create Objective
            </StylishNewButton>
            <StylishNewSelect
              name="page"
              options={[
                { value: '5', label: '5 per page' },
                { value: '15', label: '15 per page' },
                { value: '30', label: '30 per page' },
                { value: '50', label: '50 per page' },
              ]}
              defaultValue={{
                value: perPage,
                label: perPage + ' per page',
              }}
              onChange={(e) => {
                setPerPage(e?.value);
                setPage(1);
              }}
              // value={perPage}
              placeholder={'Select per page'}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
              isDisabled={false}
              classes="ms-auto"
            />
          </div>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={testObjectivesData}
            expandRow={expandRow}
            pagination
            page={page}
            perPage={perPage}
            total={total}
            onPageChange={(page) => setPage(page)}
            classes="no-filter"
            headerClasses="empty"
          />
        </>
      )) || (
        <div className="bg-gray-800 text-center px-4 py-5 p-md-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconPlaceholder} alt="" />
            </div>
            <p className="weight-600 mb-3">
              A fresh start. Create the first entry.
            </p>
            <div className="d-flex justify-content-center">
              <StylishNewButton
                type="button"
                className={'button--primary'}
                onClick={() => {
                  setShowEEDTestObjectivesDialog(true), setDialogType('Add');
                }}
              >
                Create Objective
              </StylishNewButton>
            </div>
          </div>
        </div>
      )}
      {(showEEDTestObjectivesDialog && (
        <AddEditTestObjectiveDialog
          show={showEEDTestObjectivesDialog}
          onClose={(data) => handleOnClose(data)}
          data={toBeEditedObjective}
          coicId={total + 1 || '1'}
          dialogType={dialogType}
        />
      )) ||
        null}
      {deleteTestObjective && (
        <StylishConfirmDialog
          show={deleteTestObjective}
          onClose={() => setDeleteTestObjective(false)}
          dialogTitle={'Delete Test Objective'}
          dialogContentType={'html'}
          dialogContent={`
              Deleting <span class="weight-700 break-word">${deleteTestObjectiveTitle?.description}</span> will remove all its occurance from this workspace.<span class="d-block pt-3">Are you sure you want to delete this Test Objective ?</span>`}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
};
