import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NewICSResourceDialog from 'components/ICS/NewICSResourceDialog';

import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

import ICSIncidentResource from './ICSIncidentResource';

import { updateIcsResource } from 'slices/ics_resourcesSlice';

import {
  createIcsIncidentResource,
  updateIcsIncidentResource,
} from 'slices/ics_incidentResourcesSlice';

export default function ICSResourcesDialog({
  show,
  onClose,
  ics_resources,
  ics_incident_resources,
  incident_id = null,
}) {
  const reduxDispatch = useDispatch();

  const [isNewResourceDialogOpen, setIsNewResourceDialogOpen] = useState(false);
  const [modalType, setModalType] = useState('New');
  const [editResourceData, setEditResourceData] = useState();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [archiveConfirmationData, setArchiveConfirmationData] = useState(null);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const [dialogResources, setDialogResources] = useState([
    ...ics_incident_resources,
  ]);

  useEffect(() => {
    setDialogResources(ics_incident_resources);
  }, [ics_incident_resources]);

  useEffect(() => {
    // Use to rerender
  }, [ics_resources]);

  const allColumns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      attrs: { title: 'Type' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
      sort: true,
      attrs: { title: 'Quantity' },
    },
    {
      dataField: 'archived',
      text: 'Archived',
      sort: true,
      attrs: { title: 'Archived' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <ICSIncidentResource
            row={row}
            ics_resources={ics_resources}
            ics_incident_resources={ics_incident_resources}
          />
          {(!incident_id ||
            !!ics_incident_resources.find(
              (ir) => ir.resource_id === row.id
            )) && (
            <div className="button-group">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onArchive(row)}
              >
                {row.archived ? `Unarchive` : `Archive`}
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: ics_resources
      .filter((r) => {
        const incRes = ics_incident_resources.find(
          (ir) => ir.resource_id === r.id && !ir.archived
        );
        return !!incRes;
      })
      .map((r) => r.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex) => {
      const existingIncidentResource = ics_incident_resources.find(
        (ir) => ir.resource_id === row.id
      );
      if (existingIncidentResource) {
        reduxDispatch(
          updateIcsIncidentResource(incident_id, existingIncidentResource.id, {
            ...existingIncidentResource,
            archived: !existingIncidentResource.archived,
          })
        );
      } else {
        // Create a new incident resource
        const incidentResourceData = {
          resource_id: row.id,
          name: row.name,
          description: row.description,
          quantity: 0,
          user_guid: row.user_guid,
          incident_id: incident_id,
          group_guid: reduxCurrentlySelectedGroup.group_guid,
          archived: false,
        };
        reduxDispatch(
          createIcsIncidentResource(incident_id, incidentResourceData)
        );
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setDialogResources(rows) : setDialogResources([]);
    },
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewResourceDialogOpen(true);
    setEditResourceData({
      ...row,
      versions: ics_incident_resources.find((ir) => ir.resource_id === row.id)
        ?.versions,
    });
  };

  const onArchive = (row) => {
    setShowConfirmDialog(true);
    setArchiveConfirmationData(row);
  };

  const onConfirmedArchiveActionData = () => {
    const payload = {
      ...archiveConfirmationData,
      incident_id,
      archived: !archiveConfirmationData.archived,
    };

    if (!!incident_id) {
      reduxDispatch(
        updateIcsIncidentResource(
          incident_id,
          archiveConfirmationData.id,
          payload
        )
      );
    } else {
      reduxDispatch(updateIcsResource(archiveConfirmationData.id, payload));
    }

    setShowConfirmDialog(false);
    setArchiveConfirmationData(null);
  };

  const onNewResource = () => {
    setModalType('New');
    setIsNewResourceDialogOpen(true);
  };

  const tableRows = ics_resources.map((r) => {
    const incRes = ics_incident_resources.find((ir) => ir.resource_id === r.id);
    if (incRes) {
      return {
        ...incRes.versions[0],
        id: r.id,
      };
    } else {
      return r;
    }
  });

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white" className="pb-0">
        <Modal.Title>Select Incident Resources</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="weight-500 mb-4">
          {reduxCurrentlySelectedGroup?.group_name}
        </p>
        <StylishNewButton
          type="button"
          className="button--primary mb-4"
          onClick={onNewResource}
        >
          New Resource
        </StylishNewButton>
        <div>
          <label>
            {!!incident_id && (
              <>
                Select to add to incident. De-select to archive. Re-select to
                un-archive.
              </>
            )}
          </label>
        </div>
        <StylishNewTable
          keyField={'id'}
          rows={tableRows}
          columns={allColumns}
          expandRow={expandRow}
          selectRow={(!!incident_id && selectRow) || undefined}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {isNewResourceDialogOpen && (
        <NewICSResourceDialog
          show={isNewResourceDialogOpen}
          setEditResourceData={setEditResourceData}
          setModalType={setModalType}
          editResourceData={editResourceData}
          modalType={modalType}
          onClose={() => setIsNewResourceDialogOpen(false)}
          incident_id={incident_id}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Archive Confirmation'}
          dialogContent={'Are you sure you want to archive this Resource?'}
          dialogButtonText={'Archive'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onConfirmedArchiveActionData}
        />
      )}
    </Modal>
  );
}
