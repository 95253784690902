import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { FormConfigs } from 'components/FormBuilder/FormTemplates/FormConstants/FormConfigs';
import FormWorkflow from './FormTemplates/FormComponents/FormWorkflow';
import { exportFormToPDF } from 'actions/formActions';
import {
  flattenFormSectionValidationMessages,
  getFormSectionValidationMessages,
} from 'components/FormBuilder/FormTemplates/FormValidations/FormFieldValidationFunctions';
import getCurrentUserIncidentSeat from './FormTemplates/FormFieldAutofillFunctions/getCurrentUserIncidentSeat';
import IAPForm201 from './IAPForm201';
import IAPForm204 from './IAPForm204';
import { IAPForm206 } from './IAPForm206';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { isBrowser } from 'react-device-detect';
import IAPForm202 from './IAPForm202';
import { IAPForm213RR } from './IAPForm213RR';
import { IAPForm205A } from './IAPForm205A';
import { IAPForm208 } from './IAPForm208';
import IAPCoverSheet from './IAPCoverSheet';
import { IAPForm205 } from './IAPFORM205';

import { uploadFileToTeams } from 'actions/teamsActions';

import validateSubscription from 'utils/validateSubscription';
import { toastConfig } from 'assets/data/config';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';

export default function FormCollectionInterface({ formCollection, onClose }) {
  const [showPDFPreview, setShowPDFPreview] = useState(false);
  const [showPDFPreviewNewTab, setShowPDFPreviewNewTab] = useState(false);
  const [showPDFPreviewMobile, setShowPDFPreviewMobile] = useState(false);
  const [showPDFPreviewTeams, setShowPDFPreviewTeams] = useState(false);
  const reduxDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const newUI = useNewUIFlag();

  const reduxUserSubscriptionInfo = useSelector((state) => {
    return state.app.userSubscriptionInfo;
  });

  const [
    isEditOperationalPeriodOpen,
    setIsEditOperationalPeriodOpen,
  ] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [formConfig, setFormConfig] = useState(
    FormConfigs.find((fc) => fc.formname === formCollection?.formname)
  );

  useEffect(() => {
    if (
      !!reduxUpsertedFormRecord &&
      !!formCollection &&
      formCollection.collection_id === reduxUpsertedFormRecord.collection_id
    ) {
      setForm({
        ...reduxUpsertedFormRecord,
        record_state:
          reduxUpsertedFormRecord.record_state ||
          formConfig.form_states[0].state_name,
      });
    } else if (!!formCollection) {
      setForm({
        ...formCollection.collection_form_records[
          formCollection.collection_form_records.length - 1
        ],
        record_state:
          formCollection.collection_form_records[
            formCollection.collection_form_records.length - 1
          ].record_state || formConfig.form_states[0].state_name,
      });
    }
  }, [formCollection]);

  const [form, setForm] = useState({
    ...formCollection.collection_form_records[
      formCollection.collection_form_records.length - 1
    ],
    record_state:
      formCollection.collection_form_records[
        formCollection.collection_form_records.length - 1
      ].record_state || formConfig.form_states[0].state_name,
  });

  useEffect(() => {
    setFormConfig(
      FormConfigs.find((fc) => fc.formname === formCollection.formname)
    );
  }, [form]);

  const [formRecordVersionSelected, setFormRecordVersionSelected] = useState();

  const user = useSelector((state) => {
    return state.app.user;
  });

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const isFetchWorkflowsLoaded = useSelector((state) => {
    return state.app.isFetchWorkflowsLoaded;
  });

  const [isLoaded, setIsLoaded] = useState(true);

  const isUpsertFormRecordLoaded = useSelector((state) => {
    return state.app.isUpsertFormRecordLoaded;
  });

  const exportedFormsToPDF = useSelector((state) => {
    return state.app.exportedFormsToPDF;
  });

  const isExportFormToPDFLoaded = useSelector((state) => {
    return state.app.isExportFormToPDFLoaded;
  });

  const reduxFormRecords = useSelector((state) => {
    return state.app.formRecords;
  });

  const reduxUpsertedFormRecord = useSelector((state) => {
    return state.app.upsertedFormRecord;
  });

  const isUploadFileToTeamsLoaded = useSelector((state) => {
    return state.app.isUploadFileToTeamsLoaded;
  });

  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const tenantId = useSelector((state) => {
    return state.app.tenantId;
  });

  const isGenerateTeamsSiteLoaded = useSelector((state) => {
    return state.app.isGenerateTeamsSiteLoaded;
  });

  const DICEstate = useSelector((state) => state);
  const user_incident_role = getCurrentUserIncidentSeat(DICEstate);

  const state_obj =
    !!formConfig &&
    formConfig.form_states.find((s) => s.state_name === form.record_state);
  const state_instruction = !!state_obj && state_obj.state_instruction;

  // Find transition where either the role can execute the previous transition AND it's repeatable, or just the regular transition
  let currentTransition;
  let repeatTransition = formConfig.form_state_transitions.find(
    (fst) =>
      fst.to === form.record_state &&
      !!fst.transition_conditions &&
      !!fst.transition_conditions.repeatable &&
      !!fst.transition_conditions.valid_seats &&
      !!fst.transition_conditions.valid_seats.find(
        (seat) => seat === user_incident_role || seat === '*'
      )
  );
  let userCanRepeatTransition = false;
  if (!!repeatTransition) {
    userCanRepeatTransition = true;
    currentTransition = repeatTransition;
  } else {
    currentTransition = formConfig.form_state_transitions.find(
      (fst) => fst.from === form.record_state
    );
  }

  // Data pertinent to the form itself
  const [formRecordData, setFormRecordData] = useState();

  // Form record metadata
  const formId = useRef(form.id || generateUUID());
  const collectionId = useRef(form.collection_id || generateUUID());

  const [isWorkflowOpen, setIsWorkflowOpen] = useState(false);

  function isFormReadyToSubmit(formRecordData) {
    // Block any further action if we are at the "end" state, meaning no transition exists where the current state is a "from"
    if (!currentTransition) {
      return false;
    } else {
      // Valid if current user's seat is allowed for transition and all required sections are valid, ie no validation messages
      return (
        !!currentTransition &&
        !!currentTransition.transition_conditions.valid_seats.find(
          (seat) => seat === user_incident_role || seat === '*'
        ) &&
        !!(
          flattenFormSectionValidationMessages(
            currentTransition.transition_conditions.valid_sections.map(
              (section) => {
                return getFormSectionValidationMessages(
                  formRecordData,
                  section,
                  formConfig
                );
              }
            )
          ).filter((s) => !!s).length === 0
        )
      );
    }
  }

  function onFormRecordDataUpdate(formRecordData) {
    setFormRecordData(formRecordData);
  }

  function createForm() {
    let editedForm = { ...form };
    editedForm['id'] = { ...formId }.current;
    editedForm['formname'] = formCollection.formname;
    editedForm['created_by_user'] = user.user_guid;
    editedForm['incident_id'] = reduxCurrentIncident.id;
    editedForm['group_guid'] = reduxCurrentlySelectedGroup.group_guid;
    editedForm['record_data'] = formRecordData;
    editedForm['collection_id'] = { ...collectionId }.current;
    return editedForm;
  }

  const handleSubmitFormClick = () => {
    alert('This feature is still a prototype. Thanks for checking it out!');
  };

  function formRecordSelected(record_id) {
    const f = formCollection.collection_form_records.find(
      (fr) => fr.id === record_id
    );
    setForm(f);
    setFormRecordData(f.record_data);
    setFormRecordVersionSelected(f);
  }

  const currentFormIsMostRecentForm =
    formCollection.collection_form_records[
      formCollection.collection_form_records.length - 1
    ].id === form.id;

  const incidentHasValidOP =
    !!reduxCurrentIncident &&
    !!reduxCurrentIncident.settings &&
    !!reduxCurrentIncident.settings.operationalPeriod &&
    !!reduxCurrentIncident.settings.operationalPeriod
      .operationalPeriodInterval &&
    !!reduxCurrentIncident.settings.operationalPeriod
      .operationalPeriodStartDateTime;

  const handleReloadPDFPreview = (force) => {
    if (showPDFPreview || force) {
      reduxDispatch(
        exportFormToPDF(
          createForm(),
          FormConfigs.find((fc) => fc.formname === formCollection.formname)
        )
      );
    }
  };

  useEffect(() => {
    !isLoaded ||
    !isExportFormToPDFLoaded ||
    !isUpsertFormRecordLoaded ||
    !isFetchWorkflowsLoaded
      ? reduxDispatch(startLoading())
      : reduxDispatch(endLoading());
  }, [
    isLoaded,
    isExportFormToPDFLoaded,
    isUpsertFormRecordLoaded,
    isFetchWorkflowsLoaded,
  ]);

  const b64toBlob = (b64Data, contentType) => {
    b64Data = b64Data.replace(/^[\w\d;:\/]+base64\,/g, '');
    const byteArray = Uint8Array.from(
      atob(b64Data)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    return new Blob([byteArray], { type: 'application/pdf' });
  };

  useEffect(() => {
    if (
      showPDFPreviewMobile &&
      !!exportedFormsToPDF &&
      !!exportedFormsToPDF.find((f) => f.id === { ...formId }.current)
    ) {
      const pdfContent = URL.createObjectURL(
        b64toBlob(
          exportedFormsToPDF.find((f) => f.id === { ...formId }.current)
            .PDFData,
          'application/pdf'
        )
      );
      //window.open(pdfContent, '_blank');
    }
  }, [showPDFPreviewMobile, exportedFormsToPDF]);

  useEffect(() => {
    if (
      showPDFPreviewNewTab &&
      sessionStorage['isUsingTeams'] === 'true' &&
      !!exportedFormsToPDF &&
      exportedFormsToPDF?.length
    ) {
      const pdfContent = URL.createObjectURL(
        b64toBlob(
          exportedFormsToPDF.find((f) => f.id === { ...formId }.current)
            ?.PDFData,
          'application/pdf'
        )
      );
      setShowPDFPreviewNewTab(false);
      //window.open(pdfContent, '_blank');
    }
  }, [showPDFPreviewNewTab, exportedFormsToPDF]);

  useEffect(() => {
    setShowPDFPreview(false);
  }, [location]);

  const uploadFileToTeamsClicked = () => {
    if (!isGenerateTeamsSiteLoaded) {
      toast.success(
        'Creating team site is in progress. Please check back later.',
        toastConfig
      );
      return;
    }
    const formToExport = createForm();
    reduxDispatch(
      uploadFileToTeams({
        tenant_Id: tenantId,
        incident_id: reduxCurrentIncident.id,
        file_name: formToExport.formname + '.PDF',
        teams_id: reduxCurrentIncident.teams_id,
        sharepoint_location: reduxCurrentIncident.sharepoint_location,
        channel_id: reduxCurrentIncident.channel_id,
        form: formToExport,
        formConfig: FormConfigs.find(
          (fc) => fc.formname === formCollection.formname
        ),
      })
    );
  };

  let subscriptionValidated = true;
  if (
    featureFlags.includes('STRIPE') &&
    !validateSubscription(user, reduxUserSubscriptionInfo)
  ) {
    subscriptionValidated = false;
  }

  return (
    <>
      {!newUI && (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                onClick={() => {
                  if (
                    !!location.state &&
                    location.state.form_record_collection &&
                    location.state.workflow
                  ) {
                    navigate('/workflows', {
                      form_record_collection:
                        location.state.form_record_collection,
                      workflow: location.state.workflow,
                    });
                  } else {
                    navigate('/incident_management');
                    setForm({});
                    //onClose();
                  }
                }}
              >
                <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                Back
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Incident Management
            </li>
          </ol>
        </nav>
      )}

      {newUI ? (
        <PageTitle
          title={getFormTitle(formConfig.formname)}
          parents={getFormParents(formConfig.formname)}
        >
          {showPDFButton(formConfig.formname) ? (
            <StylishNewButton
              type="button"
              className="button--secondary"
              onClick={() => {
                if (!showPDFPreview) {
                  handleReloadPDFPreview(true);
                }
                if (sessionStorage['isUsingTeams'] !== 'true' && isBrowser) {
                  setShowPDFPreview(!showPDFPreview);
                } else {
                  setShowPDFPreviewMobile(true);
                }
                if (sessionStorage['isUsingTeams'] === 'true') {
                  setShowPDFPreviewNewTab(true);
                  setShowPDFPreviewTeams(true);
                }
              }}
            >{`${
              (showPDFPreview || showPDFPreviewMobile) &&
              sessionStorage['isUsingTeams'] !== 'true'
                ? 'Hide'
                : 'Show'
            } PDF Preview`}</StylishNewButton>
          ) : null}
          {showTeamsButtons(formConfig.formname) ? (
            <>
              {!!tenantId &&
              !!isUploadFileToTeamsLoaded &&
              !!featureFlags.includes('GENERATE_TEAM_SITE') &&
              !!reduxCurrentIncident.teams_id &&
              !!reduxCurrentIncident.sharepoint_location &&
              !!reduxCurrentIncident.channel_id &&
              !!subscriptionValidated ? (
                <>
                  <StylishNewButton
                    className="button--primary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0"
                    onClick={uploadFileToTeamsClicked}
                  >
                    Share with Teams
                  </StylishNewButton>
                </>
              ) : (
                <>
                  {!isUploadFileToTeamsLoaded && (
                    <span className="ml-3">Sharing with Teams...</span>
                  )}
                </>
              )}
              {!subscriptionValidated && (
                <div>Pratus Premium required for Teams Integration</div>
              )}
            </>
          ) : null}
        </PageTitle>
      ) : null}

      {!newUI && formConfig.formname !== 'ICS 208' && (
        <div className="d-md-flex align-items-md-center">
          {formConfig.formname === 'ICS 201' && (
            <h3 className="mb-3 mb-md-0">
              Incident Briefing: ICS 201 - Awaiting Approval
            </h3>
          )}
          {formConfig.formname === 'ICS 204' && (
            <h3 className="mb-3 mb-md-0">
              Assignment List: ICS 204 - Awaiting Approval
            </h3>
          )}
          {formConfig.formname === 'ICS 206' && (
            <h3 className="mb-3 mb-md-0">MEDICAL PLAN ICS 206-CG</h3>
          )}
          {formConfig.formname === 'ICS 213 RR' && (
            <h3 className="mb-3 mb-md-0">ICS-213 RR CG (2/07)</h3>
          )}
          {formConfig.formname === 'ICS 202' && (
            <h3 className="mb-3 mb-md-0">INCIDENT OBJECTIVES ICS 202-CG</h3>
          )}

          {formConfig.formname === 'ICS 205' && (
            <h3 className="mb-3 mb-md-0">
              INCIDENT RADIO COMMUNICATIONS PLAN ICS 205-CG
            </h3>
          )}

          {formConfig.formname === 'ICS 205A' && (
            <h3 className="mb-3 mb-md-0">COMMUNICATIONS LIST ICS 205A-CG</h3>
          )}

          {formConfig.formname === 'IAP COVER SHEET' && (
            <h3 className="mb-3 mb-md-0">CG IAP COVER SHEET</h3>
          )}

          {formConfig.formname !== 'ICS 202' && (
            <div className="button-group ms-auto flex-shrink-0">
              {formConfig.formname !== 'ICS 213 RR' && (
                <>
                  <StylishNewButton
                    type="button"
                    className="button--primary"
                    onClick={() => {
                      if (!showPDFPreview) {
                        handleReloadPDFPreview(true);
                      }
                      if (
                        sessionStorage['isUsingTeams'] !== 'true' &&
                        isBrowser
                      ) {
                        setShowPDFPreview(!showPDFPreview);
                      } else {
                        setShowPDFPreviewMobile(true);
                      }
                      if (sessionStorage['isUsingTeams'] === 'true') {
                        setShowPDFPreviewNewTab(true);
                        setShowPDFPreviewTeams(true);
                      }
                    }}
                  >{`${
                    (showPDFPreview || showPDFPreviewMobile) &&
                    sessionStorage['isUsingTeams'] !== 'true'
                      ? 'Hide'
                      : 'Show'
                  } PDF Preview`}</StylishNewButton>
                </>
              )}
              {!!tenantId &&
              !!isUploadFileToTeamsLoaded &&
              !!featureFlags.includes('GENERATE_TEAM_SITE') &&
              !!reduxCurrentIncident.teams_id &&
              !!reduxCurrentIncident.sharepoint_location &&
              !!reduxCurrentIncident.channel_id &&
              !!subscriptionValidated ? (
                <>
                  <StylishNewButton
                    className="button--primary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0"
                    onClick={uploadFileToTeamsClicked}
                  >
                    Share with Teams
                  </StylishNewButton>
                </>
              ) : (
                <>
                  {!isUploadFileToTeamsLoaded && (
                    <span className="ml-3">Sharing with Teams...</span>
                  )}
                </>
              )}
              {!subscriptionValidated && (
                <div>Pratus Premium required for Teams Integration</div>
              )}
            </div>
          )}
        </div>
      )}
      <div className="row">
        <div
          className={`${
            sessionStorage['isUsingTeams'] !== 'true' && showPDFPreview
              ? 'col-md-6'
              : 'col-md-12'
          }`}
        >
          <div className="form-block">
            {formConfig.formname === 'ICS 201' ? (
              <IAPForm201
                formRecordData={formRecordData}
                onFormRecordDataUpdate={onFormRecordDataUpdate}
                formConfig={formConfig}
                setDataLoaded={handleReloadPDFPreview}
                currentRole={user_incident_role}
                currentFormIsMostRecentForm={currentFormIsMostRecentForm}
              />
            ) : formConfig.formname === 'ICS 204' ? (
              <IAPForm204
                onFormRecordDataUpdate={onFormRecordDataUpdate}
                setDataLoaded={handleReloadPDFPreview}
              />
            ) : formConfig.formname === 'ICS 206' ? (
              <IAPForm206
                formRecordData={formRecordData}
                onFormRecordDataUpdate={onFormRecordDataUpdate}
                formConfig={formConfig}
                setDataLoaded={handleReloadPDFPreview}
                currentRole={user_incident_role}
                currentFormIsMostRecentForm={currentFormIsMostRecentForm}
              />
            ) : formConfig.formname === 'ICS 202' ? (
              <IAPForm202
                formRecordData={formRecordData}
                onFormRecordDataUpdate={onFormRecordDataUpdate}
              />
            ) : formConfig.formname === 'ICS 205' ? (
              <IAPForm205 onFormRecordDataUpdate={onFormRecordDataUpdate} />
            ) : formConfig.formname === 'ICS 205A' ? (
              <IAPForm205A onFormRecordDataUpdate={onFormRecordDataUpdate} />
            ) : formConfig.formname === 'ICS 213 RR' ? (
              <IAPForm213RR />
            ) : formConfig.formname === 'IAP COVER SHEET' ? (
              <IAPCoverSheet
                formRecordData={formRecordData}
                onFormRecordDataUpdate={onFormRecordDataUpdate}
              />
            ) : formConfig.formname === 'ICS 208' ? (
              <IAPForm208 />
            ) : null}
          </div>
        </div>

        {(!!formConfig && !!isWorkflowOpen && (
          <div className="col-md-6">
            <FormWorkflow
              formConfig={formConfig}
              currentState={form.record_state}
              currentRole={user_incident_role}
              currentFormIsMostRecentForm={currentFormIsMostRecentForm}
              userCanRepeatTransition={userCanRepeatTransition}
            />
          </div>
        )) ||
          (isBrowser ? (
            <>
              {showPDFPreview && sessionStorage['isUsingTeams'] !== 'true' && (
                <div className="col-md-6">
                  {/* <div className="FormCollectionInterface-form-PDFPreview-Reload"></div> */}
                  {!!exportedFormsToPDF &&
                    !!exportedFormsToPDF.find(
                      (f) => f.id === { ...formId }.current
                    ) && (
                      <div className="h-100">
                        <iframe
                          style={{ border: 'none' }}
                          src={`data:application/pdf;base64,${
                            exportedFormsToPDF.find(
                              (f) => f.id === { ...formId }.current
                            ).PDFData
                          }`}
                          type="application/pdf"
                          className="FormCollectionInterface-form-PDFPreview-object"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    )}
                </div>
              )}
            </>
          ) : (
            <Modal
              show={showPDFPreviewMobile}
              onHide={() => setShowPDFPreviewMobile(false)}
              fullscreen={true}
            >
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title>
                  {formConfig.formname === 'ICS 201' ? (
                    <>Incident Briefing: ICS 201</>
                  ) : null}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pt-2">
                {!!exportedFormsToPDF &&
                  sessionStorage['isUsingTeams'] !== 'true' &&
                  !!exportedFormsToPDF.find(
                    (f) => f.id === { ...formId }.current
                  ) && (
                    <div className="h-100">
                      <iframe
                        style={{ border: 'none' }}
                        src={`data:application/pdf;base64,${
                          exportedFormsToPDF.find(
                            (f) => f.id === { ...formId }.current
                          ).PDFData
                        }`}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  )}
              </Modal.Body>
            </Modal>
          ))}
        {showPDFPreviewTeams && sessionStorage['isUsingTeams'] === 'true' ? (
          <Modal
            show={showPDFPreviewTeams}
            onHide={() => setShowPDFPreviewTeams(false)}
            fullscreen={true}
          >
            <Modal.Header closeButton closeVariant="white">
              <Modal.Title>
                {formConfig.formname === 'ICS 201' ? (
                  <>Incident Briefing: ICS 201</>
                ) : null}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-2">
              {!!exportedFormsToPDF &&
                sessionStorage['isUsingTeams'] === 'true' &&
                !!exportedFormsToPDF.find(
                  (f) => f.id === { ...formId }.current
                ) && (
                  <div className="h-100">
                    <a
                      href={URL.createObjectURL(
                        b64toBlob(
                          exportedFormsToPDF.find(
                            (f) => f.id === { ...formId }.current
                          )?.PDFData,
                          'image/png'
                        )
                      )}
                      target="_blank"
                    >
                      View PDF in Browser
                    </a>
                  </div>
                )}
            </Modal.Body>
          </Modal>
        ) : null}
      </div>
      {isEditOperationalPeriodOpen && <EditOperationalPeriodDialog />}
    </>
  );
}


/// Private utilities

function getFormTitle(formname) {
  switch (formname) {
    case 'ICS 201':
      return 'Incident Briefing: ICS 201 - Awaiting Approval';
    case 'ICS 204':
      return 'Assignment List: ICS 204 - Awaiting Approval';
    case 'ICS 206':
      return 'MEDICAL PLAN ICS 206-CG';
    case 'ICS 202':
      return 'INCIDENT OBJECTIVES ICS 202-CG';
    case 'ICS 205':
      return 'INCIDENT RADIO COMMUNICATIONS PLAN ICS 205-CG';
    case 'ICS 205A':
      return 'COMMUNICATIONS LIST ICS 205A-CG';
    case 'ICS 213 RR':
      return 'ICS-213 RR CG (2/07)';
    case 'IAP COVER SHEET':
      return 'CG IAP COVER SHEET';
    case 'ICS 208':
      return 'Site Safety and Health Plan ICS 208';
    default:
      return '';
  }
}

function getFormParents(formname) {
  switch (formname) {
    case 'ICS 206':
    case 'ICS 202':
    case 'ICS 205':
    case 'ICS 205A':
    case 'IAP COVER SHEET':
    case 'ICS 208':
      return ['Incident Management', 'Plan'];
    default:
      return ['Incident Management'];
  }

}

function showPDFButton(formname) {
  return formname !== 'ICS 202' && formname !== 'ICS 213 RR'
}

function showTeamsButtons(formname) {
  return formname !== 'ICS 202'
}
