import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { PreparePath } from 'utils/sharedUtils/sharedConst';

export const TestObjectives = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector(
    (state) => state?.prepare?.workspace
  );
  const [associatedTestObjective, setAssociatedTestObjective] = useState([]);
  const [
    associatedTestObjectiveCount,
    setAssociatedTestObjectiveCount,
  ] = useState(0);
  const [nonAssociatedTestObjective, setNonAssociatedTestObjective] = useState(
    []
  );
  const [
    nonAssociatedTestObjectiveCount,
    setNonAssociatedTestObjectiveCount,
  ] = useState(0);
  const [previousData, setPreviousData] = useState();
  useEffect(() => {
    if (!!selectedWorkspace && params.id) getAllMissionObjectivesList();
  }, [selectedWorkspace, params.id]);

  const getAllMissionObjectivesList = async () => {
    dispatch(startLoading());

    try {
      const response = await Network.get(API.getMissionTimelineObjectiveList, {
        workspaceId: selectedWorkspace,
        missionTimelineId: params.id,
      });
      setPreviousData(response?.data?.response);
      setAssociatedTestObjective(response?.data?.response.associated_objective);
      setAssociatedTestObjectiveCount(
        response?.data?.response.associated_objective_total
      );
      setNonAssociatedTestObjective(
        response.data.response.not_associated_objective
      );
      setNonAssociatedTestObjectiveCount(
        response.data.response.not_associated_objective_total
      );
      dispatch(endLoading());
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      dispatch(endLoading());
    }
  };
  const onPlusClick = (row) => {
    setAssociatedTestObjective((prev) => [...prev, row]);
    setAssociatedTestObjectiveCount((prev) => prev + 1);
    setNonAssociatedTestObjective((prev) =>
      prev.filter((item) => item.coic_id !== row.coic_id)
    );
    setNonAssociatedTestObjectiveCount((prev) => prev - 1);
  };
  const onMinusClick = (row) => {
    setNonAssociatedTestObjective((prev) => [...prev, row]);
    setNonAssociatedTestObjectiveCount((prev) => prev + 1);
    setAssociatedTestObjective((prev) =>
      prev.filter((item) => item.coic_id !== row.coic_id)
    );
    setAssociatedTestObjectiveCount((prev) => prev - 1);
  };
  const onSubmit = async (type) => {
    dispatch(startLoading());

    const data = {
      workspaceId: selectedWorkspace,
      mission_timeline_id: params.id,
      mission_objective: associatedTestObjective.map((item) => ({
        coic_id: item.coic_id,
        mission_timeline_id: params.id,
        workspaceId: selectedWorkspace,
      })),
      ...compareData(),
    };

    try {
      await Network.post(API.createUpdateMissionObjective, data);
      dispatch(endLoading());
      if (type === 'exit') {
        navigate(`${PreparePath}/dashboard/plan/missions`);
      } else {
        navigate(
          `${PreparePath}/dashboard/plan/missions/timeline/${params.id}?page=4`
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      dispatch(endLoading());
    }
  };
  const compareData = () => {
    let previous_data = {};
    let current_data = {};
    if (
      JSON.stringify(previousData.associated_objective) ===
      JSON.stringify(associatedTestObjective)
    ) {
      return { previous_data: null, current_data: null };
    }
    previous_data = previousData.associated_objective.map((item) => ({
      coic: item.coic,
      description: item.description,
      associated_plan_test_objective: item.associated_plan_test_objective,
    }));
    current_data = associatedTestObjective.map((item) => ({
      coic: item.coic,
      description: item.description,
      associated_plan_test_objective: item.associated_plan_test_objective,
    }));
    return { previous_data, current_data };
  };
  const onSaveAndExitClick = () => {
    onSubmit('exit');
  };

  const columnsAssignedObjectives = [
    {
      dataField: 'coic',
      text: 'COIC',
      sort: false,
      attrs: { title: 'COIC' },
      formatter: (_, row) => `COIC ${row?.coic}`,
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: false,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      attrs: { title: 'Action' },
      formatter: (_, row) => (
        <StylishNewButton
          className="button--action remove"
          type="button"
          onClick={() => onMinusClick(row)}
        >
          <i className="fa fa-minus" aria-hidden="true"></i>
        </StylishNewButton>
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const columnsAvailableObjectives = [
    {
      dataField: 'coic',
      text: 'COIC',
      sort: false,
      attrs: { title: 'COIC' },
      formatter: (_, row) => `COIC ${row?.coic}`,
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: false,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      attrs: { title: 'Action' },
      formatter: (_, row) => (
        <StylishNewButton
          className="button--action add"
          type="button"
          onClick={() => onPlusClick(row)}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
        </StylishNewButton>
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          {(row?.associated_plan_test_objective &&
            !!row?.associated_plan_test_objective?.length &&
            row?.associated_plan_test_objective?.map((obj, idx) => (
              <p
                className={`${
                  (idx === row?.associated_plan_test_objective?.length - 1 &&
                    'm-0') ||
                  ''
                }`}
                key={obj?.id}
              >
                <strong className="weight-600 d-block mb-1">
                  {obj?.type} {obj?.item}
                </strong>
                {obj?.description || '-'}
              </p>
            ))) ||
            null}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <h4>Mission Test Objectives</h4>
      <div className="form-block">
        <p className="weight-600">
          Assigned Objectives: {associatedTestObjectiveCount}
        </p>
        <StylishNewTable
          keyField="coic_id"
          columns={columnsAssignedObjectives}
          rows={associatedTestObjective}
          expandRow={expandRow}
          classes="mb-4 no-filter"
          headerClasses="empty"
        />
        <p className="weight-600">
          Assigned Objectives: {nonAssociatedTestObjectiveCount}
        </p>
        <StylishNewTable
          keyField="coic_id"
          columns={columnsAvailableObjectives}
          rows={nonAssociatedTestObjective}
          expandRow={expandRow}
          classes="no-filter"
          headerClasses="empty"
        />
      </div>
      <div className="button-group d-block d-md-flex mt-4">
        <StylishNewButton
          type={'button'}
          onClick={() =>
            navigate(
              `${PreparePath}/dashboard/plan/missions/timeline/${params.id}?page=2`
            )
          }
          className={'button--primary button--reverse w-100 w-md-auto'}
        >
          Previous
        </StylishNewButton>
        <StylishNewButton
          type="button"
          onClick={onSaveAndExitClick}
          className={
            'button--tertiary button--reverse w-100 w-md-auto ms-0 ms-md-auto mt-2 mt-md-0'
          }
        >
          Save & Exit
        </StylishNewButton>
        <StylishNewButton
          type="button"
          onClick={onSubmit}
          className={
            'button--primary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0'
          }
        >
          Next
        </StylishNewButton>
      </div>
    </>
  );
};
